import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS_INSTANCE } from '../../components/utils/axiosInstance';

const initialState = {
  data: [],
  session: sessionStorage.getItem('session_ID') || null,
  status: null,
  message: null,
  error: null
}

export const fetchChatResponse = createAsyncThunk('chatResponse',
  async (formData, {rejectWithValue}) => {
    try {
      const response = await AXIOS_INSTANCE.post('/stream-chat-message/', formData);
      console.log('responseData', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const uploadFiles = createAsyncThunk('addFile',
  async (formData) => {
    const response = await AXIOS_INSTANCE.post('/stream-chat-message/', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    return response.data;
  }
);

export const uploadLink = createAsyncThunk('addLink',
  async (payload) => {
    const response = await AXIOS_INSTANCE.post('/stream-chat-message/', {
      'source_url': payload.source_url,
      'language': payload.selectedLanguage,
      'session_id': payload.sessionID
    });
    return response.data;
  }
);

export const getSessionData = createAsyncThunk('sessionContent',
  async (session) => {
    const response = await AXIOS_INSTANCE.get(`stream-chat-message/?session_id=${session}`);
    return response.data
  }
);

// patch request for like and dislike
export const patchLikeAndDislike = createAsyncThunk('likeDislike',
  async ({messageId, is_liked} ) => {
    const response = await AXIOS_INSTANCE.patch(`/chat-message/${messageId}/`, {
      'is_liked': is_liked
    });
    return response.data;
  }
);

export const { actions, reducer } = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    createNewChat: (state, action) => {
      state.data = [];
      state.session = null;
    },
    setSession: (state, action) => {
      state.session = action.payload;
    }
  },
  extraReducers: (builder) => {

    /*Fetch Chat Response*/
    builder.addCase(fetchChatResponse.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchChatResponse.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = [...state.data, action.payload];
      state.session = action.payload?.session;
    });
    builder.addCase(fetchChatResponse.rejected, (state, action) => {
      state.status = 'failed';
      state.message = action.payload?.data?.detail || action.payload?.detail;
      state.error = true;
      state.statusCode = action.payload?.status;
    });

    /*Upload Files*/
    builder.addCase(uploadFiles.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(uploadFiles.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = [action.payload];
      state.session = action.payload.session;
    });
    builder.addCase(uploadFiles.rejected, (state) => {
      state.status = 'failed';
    });

    /*Upload Link*/
    builder.addCase(uploadLink.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(uploadLink.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = [action.payload];
      state.session = action.payload.session;
    });
    builder.addCase(uploadLink.rejected, (state) => {
      state.status = 'failed';
    });

    /*Get Session Data*/
    builder.addCase(getSessionData.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getSessionData.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload.results;
    });
    builder.addCase(getSessionData.rejected, (state) => {
      state.status = 'failed';
    });

    /*Patch Like and Dislike*/
    builder.addCase(patchLikeAndDislike.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(patchLikeAndDislike.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = [...state.data, action.payload.is_liked];
    });
    builder.addCase(patchLikeAndDislike.rejected, (state) => {
      state.status = 'failed';
    });
  }
});

export const { createNewChat, setSession } = actions;
