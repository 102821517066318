import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from './Sidebar';
import ChatContent from './ChatContent';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getRestartStatus } from '../state/slices/restartSlice';
import { useAuth } from './utils/AuthContext';
import { AXIOS_INSTANCE } from './utils/axiosInstance';
import FirstTimeModal from './FirstTimeModal';

export default function ChatPage() {
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  //const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // [1
  const { isMobile } = useAuth()
  const restart = useSelector((state) => state.restart);
  const dispatch = useDispatch();

  const getRestartStat = useCallback(() => {
    dispatch(getRestartStatus()).then((data) => {
      if (!data.payload.healthy) {
        setTimeout(() => {
          getRestartStat();
        }, 5000);
      }
    })
  }, [dispatch]);

  useEffect(() => {
    document.title = "ImageChat | Chooch";
    if (restart.status === 'restartLoading') {
      getRestartStat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOnboardingStatus = () => {
    AXIOS_INSTANCE.get('/user-setting/')
      .then(res => {
        sessionStorage.setItem('isWelcomeSeen', res.data.completed_onboarding)
        if (!res.data.completed_onboarding) {
          setShowWelcomeModal(true)
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  useEffect(() => {
    if (sessionStorage.getItem('isWelcomeSeen') === null) {
      getOnboardingStatus();
    }
  }, [])


  const handleCloseModal = () => {
    setShowWelcomeModal(false)
  }

  const handleSkip = () => {
    sessionStorage.setItem('isWelcomeSeen', true)
    setShowWelcomeModal(false)
  }

  return (
    <Row className='flex-grow-1 m-0 p-0'>
      <Col className='p-0'>
        {
          !isMobile ?
            <Row className='mx-0'>
              <Col sm={2} className='p-0 sidebar'>
                <Sidebar />
              </Col>
              <Col sm={10} className='page-content'>
                <ChatContent />
              </Col>
            </Row>
            :
            <ChatContent />
        }
      </Col>

      <FirstTimeModal show={showWelcomeModal} closeModal={handleCloseModal} />
    </Row>

  );
}

