import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getChatHistoryList, openModal } from '../state/slices/chatHistoryListSlice';
import { createNewChat, getSessionData, setSession } from '../state/slices/chatSlice';
import DeleteModal from './DeleteModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './utils/AuthContext';
import TrashGray
  from '../assets/images/trash.svg';
import newChatIcon from '../assets/images/new-chat.svg'
import { ListGroup } from 'react-bootstrap';
import { useTour } from '@reactour/tour';
import { Trash } from 'react-bootstrap-icons';
import moment from 'moment';
import ChatResponseSpinner
  from './ChatResponseSpinner';

export default function ChatHistoryList() {
  const closeElement = document.querySelector('.offcanvas-header .btn-close');

  const { currentStep, isOpen } = useTour();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useAuth();
  const [chatList, setChatList] = useState([]);
  const [page, setPage] = useState(0);

  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);

  const chatHistoryList = useSelector(state => state.chatHistoryList);

  const dispatch = useDispatch();

  const handleChatHistoryClick = async (session_id) => {
    setHoveredId(null);
    sessionStorage.setItem('session_ID', session_id);
    await dispatch(setSession(session_id));
    setHoveredId(session_id);
    if (isMobile) {
      await dispatch(getSessionData(session_id));
      navigate('/home');
      closeElement && closeElement.click()
    }
  };

  const openDeleteModal = (sessionID) => {
    dispatch(openModal());
  }

  useEffect(() => {
    dispatch(getChatHistoryList(1)).then((res) => {
      setChatList(res.payload.results);
      setPage(0)
    });
  }, [sessionStorage.getItem('session_ID')]);

  const handleMouseEnter = id => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    if (selectedIds.length === 0) {
      setHoveredId(null);
    }
  };

  const handleSelect = id => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(sid => sid !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const shouldShowCheckbox = (id) => {
    return selectedIds.length > 0 || id === hoveredId;
  };

  const handleIdSelectAll = (isSelectAll) => {
    if (isSelectAll) {
      let allIds = chatList.map(value => value.id);
      setSelectedIds(allIds);
      setIsAllSelected(true)
    }
    else {
      setSelectedIds([]);
      setIsAllSelected(false)
      setHoveredId(null)
    }
  }

  const clearDeleteStatus = () => {
    setSelectedIds([])
    setPage(1)
    dispatch(getChatHistoryList(1)).then((res) => {
      setChatList(res.payload.results);
    });
  }
  const renderTitleNew = (item, chatList) => {
    const dataSets = [
      { data: chatList.filter(chat => moment(chat.update_date).isSame(moment(), 'day')), title: 'Today' },
      { data: chatList.filter(chat => moment(chat.update_date).isAfter(moment().subtract(7, 'days').startOf('day')) && moment(chat.update_date).isBefore(moment().startOf('day'))), title: 'Previous 7 Days' },
      { data: chatList.filter(chat => moment(chat.update_date).isAfter(moment().subtract(30, 'days').startOf('day')) && moment(chat.update_date).isBefore(moment().subtract(7, 'days').startOf('day'))), title: 'Previous 30 Days' },
      {
        data: chatList.filter(chat => moment(chat.update_date).isAfter(moment().subtract(1, 'years')) && moment(chat.update_date).isBefore(moment().subtract(1, 'months'))).map(chat =>
        ({
          ...chat,
          monthTitle: moment(chat.update_date).format('MMMM')
        })
        ),
        title: 'Previous Months'
      }
    ];
    return dataSets.find(a => a.data.find((b, index) => b.id === item.id && index === 0)) ?
      dataSets.find(a => a.data.find((b, index) => b.id === item.id && index === 0)).title
      :
      null
  }

  const handleNewChat = () => {
    sessionStorage.removeItem('session_ID');
    setSelectedIds([])
    setHoveredId(null)
    setIsAllSelected(false)
    dispatch(createNewChat());
    closeElement && closeElement.click()
  }

  const observer = useRef();
  const lastChatElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && chatHistoryList.data.next !== null) {
        setLoading(true);
        dispatch(getChatHistoryList(page + 1)).then((res) => {
          setPage(page + 1);
          const newResults = res.payload.results.filter(
            newChat => !chatList.some(existingChat => existingChat.id === newChat.id)
          );
          const updatedChatList = [...newResults, ...chatList];
          updatedChatList.sort((a, b) => moment(b.update_date).diff(moment(a.update_date)));
          setChatList(updatedChatList);
          setLoading(false);
        });
      }
    });
    if (node) observer.current.observe(node);
  }, [page]);

  return (
    <>
      <button className='new-chat-btn third-step' onClick={handleNewChat}>
        <img src={newChatIcon} alt="new-chat" />
        New Chat
        </button>
      <div className="d-flex justify-content-between align-items-center my-3 w-100 px-3">
        <h6 className="chat-history-title text-start mb-0">Chat History</h6>
        {selectedIds.length > 0 &&
          <input type="checkbox" className='select-all-cb' checked={isAllSelected} onChange={() => handleIdSelectAll(!isAllSelected)} />
        }
      </div>
      {chatHistoryList.data?.results?.length <= 0 && currentStep === 3 && isOpen ?
        <div className='chat-history-list overflow-scroll h-100 fourth-step'>
          <ListGroup.Item href='#' key={0} action active={true}>
            <div className='active-border'></div>
            <span className='item-description'>
              <span className='item-subject'>Example chat item</span>
            </span>
            <span className='item-info-wrapper ms-auto d-flex flex-column align-items-center '>
              <span>
                <img src={TrashGray} alt={'delete'} />
              </span>
            </span>
          </ListGroup.Item>
        </div>
        :

        <div className="chat-history-list overflow-scroll h-100 fourth-step">
          {chatList.map((item, index) => {
            return (
              <div key={index}>
                {renderTitleNew(item, chatList) ?
                  <p className="chat-date-title">{renderTitleNew(item, chatList)}</p>
                  :
                  null
                }
                <ListGroup.Item
                  href="#"
                  key={item.source_id}
                  action
                  active={sessionStorage.getItem('session_ID') == item?.id}
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="active-border"></div>
                  <span className="item-description" onClick={() => handleChatHistoryClick(item?.id)}>
                    <span className="item-subject">{item?.subject}</span>
                  </span>
                  <span className="item-info-wrapper ms-auto d-flex flex-column align-items-center ">
                    {shouldShowCheckbox(item.id) && (
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(item.id)}
                        onChange={() => handleSelect(item.id)}
                      />
                    )}
                  </span>
                </ListGroup.Item>
              </div>
            );
          })}
          <div ref={lastChatElementRef} style={{ height: "5px", width: "5px" }}></div>
          {loading && <span className='d-flex justify-content-center'><ChatResponseSpinner withText={false}/></span>}
          {selectedIds.length > 0 &&
            <div className="delete-all-btn" onClick={openDeleteModal}>
              Delete
              <Trash />
            </div>
          }
        </div >
      }

      <DeleteModal
        selectedIds={selectedIds}
        clearDeleteStatus={clearDeleteStatus}
      />

    </>
  );
}
