import CloudSignUp from './CloudSignUp';
import SelfHostedSignUp from './SelfHostedSignUp';

export default function SignUp() {
  return (
    <div>
      {process.env.REACT_APP_ON_PREM === 'false' ? <CloudSignUp /> : <SelfHostedSignUp />}
    </div>
  )
}
