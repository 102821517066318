import React from 'react'
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import imageBack from '../assets/images/ie-image-back.png'
import imageFront from '../assets/images/ie-image-front.png'

export default function InferenceEngine() {
  const navigate = useNavigate();

  const navigateToHomepage = () => {
    navigate("/home");
  }

  const handleContactUs = () => {
    window.open('https://www.chooch.com/upgrade-imagechat/', "_blank");
  }

  return (
    <div className='ie-wrapper'>
      <div className='breadcrumb'>
        <div className='main-breadcrumb' onClick={navigateToHomepage}>
          Homepage
        </div>
        <ChevronRight size={14} />
        <div className='sub-breadcrumb'>
          Enhance Your Experience
        </div>
      </div>

      <div className='ie-content'>
        <div className='ie-text-content'>
          <h5>ENHANCE YOUR EXPERIENCE</h5>
          <h1>Unlock ImageChat-3’s Full Potential!</h1>

          <div>
            Upgrade your ImageChat-3 experience and tap into its limitless potential. Seamlessly integrate its powerful capabilities with video streams to automate search and review tasks like never before.
            <br></br>
            <br></br>
            With ImageChat-3, you can easily execute automated prompt queries to maximize the efficiency and speed of video footage review. Save valuable time by eliminating repetitive, manual visual review tasks and redirecting resources to more impactful activities.
            <br></br>
            <br></br>
            ImageChat-3 advanced features include:
            <br></br>
            <br></br>
            <ul>
              <li>Seamless integration into edge-optimized inference engine</li>

              <li>Data security and privacy built-in</li>

              <li>Pre-trained models for common business applications</li>

              <li>Low code tools for custom model development</li>

             <li>Real-time alerts on predictions</li>

              <li>Smart Analytics Dashboard to monitor and analyze alerts</li>

              <li>24 x 7 online support from our help team</li>
            </ul>
          </div>

          <p>Contact us to upgrade.</p>
          <p>Start building generative AI-powered applications for your enterprise to transform how work gets done. Let us help you unlock a new realm of possibilities with ImageChat.</p>
          <Button onClick={handleContactUs}>Contact Us</Button>
        </div>
        <div className='ie-image-content'>
          <img src={imageBack} alt="img-back" className='img-back' />
          <img src={imageFront} alt="img-front" className='img-front' />
        </div>
      </div>
    </div>
  )
}

