import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '../assets/images/copy-blue.svg';

export default function CustomCopy({ text, className }) {
  const [isCopied, setIsCopied] = React.useState(false);

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="bottom"
      onEnter={() => setIsCopied(false)}
      overlay={
        <Tooltip className="copy-chat-tooltip">
          {isCopied ? 'Copied' : 'Copy'}
        </Tooltip>
      }
    >
      <CopyToClipboard text={text} onCopy={() => setIsCopied(true)}>
        <img src={CopyIcon} alt="copy" className={'copy-icon ' + className} />
      </CopyToClipboard>
    </OverlayTrigger>
  );
};
