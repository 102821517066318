import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('rememberUser') === 'true' ? JSON.parse(localStorage.getItem('user')) : JSON.parse(sessionStorage.getItem('user')))
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('rememberUser') === 'true' ? localStorage.getItem('isAuthenticated') : sessionStorage.getItem('isAuthenticated'))
  const [isEnterprise, setIsEnterprise] = useState(JSON.parse(localStorage.getItem('isEnterprise')));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const settings = useSelector((state) => state.settings);

  const setUser = (email = settings?.data?.user_email, rememberUser) => {
    let user = {
      "email": email,
    }
    if(rememberUser){
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('isAuthenticated', 'true')
    }
    else{
      sessionStorage.setItem('user', JSON.stringify(user))
      sessionStorage.setItem('isAuthenticated', 'true')
    }

    setCurrentUser(user)
    setIsAuthenticated('true')
  }

  const userAccountType = (userType) => {
    setIsEnterprise(userType)
  }

  const logout = () => {
    let user = {
      "email": null,
    }
    if(localStorage.getItem('rememberUser') === 'true'){
      localStorage.removeItem('user')
      localStorage.setItem('isAuthenticated', 'false')
    }
    else{
      sessionStorage.removeItem('user')
      sessionStorage.setItem('isAuthenticated', 'false')
    }
    localStorage.setItem('rememberUser', 'false')
    setCurrentUser(user)
    setIsAuthenticated('false')
  }


  useEffect(() => {
    const resizeListener = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

  const value = {
    isAuthenticated,
    currentUser,
    setUser,
    userAccountType,
    isEnterprise,
    logout,
    isMobile,
  }


  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider >
  )
}
