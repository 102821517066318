import React, { useState } from 'react'

export default function Imgload(props) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <img src={props.file?.webp || props.file?.thumbnail} alt={props.file.thumbnail} className="img-file preview" onLoad={() => setLoading(false)} onClick={() => props.handleOpenImagePreview(props.file)} style={!loading ? {} : { display: 'none' }} />
      <div className='skeleton' style={loading ? {} : { display: 'none' }}></div>
    </>
  )
}
