import React from 'react'
import { ChevronRight, Envelope, GeoAlt } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';

export default function About() {
  const navigate = useNavigate();

  const navigateToHomepage = () => {
    navigate("/home");
  }

  const navigateToLicenseAgreement = () => {
    navigate("/license-agreement");
  }


  const handleContactUs = () => {
    window.open('https://chooch.ai/contact/', "_blank");
  }

  const handleChooch = () => {
    window.open('https://www.chooch.com/', "_blank");
  }

  const handleApiDocs = () => {
    window.open('https://imagechatweb.chooch.ai/docs', "_blank");
  }

  const handleSendMail = () => {
    window.open('mailto:support@chooch.com', "_blank");
  }
  const handleSelfHosted = () => {
    window.open('https://www.chooch.com/imagechat-self-hosted-setup-guide/', "_blank");
  }

  const handleGithub = () => {
    window.open('https://github.com/chooch-ai/imagechat', "_blank");
  }

  return (
    <div className='about-page'>
      <div className='breadcrumb'>
        <div className='main-breadcrumb' onClick={navigateToHomepage}>
          Homepage
        </div>
        <ChevronRight size={14} />
        <div className='sub-breadcrumb'>
          Help
        </div>
      </div>

      <div className='about-content'>
        <div className='about-contact-wrapper d-flex justify-content-between px-4'>
          <div className='more-info'>
            <h5>Resources</h5>
            <div className='own-link' onClick={handleSendMail}>support@chooch.com</div>
            <div className='own-link' onClick={handleGithub}>github.com/chooch-ai/imagechat</div>
            <div className='own-link' onClick={handleApiDocs}>ImageChat API Docs</div>
            <div className='own-link' onClick={handleSelfHosted}>ImageChat Self-Hosted</div>
            <div className='own-link' onClick={handleChooch}>www.chooch.com</div>
          </div>
          <div className='contact-info'>
            <h5>Contact Support</h5>
            <div className='d-flex'>
              <Envelope className='me-3' size={20} />
              <a href="mailto:support@chooch.com">support@chooch.com</a>
            </div>
            <div className='d-flex mt-3'>
              <GeoAlt className='me-3' size={20} />
              3E 3rd Ave, San Mateo, CA 94401
            </div>
          </div>
          <div className='contact-area'>
            <h5>Talk to Sales</h5>
            <p>Explore the possibilities with ImageChat. Schedule a demo to learn more. </p>
            <button onClick={handleContactUs}>Contact Us</button>
          </div>
        </div>
        <div className='about-explanation px-4'>
          <strong>Make the world a better place with AI</strong>
          <p className='about-explanation-text'>Usage of ImageChat is subject to the <span onClick={navigateToLicenseAgreement}>Chooch Terms & Conditions and User Agreement</span>. All models and example code belong to Chooch Intelligence Technologies Co (C) 2023.</p>
          <br></br><br></br>

        </div>

      </div>
    </div>
  )
}
