import {
    Modal,
    Button,
    OverlayTrigger, Tooltip
} from 'react-bootstrap';
import Developer from '../assets/images/developer.svg'
import Enterprise from '../assets/images/enterprise.svg'
import Info from '../assets/images/info-pruple.svg'
import { useNavigate } from 'react-router-dom';
import {
    CopyToClipboard
} from 'react-copy-to-clipboard';
import Copy from '../assets/images/copy.svg';
import React from 'react';


const usagesLeft = [
    {title: 'ImageChat Web', description: '1 API for each response.'},
    {title: 'Train Imagechat Models', description: '1 API per model.'},
    {title: 'Train Object Detection Models', description: '1 API per model plus 1 API for each image in the trained dataset.'},
    {title: 'Train Face Models', description: '1 API per model plus 1 API for each image added to the model.'},
    {title: 'Train Image Models', description: '1 API per model plus 1 API for each image added to the model.'},
    {title: 'Test ImageChat, Object and Face Models', description: '1 API for each image tested.'},
    {title: 'Import Model', description: '1 API per MB of the model.'},
    {title: 'Create Dataset', description: '1 API per dataset created.'},
]

const usagesRight = [
    {title: 'Add Image to Dataset', description: '1 API for each image added to the dataset.'},
    {title: 'Create dataset with Data Augmentation', description: '1 API for each image generated with Data Augmentation.'},
    {title: 'Create dataset with 2D Synthetic Data Generation', description: '1 API for each image generated with 2D Synthetic Data Generation.'},
    {title: 'Create dataset with 3D Synthetic Data Generation', description: '1 API for each image generated with 3D Synthetic Data Generation.'},
    {title: 'Create dataset with Smart Annotation', description: '1 API per annotated class.'},
    {title: 'Create dataset with Video Annotation', description: '3 APIs for each image generated plus the total video length in seconds.'},
    {title: 'Import Dataset', description: '1 API for each image in the imported dataset.'},
    {title: 'Raw Data', description: '1 API for each file plus 1 API for each MB.'},
];



export default function ApiLimitationWarningModal({show, handleCloseModal}) {

    const navigate = useNavigate();
    const handleCurrentUsage = () => {
        window.open('https://app.chooch.ai/feed/user-settings#usage', '_blank');
    }

    const handleBuyNow = () => {
        window.open('https://app.chooch.ai/feed/user-settings#payment', '_blank');
    }

    const handleContactUs = () => {
        window.open('https://www.chooch.com/contact-us/', '_blank');
    }

    const closeApiLimitationModal = () => {
        navigate(0);
    }

    return (
      <Modal show={show} onHide={closeApiLimitationModal} className="api-limitation-modal" centered size={'lg'}>
        <Modal.Header closeButton>
            <Modal.Title className='api-limitation-title'>Pricing Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h5 className='api-limitation-subtitle '>You've reached your API Limit</h5>
            <div className='api-limitation-modal-content px-4'>
                <div className='plan-boxes'>
                    <div className='plan-box'>
                        <div className='plan-box-title'>
                            <span className='plan-title-icon'>
                                <span className='plan-icon-wrapper'><img src={Developer} alt='devloper-code-icon'/></span>
                                <p className='plan-type-title'>Developer</p>
                            </span>
                            <p className='plan-type-price'>$1</p>
                        </div>
                        <div className='plan-box-content'>
                            <p>Get the first 10,000 API requests free. After that, each block of 1,000 requests, starting from 10,001 to 11,000, will cost $1.</p>
                        </div>
                        <div>
                            <button className='purple-btn w-100' onClick={handleBuyNow}>Buy Now</button>
                        </div>
                    </div>
                    <div className='plan-box'>
                        <div className='plan-box-title'>
                            <span className='plan-title-icon'>
                                <span className='plan-icon-wrapper'><img src={Enterprise} alt='enterprise-icon'/></span>
                                <p className='plan-type-title'>Enterprise</p>
                            </span>
                            <p className='plan-type-price'></p>
                        </div>
                        <div className='plan-box-content'>
                            <p>Get in touch with us, and let’s create tailor-made solutions for you.</p>
                        </div>
                        <div className='api-limitation-gradient-bordered-area mt-auto'>
                            <button className='api-limitation-gradient-bordered-btn' onClick={handleContactUs}>Contact us</button>
                        </div>
                    </div>
                </div>
                <div className='api-limitation-request-usages'>
                    <div className='request-usage-title'>
                        <h5 className='api-request-usages'>What can you do with API requests?</h5>
                        <Button variant='link' className='api-limitation-request-usages-btn' onClick={handleCurrentUsage}>Current Usage</Button>
                    </div>
                    <div className='usages-boxes-container'>
                        <div className='usage-box'>
                            {
                              usagesLeft.map((usage, index) => {
                                  return (
                                    <div className='api-limitation-request-usage' key={index}>
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="bottom-start"
                                            overlay={
                                                <Tooltip className='api-usage-tooltip'>
                                                    <span className='api-limitation-request-usage-icon'>{usage.description}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <span className='api-limitation-request-usage-icon'><img src={Info} alt={'info'}/></span>
                                        </OverlayTrigger>
                                        <span className='api-limitation-request-usage-title'>{usage.title}</span>
                                    </div>
                                    )
                                })
                            }
                        </div>
                        <div className='usage-box'>
                            {
                                usagesRight.map((usage, index) => {
                                    return (
                                      <div className='api-limitation-request-usage' key={index}>
                                          <OverlayTrigger
                                              trigger={['hover', 'focus']}
                                              placement="bottom-start"
                                              overlay={
                                                  <Tooltip className='api-usage-tooltip'>
                                                      <span className='api-limitation-request-usage-icon'>{usage.description}</span>
                                                  </Tooltip>
                                              }
                                          >
                                              <span className='api-limitation-request-usage-icon'><img src={Info} alt={'info'}/></span>
                                          </OverlayTrigger>
                                          <span className='api-limitation-request-usage-title'>{usage.title}</span>
                                      </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    )
}
