import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';

export default function MarkdownCodeBlock({children, className}) {
  const match = /language-(\w+)/.exec(className || '');
  const language = match ? match[1].toLowerCase() : 'javascript';

  return (
    <SyntaxHighlighter
      style={vscDarkPlus}
      language={language}
    >
      {children}
    </SyntaxHighlighter>
  )
}
