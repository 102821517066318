import React from 'react'
import { useSelector } from 'react-redux';

export default function RestartLoader() {
  const restart = useSelector((state) => state.restart);

  return (
    <>
      {restart.status === 'restartLoading' ?
        <div className='rloader'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className="lds-spinner w-100 d-flex justify-content-center my-5">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p className='restart-text'>Restarting</p>
            <p className='restart-explanation'>The system will be running smoothly shortly after restarting, with an expected duration of 3-5 minutes.</p>
          </div>
        </div>
        :
        null
      }
    </>
  )
}
