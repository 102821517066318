import React from 'react';
import SelectLanguage from './SelectLanguage';
import ChatHistoryList from './ChatHistoryList';

export default function Sidebar() {

  return (
    <>
      <div className={'menu'}>
        <ChatHistoryList />
        <div className='lang-divider'>
          <SelectLanguage />
        </div>
      </div>
    </>
  )
}
