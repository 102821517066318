import CloudLogin from './CloudLogin';
import SelfHostedLogin from './SelfHostedLogin';

export default function Login() {

  return (
    <>
      {process.env.REACT_APP_ON_PREM === 'false' ? <CloudLogin /> : <SelfHostedLogin />}
    </>
  );
}
