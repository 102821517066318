import React, { useEffect, useState, useRef, useId } from 'react';
import Loader from './Loader';
import ChatAvatar from '../assets/images/chatAvatar.svg'
import CirclePlus from '../assets/images/circle-plus.svg';
import CirclePlusWhite from '../assets/images/circle-plus-white.svg';
import CirclePlusDisabled from '../assets/images/circle-plus-disabled.svg';
import { Input } from 'antd';
import Base64ImageConverter from './utils/Base64ImageConverter';
import { dataToUrl, getFileTypeLogo, getFileTypeLogoBig } from './utils/generic';
import { useDispatch, useSelector } from 'react-redux';
import AddSingleFileModal from './AddSingleFileModal';
import { fetchChatResponse, getSessionData, patchLikeAndDislike } from '../state/slices/chatSlice';
import { getChatHistoryList } from '../state/slices/chatHistoryListSlice';
import Copy from '../assets/images/copy.svg'
import CopyIcon from '../assets/images/copy-blue.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, OverlayTrigger, Accordion } from 'react-bootstrap';
import {
  X,
  Download,
  XCircle,
  XCircleFill,
  PlayCircleFill,
  Magic
} from 'react-bootstrap-icons';
import UrlThumb from '../assets/images/urlThumb.png';
import { useToast } from './utils/ToastContext';
import downloadIcon from '../assets/images/downloadIcon.svg'
import imagePreviewIcon from '../assets/images/image-preview.svg'
import downloadIconDoc from '../assets/images/downloadIconDoc.svg'
import regenerateIcon from '../assets/images/regenerate-icon-light.svg'
import likeIcon from '../assets/images/like-icon.svg'
import dislikeIcon from '../assets/images/dislike-icon.svg'
import likeIconDark from '../assets/images/like-icon-fill.svg'
import dislikeIconDark from '../assets/images/dislike-icon-fill.svg'
import stopIcon from '../assets/images/stop.png'
import { AXIOS_INSTANCE } from './utils/axiosInstance';
import ApiLimitationWarningModal from "./ApiLimitationWarningModal";
import { useAuth } from './utils/AuthContext';
import prompts from './utils/promptExamples';
import { categories } from './utils/promptExamples';
import ChatResponseSpinner from './ChatResponseSpinner';
import ImagePreviewModal
  from './ImagePreviewModal';
import Carousel from './Carousel';
import closeIcon from '../assets/images/close-gray.svg'
import Imgload from './Imgload';
import UrlSuggestions from './UrlSuggestions';
import Linkify from 'react-linkify';
import { useTheme } from "./utils/ThemeContext";
import CustomMarkdown from './CustomMarkdown';
import CustomCopy from './CustomCopy';

const { TextArea } = Input;

export default function ChatContent() {
  const [abortController, setAbortController] = useState(new AbortController());
  const { darkTheme } = useTheme();

  const { updateMessage } = useToast();
  const { isMobile } = useAuth();
  const chat = useSelector(state => state.chat);
  const settings = useSelector((state) => state.settings);
  const usernameavatar = settings?.data?.user_full_name?.split(' ')[0].slice(0, 1) + settings?.data?.user_full_name?.split(' ').pop().slice(0, 1)
  //const likeDislike = useSelector(state => state.chat.data.map(item => item.is_liked));
  //const sessionData = useSelector(state => state.sessionContent);
  const dispatch = useDispatch();

  const [isGettingReady, setIsGettingReady] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [categoryName, setCategoryName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [url, setUrl] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [canSendChatMessage, setCanSendChatMessage] = useState(false);
  const [isChatResponseLoading, setIsChatResponseLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem('language'))?.value);
  const [allMessages, setAllMessages] = useState(chat?.data || []);
  const [contentLoading, setContentLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const bottomRef = useRef(null);
  const [showWarning, setShowWarning] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);

  const [sourceWebUrls, setSourceWebUrls] = useState([]);
  const [sourceImageUrls, setSourceImageUrls] = useState([]);
  const [sourceVideoUrls, setSourceVideoUrls] = useState([]);
  const [activeSourceButton, setActiveSourceButton] = useState(0);
  const [showRelatedSources, setShowRelatedSources] = useState(false);

  const keyID = useId()
  const textAreaRef = useRef(null);

  const [showAddSingleFileModal, setShowAddSingleFileModal] = useState(false);
  const [showApiLimitationModal, setShowApiLimitationModal] = useState(false);

  const [selectedPrompt, setSelectedPrompt] = useState(1);
  const [selectedPromptExample, setSelectedPromptExample] = useState(null);

  const [likeStatus, setLikeStatus] = useState(null);
  const [dislikeStatus, setDislikeStatus] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const [currentMessage, setCurrentMessage] = useState(null);

  const markdownCorrectness = (text) => {
    return text
      .replace(/\*\*\s*(\S.*?)\s*\*\*/g, '**$1**')
      .replace(/(\d+)-(\S*?)/g, '$1.$2');
  }

  const handleSelectedPrompt = async (file) => {
    setSelectedPromptExample(file);
    setPrompt(file.examplePrompt);
    setCanSendChatMessage(true);
  }

  const handlePrePrompt = (id) => {
    setSelectedPrompt(id);
    setSelectedPromptExample(null);
    setPrompt('');
  }

  const handleOpenImagePreview = (file) => {
    setShowImagePreview(true);
    setImagePreviewUrl(file?.file_webp || file?.file);
    setDownloadUrl(file.download_url);
  }

  const handleCloseImagePreview = () => {
    setShowImagePreview(false);
    setImagePreviewUrl(null);
  }

  const handleCloseApiLimitationModal = () => {
    setShowApiLimitationModal(false);
  }
  const handleCloseAddFileModal = () => {
    setShowAddSingleFileModal(false);
  }

  const addSingleFile = () => {
    setShowAddSingleFileModal(true);
  }

  const addSingleFilePortrait = (category) => {
    setPrompt(category);
    sessionStorage.removeItem('session_ID')
    setShowAddSingleFileModal(true);
  }

  const handleChatTextChange = async (e) => {
    setPrompt(e.target.value);
    setSelectedPromptExample(item => {
      return {
        ...item,
        examplePrompt: e.target.value
      }
    })
    setSelectedLanguage(JSON.parse(localStorage.getItem('language'))?.value);
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter' && prompt.trim() !== '' && !event.shiftKey) {
      await handleQuestion();
    }
  }

  const handleRegeneration = async (regenerate, prompt, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('language', JSON.parse(localStorage.getItem('language'))?.value || 'en');
    formData.append('session_id', sessionStorage.getItem('session_ID') || chat.session || '');
    formData.append('prompt', prompt);
    formData.append('regenerate', regenerate);
    setIsChatResponseLoading(true);
    setIsGenerating(true);

    await fetch(process.env.REACT_APP_BASE_URL + '/chat/stream-chat-message/', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
      },
      body: formData,
      signal: abortController.signal
    })
      .then(response => {
        setShowRelatedSources(false)
        if (!response.ok) {
          throw new Error('Sth went wrong');
        }
        let currentSessionID;
        setShowTooltip(false)
        const stream = response.body;
        const reader = stream.getReader();
        var chunkTexts = '';
        const readChunk = () => {
          reader.read()
            .then(({ value, done }) => {
              if (done) {
                setShowTooltip(true)
                setShowRelatedSources(true)
                setIsGettingReady(false)
                setTimeout(() => {
                  textAreaRef?.current?.focus();
                }, [500]);
                return;
              }

              setIsChatResponseLoading(false);
              const chunkString = new TextDecoder().decode(value);

              if (chunkString.includes('create_date')) {
                setIsGettingReady(true)
                setIsGenerating(false)
                let jsonmsg = JSON.parse(chunkString.split('!-!-!-!-!')[0]);

                currentSessionID = jsonmsg.session;
                let textmsg = chunkString.split('!-!-!-!-!')[1];

                chunkTexts += jsonmsg.prediction
                chunkTexts += textmsg

                setSourceWebUrls(jsonmsg?.source_web_urls)
                setSourceImageUrls(jsonmsg.source_image_urls)
                setSourceVideoUrls(jsonmsg.source_video_urls)

                setAllMessages([...allMessages, jsonmsg])


              }
              else {
                chunkTexts += chunkString

                setAllMessages((prevMessages) => [
                  ...prevMessages.slice(0, prevMessages.length - 1),
                  {
                    ...prevMessages[prevMessages.length - 1],
                    prediction: chunkTexts
                  }
                ]);

              }
              if (sessionStorage.getItem('session_ID') && currentSessionID == sessionStorage.getItem('session_ID')) {
                readChunk();
              } else {
                setAllMessages([]);
                setPrompt('');
                setContentLoading(false);
                setSelectedPrompt(7);
                setIsGettingReady(false)
              }
            })
            .catch(error => {
              if (error.name === 'AbortError') {
                return;
              } else {
                updateMessage('Something went wrong! Please try again.', 'error')
              }
            });
        };
        readChunk();
      })
      .catch(error => {
        updateMessage('Something went wrong! Please try again.', 'error')
        setShowTooltip(true)
        setIsGettingReady(false)
        setIsChatResponseLoading(false);
      });
  }

  const handleLike = (message) => {
    if (message) {
      setCurrentMessage(message);
      const newLikeStatus = message?.is_liked ? null : true;
      dispatch(patchLikeAndDislike({
        messageId: message?.id,
        is_liked: newLikeStatus
      })).then((res) => {
        if (res.payload !== undefined) {
          setAllMessages((prevMessages) => {
            return prevMessages.map((item) => {
              if (item?.id === message?.id) {
                return {
                  ...item,
                  is_liked: res.payload.is_liked
                }
              }
              return item;
            })
          })
        }
      })
    }
  }


  useEffect(() => {
    handleLike(currentMessage);
  }, [likeStatus]);


  const handleDislike = (message) => {
    if (message) {
      setCurrentMessage(message);
      const newLikeStatus = message?.is_liked === false ? null : false;
      dispatch(patchLikeAndDislike({
        messageId: message?.id,
        is_liked: newLikeStatus
      })).then((res) => {
        if (res.payload !== undefined) {
          setAllMessages((prevMessages) => {
            return prevMessages.map((item) => {
              if (item?.id === message?.id) {
                return {
                  ...item,
                  is_liked: res.payload.is_liked
                }
              }
              return item;
            })
          })
        }
      })
    }
  }

  useEffect(() => {
    handleDislike(currentMessage);
  }, [dislikeStatus]);



  useEffect(() => {
    if (prompt !== null && prompt !== '' && prompt?.trim() !== '') {
      setCanSendChatMessage(true);
    }
    else if (selectedFile !== null) {
      setCanSendChatMessage(true);
    }
    else if (url !== null) {
      setCanSendChatMessage(true);
    }
    else if (selectedPromptExample !== null) {
      if (selectedPromptExample?.examplePrompt.trim() !== '') {
        setCanSendChatMessage(true);
      }
      else {
        setCanSendChatMessage(false);
      }
    }
    else {
      setCanSendChatMessage(false)
    }
  }, [prompt, selectedFile, url, selectedPromptExample])


  useEffect(() => {
    setShowRelatedSources(false)
    if (sessionStorage.getItem('session_ID')) {
      setContentLoading(true);
      dispatch(getSessionData(sessionStorage.getItem('session_ID')))
        .then((res) => {
          setAllMessages(res.payload?.results);
          setPrompt('');
          setSourceWebUrls(res.payload?.results[res.payload?.results.length - 1]?.source_web_urls)
          setSourceImageUrls(res.payload?.results[res.payload?.results.length - 1].source_image_urls)
          setSourceVideoUrls(res.payload?.results[res.payload?.results.length - 1].source_video_urls)
          res.payload.results[0].prediction.length > 30 && setShowRelatedSources(true);
          setContentLoading(false);
        })
    } else if (!sessionStorage.getItem('session_ID')) {
      setAllMessages([]);
      setPrompt('');
      setContentLoading(false);
      setSelectedPrompt(7)
    }
  }, [sessionStorage.getItem('session_ID')])

  const askQuestion = async () => {
    if (prompt.trim() === '' && selectedFile === null && url === null && selectedPromptExample === null) {
      return;
    }

    setAllMessages([...allMessages, {
      'message': prompt || selectedPromptExample?.examplePrompt,
      'tempFile': selectedFile || selectedPromptExample,
      'tempUrl': url
    }])

    setIsChatResponseLoading(true);
    setSelectedPromptExample(null);

    const formData = new FormData();
    if (selectedFile !== null) {
      formData.append("file", selectedFile);
    }
    if (selectedPromptExample !== null) {
      formData.append("file", selectedPromptExample);
    }
    if (url !== null) {
      formData.append("source_url", url);
    }
    formData.append('language', JSON.parse(localStorage.getItem('language'))?.value || 'en');
    formData.append('session_id', sessionStorage.getItem('session_ID') || chat.session || '')
    formData.append('prompt', prompt);

    await fetch(process.env.REACT_APP_BASE_URL + '/chat/stream-chat-message/', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
      },
      body: formData,
      signal: abortController.signal
    })
      .then(response => {
        setShowRelatedSources(false)
        if (!response.ok) {
          throw new Error('Sth went wrong');
        }
        let currentSessionID;
        setShowTooltip(false)
        const stream = response.body;
        const reader = stream.getReader();
        var chunkTexts = '';
        const readChunk = () => {
          reader.read()
            .then(({ value, done }) => {
              if (done) {
                dispatch(getChatHistoryList(1));
                setIsGenerating(false);
                setSelectedPromptExample(null);
                setSelectedPrompt(null);
                setShowTooltip(true)
                setIsGettingReady(false)
                setShowRelatedSources(true)
                setTimeout(() => {
                  bottomRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
                  textAreaRef?.current?.focus();
                }, [500]);
                return;
              }

              setIsChatResponseLoading(false);
              const chunkString = new TextDecoder().decode(value);

              if (chunkString.includes('create_date')) {
                setIsGettingReady(true)
                setSelectedFile(null);
                setPrompt('');
                let jsonmsg = JSON.parse(chunkString.split('!-!-!-!-!')[0]);
                sessionStorage.setItem('session_ID', jsonmsg.session);
                currentSessionID = jsonmsg.session;
                let textmsg = chunkString.split('!-!-!-!-!')[1];

                chunkTexts += jsonmsg.prediction
                chunkTexts += textmsg

                setSourceWebUrls(jsonmsg?.source_web_urls)
                setSourceImageUrls(jsonmsg.source_image_urls)
                setSourceVideoUrls(jsonmsg.source_video_urls)

                setAllMessages([...allMessages, jsonmsg])
              }
              else {
                chunkTexts += chunkString

                setAllMessages((prevMessages) => [
                  ...prevMessages.slice(0, prevMessages.length - 1),
                  {
                    ...prevMessages[prevMessages.length - 1],
                    prediction: chunkTexts
                  }
                ]);

              }
              if (sessionStorage.getItem('session_ID') && currentSessionID == sessionStorage.getItem('session_ID')) {
                readChunk();
              }
              else {
                setAllMessages([]);
                setPrompt('');
                setContentLoading(false);
                setSelectedPrompt(7);
                setIsGettingReady(false)
              }
            })
            .catch(error => {
              if (error.name === 'AbortError') {
                return;
              } else {
                updateMessage('Something went wrong! Please try again.', 'error')
              }
            });
        };
        readChunk();
      })
      .catch(error => {
        updateMessage('Something went wrong! Please try again.', 'error')
        setIsGenerating(false);
        setSelectedPromptExample(null);
        setSelectedPrompt(null);
        setShowTooltip(true)
        setIsGettingReady(false)
        setIsChatResponseLoading(false);
      });
  }

  const toDataURL = async url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))


  function dataURLtoFile(dataurl, filename = 'imageName.jpg') {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleQuestion = async () => {

    if (selectedPromptExample !== null && selectedFile === null) {
      if (selectedPromptExample.type === 'text') {
        await askDefaultQuestion(selectedPromptExample?.examplePrompt);
      } else {
        await askDefaultQuestion(selectedPromptExample?.examplePrompt, selectedPromptExample);
      }
    } else {
      await askQuestion();

    }
  }

  const askDefaultQuestion = async (question, file) => {
    const formData = new FormData();
    var fileData;
    if (file !== null && selectedPromptExample !== null && selectedPromptExample.type !== 'text' && selectedPromptExample.file) {
      await toDataURL(file.file)
        .then(dataUrl => {
          fileData = dataURLtoFile(dataUrl, file.name);
          formData.append("file", fileData);
        })
    }

    formData.append('language', JSON.parse(localStorage.getItem('language'))?.value || 'en');
    formData.append('session_id', sessionStorage.getItem('session_ID') || '');
    formData.append('prompt', question);

    setPrompt(question);
    setAllMessages([...allMessages, {
      'message': question,
      'tempFile': fileData
    }])
    setIsChatResponseLoading(true);
    setSelectedPromptExample(null);
    setSelectedPrompt(null);



    await fetch(process.env.REACT_APP_BASE_URL + '/chat/stream-chat-message/', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
      },
      body: formData,
      signal: abortController.signal
    })
      .then(response => {
        setShowRelatedSources(false)
        if (!response.ok) {
          throw new Error('Sth went wrong');
        }
        let currentSessionID;
        setShowTooltip(false)
        const stream = response.body;
        const reader = stream.getReader();
        var chunkTexts = '';
        const readChunk = () => {
          reader.read()
            .then(({ value, done }) => {
              if (done) {
                dispatch(getChatHistoryList(1));
                setIsGenerating(false);
                setSelectedPromptExample(null);
                setSelectedPrompt(null);
                setShowTooltip(true)
                setIsGettingReady(false)
                setShowRelatedSources(true);
                setTimeout(() => {
                  bottomRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
                  textAreaRef?.current?.focus();
                }, [500]);
                return;
              }

              setIsChatResponseLoading(false);
              const chunkString = new TextDecoder().decode(value);

              if (chunkString.includes('create_date')) {
                setIsGettingReady(true)
                setPrompt('');
                let jsonmsg = JSON.parse(chunkString.split('!-!-!-!-!')[0]);
                sessionStorage.setItem('session_ID', jsonmsg.session);
                currentSessionID = jsonmsg.session;
                let textmsg = chunkString.split('!-!-!-!-!')[1];

                chunkTexts += jsonmsg.prediction
                chunkTexts += textmsg

                setSourceWebUrls(jsonmsg?.source_web_urls)
                setSourceImageUrls(jsonmsg.source_image_urls)
                setSourceVideoUrls(jsonmsg.source_video_urls)

                setAllMessages([...allMessages, jsonmsg])

              }
              else {
                chunkTexts += chunkString

                setAllMessages((prevMessages) => [
                  ...prevMessages.slice(0, prevMessages.length - 1),
                  {
                    ...prevMessages[prevMessages.length - 1],
                    prediction: chunkTexts
                  }
                ]);

              }
              if (sessionStorage.getItem('session_ID') && currentSessionID == sessionStorage.getItem('session_ID')) {
                readChunk();
              }
              else {
                setAllMessages([]);
                setPrompt('');
                setContentLoading(false);
                setSelectedPrompt(7);
                setIsGettingReady(false)
              }
            })
            .catch(error => {
              if (error.name === 'AbortError') {
                return;
              } else {
                updateMessage('Something went wrong! Please try again.', 'error')
              }
            });
        };
        readChunk();
      })
      .catch(error => {
        updateMessage('Something went wrong! Please try again.', 'error')
        setIsGenerating(false);
        setSelectedPromptExample(null);
        setSelectedPrompt(null);
        setShowTooltip(true)
        setIsGettingReady(false)
        setIsChatResponseLoading(false);
      });
  }

  function stopResponseSSE() {
    abortController.abort();
    setAbortController(new AbortController());
    setIsGenerating(false);
    setSelectedPromptExample(null);
    setSelectedPrompt(null);
    setShowTooltip(true)
    setIsGettingReady(false)
    sendReceivedPrediction(allMessages[0].id, allMessages[allMessages.length - 1].prediction)
  }

  const sendReceivedPrediction = (messageId, prediction) => {
    AXIOS_INSTANCE.patch(`/chat-message/${messageId}/`, {
      'prediction': prediction
    })
      .then(res => {
        dispatch(getChatHistoryList(1));
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  const handleAddFile = (file) => {
    setUrl(null)
    setSelectedFile(file)
  }

  const handleAddUrl = (url) => {
    setSelectedFile(null);
    setUrl(url)
  }

  const removeSelectedFile = () => {
    setSelectedFile(null);
  }

  const removeSelectedPrompt = () => {
    setSelectedPromptExample(null);
    setSelectedPrompt(null);
    setPrompt('');
  }

  const removeSelectedUrl = () => {
    setUrl(null);
  }

  useEffect(() => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
    }, 100)
    //bottomRef.current?.scrollBy(0, 200)
  }, [allMessages, isGenerating, isChatResponseLoading])

  useEffect(() => {
    if (selectedFile !== null) {
      bottomRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
    }
  }, [selectedFile])

  useEffect(() => {
    if (url !== null) {
      bottomRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
    }
  }, [url])

  useEffect(() => {
    showTooltip && bottomRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
  }, [showTooltip])

  useEffect(() => {
    if (chat.message === "You have reached your api limit!") {
      //setShowApiLimitationModal(true); uncomment to show the modal
      console.log('You have reached your api limit!');

    } else if (chat.status === "failed" && chat.message === null) {
      updateMessage('Something went wrong! Please try again.', 'error')

    } else if (chat.status === "failed" && chat.statusCode >= 500) {
      updateMessage('Something went wrong! Please try again. Server', 'error');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat.message])



  useEffect(() => {
    if (process.env.REACT_APP_ON_PREM !== 'false') {
      const getRestartStat = () => {
        AXIOS_INSTANCE.get('/system-restart/')
          .then(res => {
            if (res.data.healthy) {
              setIsGettingReady(false);
            }
            else {
              setIsGettingReady(true);
            }
          })
      }

      getRestartStat();

      const intervalId = setInterval(() => {
        getRestartStat();
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, []);


  useEffect(() => {
    if (chat.session !== null || isChatResponseLoading || isGettingReady || sessionStorage.getItem('session_ID')) {
      setSelectedPrompt(null);
      setSelectedPromptExample(null);
    }

  }, [chat.session, isChatResponseLoading, isGettingReady])

  const goToDownloadApp = () => {
    window.open('https://www.chooch.com/imagechat-self-hosted-setup-guide/', "_blank");
  }

  useEffect(() => {
    setTimeout(() => {
      setShowWarning(false)
    }, 3000)
  }, [])

  const changeSourceType = (id) => {
    setActiveSourceButton(id);
  }

  const goToSourceUrl = (src_url) => {
    window.open(src_url, '_blank')
  }

  if (contentLoading && chat.session !== null && allMessages.length === 0) {
    return (
      <div className='d-flex align-items-center justify-content-center w-100 h-100'>
        <Loader />
      </div>
    )
  }


  return (
    <>
      <div className='search-results-content'>
        {showWarning &&
          <div className='not-responsible'>
            <p>Chooch is not responsible for the images created.</p>
            <img src={closeIcon} alt="close" onClick={() => setShowWarning(false)} />
          </div>
        }
        <div className='d-flex flex-column w-100'>
          <div className='chat-content'>
            {/* Empty Chat (self-*/}
            {
              (chat.session !== null || allMessages.length > 0) ?
                null
                :
                <>
                  {/*process.env.REACT_APP_ON_PREM === 'false' &&
                      <p className='self-hosted-download-banner mb-4'>
                        Build your next Generative AI App with <strong className='bold-banner-text'>&nbsp;ImageChat.&nbsp;</strong><strong className='download-btn' onClick={goToDownloadApp}>Download</strong>&nbsp;ImageChat Self-Hosted for free!
                      </p>
              */}
                  {
                    process.env.REACT_APP_ON_PREM !== 'false' &&
                    <div className="d-flex flex-column align-items-center mt-5">
                      <p className="empty-chat-header">Welcome to ImageChat {process.env.REACT_APP_ON_PREM === 'false' ? 'Web!' : ''}</p>
                      <p className="empty-chat-subheader">Start a conversation or upload a file </p>
                    </div>
                  }
                  <div className="suggestions-content-wrapper">

                    {/* Prompt Categories */}
                    {/*
                        prompts.map((item, index) => {
                          return (
                            <div key={item.id} className={`${item.id === selectedPrompt ? 'prompt-item-wrapper selected' : 'prompt-item-wrapper '}`}>
                              <div className="prompt-item" onClick={() => handlePrePrompt(item.id)}>
                                <h6 className="prompt-item-title gradient-text">
                                  {item.title}
                                </h6>
                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                              </div>
                            </div>
                          )
                        })
                      */}

                    {categories.map((item, index) => {
                      return (
                        <div key={item.id} className={`${item.id === selectedPrompt ? 'prompt-item-wrapper selected' : 'prompt-item-wrapper '}`}>
                          <div className="prompt-item" onClick={() => handlePrePrompt(item.id)}>
                            {index === 4 ?
                              darkTheme ?
                                <img src={item.imgDark} alt={item.id} className='ai-img' />
                                :
                                <img src={item.img} alt={item.id} className='ai-img' />
                              :
                              <img src={item.img} alt={item.id} className={index === 5 ? 'docs-img' : ''} />
                            }
                            <h6 className="prompt-item-title">
                              {item.title}
                            </h6>
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </>
            }

            {/* Chat Content - Messages */}
            <div className="messages" style={{ display: allMessages.length > 0 || chat.session !== null ? 'block' : 'none' }}>
              {
                (chat.session !== null || sessionStorage.getItem('session_ID') || allMessages.length > 0) ?
                  allMessages.map((messageItem, index) => {
                    let lastMessage = allMessages.length - 1 === index;
                    const checkSourceFile = messageItem?.files?.find(file => file.is_source === true);
                    return (
                      <div key={messageItem?.create_date + index}>
                        {
                          // chat with prompt no response yet
                          messageItem?.message ?
                            // prompt and file
                            messageItem?.tempFile ?
                              messageItem?.tempFile?.type.includes('image') ?
                                // file is image (temporary)
                                <>
                                  <div className="image-item">
                                    <img src={URL.createObjectURL(messageItem?.tempFile)} alt="src" className="img-file" />
                                  </div>

                                  <div className="d-flex align-items-start mb-4">
                                    <div className="user-avatar">{usernameavatar}</div>
                                    <div className='d-flex flex-column w-100'>
                                      <div className="bubble-name">
                                        You
                                      </div>
                                      <div className="user-message a1">
                                        {messageItem?.message}
                                      </div>
                                    </div>
                                  </div>
                                </>
                                :
                                // file is not image
                                <>
                                  <div className="doc-item">
                                    <div className="doc-item-name">
                                      <img src={getFileTypeLogo(messageItem?.tempFile?.type)} alt="pic-logo" /> {messageItem?.tempFile?.name}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-start mb-4">
                                    <div className="user-avatar">{usernameavatar}</div>
                                    <div className='d-flex flex-column w-100'>
                                      <div className="bubble-name">
                                        You
                                      </div>
                                      <div className="user-message a2">
                                        {messageItem?.message}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              :
                              <div className="d-flex align-items-start mb-4">
                                <div className="user-avatar">{usernameavatar}</div>
                                <div className='d-flex flex-column w-100'>
                                  <div className="bubble-name">
                                    You
                                  </div>
                                  <div className="user-message a3">
                                    {messageItem?.message}
                                  </div>
                                </div>
                              </div>
                            :
                            messageItem?.tempFile ?
                              messageItem?.tempFile?.type.includes('image') ?
                                // file is image (temporary)
                                <div className="image-item">
                                  <img src={URL.createObjectURL(messageItem?.tempFile)} alt="src" className="img-file preview" />
                                </div>
                                :
                                // file is not image
                                <div className="doc-item">
                                  <div className="doc-item-name">
                                    <img src={getFileTypeLogo(messageItem?.tempFile?.type)} alt="pic-logo" /> {messageItem?.tempFile?.name}
                                  </div>
                                </div>
                              :
                              // response from server
                              messageItem?.files?.length ?
                                messageItem?.files.map((file, i) => {
                                  return (
                                    <div key={i}>
                                      {
                                        file?.thumbnail ?
                                          file.is_source ?
                                            <>
                                              <div className="image-item 3" key={file?.thumbnail}>
                                                <Imgload file={file} handleOpenImagePreview={handleOpenImagePreview} />
                                                <a href={file.download_url}>
                                                  <OverlayTrigger
                                                    trigger={['hover', 'focus']}
                                                    placement="bottom"
                                                    overlay={
                                                      <Tooltip className="copy-chat-tooltip">Download</Tooltip>}
                                                  >
                                                    <img src={downloadIcon} alt="downloadIcon" className="download-icon" />
                                                  </OverlayTrigger>
                                                </a>
                                                <img src={imagePreviewIcon} alt="imagePreview" className="image-preview-icon" onClick={() => handleOpenImagePreview(file)} />
                                              </div>
                                              {
                                                messageItem?.prompt &&
                                                <div className="d-flex align-items-start mb-4" key={messageItem?.id}>
                                                  <div className="user-avatar">{usernameavatar}</div>
                                                  <div className='d-flex flex-column w-100'>
                                                    <div className="bubble-name">
                                                      You
                                                    </div>
                                                    <div className="user-message a4">
                                                      {messageItem?.prompt}
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                              {
                                                messageItem?.prediction &&
                                                <div className="prediction-wrapper">
                                                  <div className="d-flex align-items-start mb-2" key={messageItem?.id}>
                                                    <div className="chat-avatar"></div>
                                                    <div className='d-flex flex-column w-100'>
                                                      <div className="bubble-name">
                                                        ImageChat
                                                      </div>
                                                      <div className="ai-message">
                                                        <CustomMarkdown markdownText={messageItem?.prediction} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {
                                                    messageItem?.files?.filter(item => item.is_source === false).length > 0 ?
                                                      null
                                                      :
                                                      index + 1 === allMessages.length && !showTooltip ?
                                                        null
                                                        :
                                                        <div className="d-flex justify-content-start ms-3 ps-3 mb-4 gap-2">
                                                          <CustomCopy text={messageItem?.prediction} />

                                                          {
                                                            lastMessage &&
                                                            <OverlayTrigger
                                                              trigger={['hover', 'focus']}
                                                              placement="bottom"
                                                              overlay={
                                                                <Tooltip className="copy-chat-tooltip">Regenerate
                                                                  Response</Tooltip>}
                                                            >
                                                              <img src={regenerateIcon} alt="imagePreview" className="regenerate-icon" onClick={() => handleRegeneration(true, '', '')} />
                                                            </OverlayTrigger>
                                                          }
                                                          <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="bottom"
                                                            overlay={
                                                              <Tooltip className="copy-chat-tooltip">Good
                                                                Response</Tooltip>}
                                                          >
                                                            <img
                                                              src={messageItem?.is_liked === true ? likeIconDark : likeIcon}
                                                              alt="imagePreview"
                                                              className="like-icon"
                                                              onClick={() => handleLike(messageItem)}
                                                            />
                                                          </OverlayTrigger>

                                                          <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="bottom"
                                                            overlay={
                                                              <Tooltip className="copy-chat-tooltip">Bad
                                                                Response</Tooltip>}
                                                          >
                                                            <img
                                                              src={messageItem?.is_liked === false ? dislikeIconDark : dislikeIcon}
                                                              alt="imagePreview"
                                                              className="dislike-icon"
                                                              onClick={() => handleDislike(messageItem)}
                                                            />
                                                          </OverlayTrigger>

                                                        </div>
                                                  }

                                                </div>
                                              }
                                            </>
                                            :
                                            // image but not source file - generated file by the model
                                            <>
                                              {
                                                messageItem?.prompt && messageItem?.files?.length === 1 &&
                                                <div className="d-flex align-items-start mb-4" key={messageItem?.id}>
                                                  <div className="user-avatar">{usernameavatar}</div>
                                                  <div className='d-flex flex-column w-100'>
                                                    <div className="bubble-name">
                                                      You
                                                    </div>
                                                    <div className="user-message a5">
                                                      {messageItem?.prompt}
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                              {
                                                messageItem?.prediction && messageItem?.files?.length === 1 &&
                                                <div className="prediction-wrapper">
                                                  <div className="d-flex align-items-start mb-2" key={messageItem?.id}>
                                                    <div className="chat-avatar"></div>
                                                    <div className='d-flex flex-column w-100'>
                                                      <div className="bubble-name">
                                                        ImageChat
                                                      </div>
                                                      <div className="ai-message">
                                                        <Linkify
                                                          componentDecorator={(decoratedHref, decoratedText, key) => (
                                                            <a target="_blank" href={decoratedHref} key={key}>
                                                              {decoratedText}
                                                            </a>
                                                          )}
                                                        >
                                                          {messageItem?.prediction}
                                                        </Linkify>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                              <div className="image-item 3" key={keyID}>
                                                <Imgload file={file} handleOpenImagePreview={handleOpenImagePreview} />
                                                <img src={imagePreviewIcon} alt="imagePreview" className="image-preview-icon" onClick={() => handleOpenImagePreview(file)} />

                                                {/* icons */}
                                                <div>
                                                  <a href={file.download_url}>
                                                    <OverlayTrigger
                                                      trigger={['hover', 'focus']}
                                                      placement="bottom"
                                                      overlay={
                                                        <Tooltip className="copy-chat-tooltip">Download</Tooltip>}
                                                    >
                                                      <img src={downloadIcon} alt="downloadIcon" className="download-icon" />
                                                    </OverlayTrigger>

                                                  </a>
                                                  {
                                                    lastMessage &&
                                                    <OverlayTrigger
                                                      trigger={['hover', 'focus']}
                                                      placement="bottom"
                                                      overlay={
                                                        <Tooltip className="copy-chat-tooltip">Regenerate Response</Tooltip>}
                                                    >
                                                      <img src={regenerateIcon} alt="imagePreview" className="regenerate-icon" onClick={() => handleRegeneration(true, '', '')} />
                                                    </OverlayTrigger>
                                                  }
                                                </div>
                                                <div>
                                                  <OverlayTrigger
                                                    trigger={['hover', 'focus']}
                                                    placement="bottom"
                                                    overlay={<Tooltip className="copy-chat-tooltip">Good Response</Tooltip>}
                                                  >
                                                    <img
                                                      src={messageItem?.is_liked === true ? likeIconDark : likeIcon}
                                                      alt="imagePreview"
                                                      className="like-icon"
                                                      onClick={() => handleLike(messageItem)}
                                                    />
                                                  </OverlayTrigger>

                                                  <OverlayTrigger
                                                    trigger={['hover', 'focus']}
                                                    placement="bottom"
                                                    overlay={<Tooltip className="copy-chat-tooltip">Bad Response</Tooltip>}
                                                  >
                                                    <img
                                                      src={messageItem?.is_liked === false ? dislikeIconDark : dislikeIcon}
                                                      alt="imagePreview"
                                                      className="dislike-icon"
                                                      onClick={() => handleDislike(messageItem)}
                                                    />
                                                  </OverlayTrigger>
                                                </div>
                                              </div>
                                            </>
                                          :
                                          // doc file
                                          <>
                                            <div className="doc-item" key={i}>
                                              <div className="doc-item-name">
                                                <img src={getFileTypeLogo(file.get_file_extension)} alt="pic-logo" /> {file.file_name}
                                                <a href={file.download_url}>
                                                  <img src={downloadIconDoc} alt="downloadIconDoc" className="doc-download-icon" />
                                                </a>
                                              </div>
                                            </div>
                                            {
                                              messageItem?.prompt &&
                                              <div className="d-flex align-items-start mb-4" key={messageItem?.prediction}>
                                                <div className="user-avatar">{usernameavatar}</div>
                                                <div className='d-flex flex-column w-100'>
                                                  <div className="bubble-name">
                                                    You
                                                  </div>
                                                  <div className="user-message a6">
                                                    {messageItem?.prompt}
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                            {
                                              messageItem?.prediction &&
                                              <div className="prediction-wrapper">
                                                <div className="d-flex align-items-start mb-2" key={messageItem?.prediction}>
                                                  <div className="chat-avatar"></div>
                                                  <div className='d-flex flex-column w-100'>
                                                    <div className="bubble-name">
                                                      ImageChat
                                                    </div>
                                                    <div className="ai-message">
                                                      <CustomMarkdown markdownText={messageItem?.prediction} />
                                                    </div>
                                                  </div>
                                                </div>
                                                {index + 1 === allMessages.length && !showTooltip ?
                                                  null
                                                  :
                                                  <div className="d-flex justify-content-start ms-3 ps-3 mb-3 gap-2">
                                                    <CustomCopy text={messageItem?.prediction} />

                                                    {
                                                      lastMessage &&
                                                      <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        placement="bottom"
                                                        overlay={<Tooltip className="copy-chat-tooltip">Regenerate Response</Tooltip>}
                                                      >
                                                        <img src={regenerateIcon} alt="imagePreview" className="regenerate-icon" onClick={() => handleRegeneration(true, '', '')} />
                                                      </OverlayTrigger>
                                                    }
                                                    <OverlayTrigger
                                                      trigger={['hover', 'focus']}
                                                      placement="bottom"
                                                      overlay={<Tooltip className="copy-chat-tooltip">Good Response</Tooltip>}
                                                    >
                                                      <img
                                                        src={messageItem?.is_liked === true ? likeIconDark : likeIcon}
                                                        alt="imagePreview"
                                                        className="like-icon"
                                                        onClick={() => handleLike(messageItem)}
                                                      />
                                                    </OverlayTrigger>

                                                    <OverlayTrigger
                                                      trigger={['hover', 'focus']}
                                                      placement="bottom"
                                                      overlay={<Tooltip className="copy-chat-tooltip">Bad Response</Tooltip>}
                                                    >
                                                      <img
                                                        src={messageItem?.is_liked === false ? dislikeIconDark : dislikeIcon}
                                                        alt="imagePreview"
                                                        className="dislike-icon"
                                                        onClick={() => handleDislike(messageItem)}
                                                      />
                                                    </OverlayTrigger>

                                                  </div>
                                                }
                                              </div>
                                            }
                                          </>
                                      }
                                    </div>
                                  )
                                })
                                :
                                messageItem?.prediction &&
                                <>
                                  {
                                    messageItem?.prompt &&
                                    <div className="d-flex align-items-start mb-4" key={messageItem?.id}>
                                      <div className="user-avatar">{usernameavatar}</div>
                                      <div className='d-flex flex-column w-100'>
                                        <div className="bubble-name">
                                          You
                                        </div>
                                        <div className="user-message a7">
                                          {messageItem?.prompt}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  <div className="prediction-wrapper">
                                    <div className="d-flex align-items-start mb-2" key={messageItem?.id}>
                                      <div className="chat-avatar"></div>
                                      <div className='d-flex flex-column w-100'>
                                        <div className="bubble-name">
                                          ImageChat
                                        </div>
                                        <div className="ai-message">
                                          <CustomMarkdown markdownText={messageItem?.prediction} />
                                        </div>
                                      </div>
                                    </div>
                                    {index + 1 === allMessages.length && !showTooltip ?
                                      null
                                      :
                                      <div className="d-flex justify-content-start ms-3 ps-3 mb-3 gap-2">
                                        <CustomCopy text={messageItem?.prediction} />

                                        {
                                          lastMessage && sourceWebUrls.length === 0 && sourceImageUrls.length === 0 && sourceVideoUrls.length === 0 ?
                                            <OverlayTrigger
                                              trigger={['hover', 'focus']}
                                              placement="bottom"
                                              overlay={<Tooltip className="copy-chat-tooltip">Regenerate Response</Tooltip>}
                                            >
                                              <img src={regenerateIcon} alt="imagePreview" className="regenerate-icon" onClick={() => handleRegeneration(true, '', '')} />
                                            </OverlayTrigger>
                                            :
                                            null
                                        }
                                        <OverlayTrigger
                                          trigger={['hover', 'focus']}
                                          placement="bottom"
                                          overlay={<Tooltip className="copy-chat-tooltip">Good Response</Tooltip>}
                                        >
                                          <img
                                            src={messageItem?.is_liked === true ? likeIconDark : likeIcon}
                                            alt="imagePreview"
                                            className="like-icon"
                                            onClick={() => handleLike(messageItem)}
                                          />
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                          trigger={['hover', 'focus']}
                                          placement="bottom"
                                          overlay={<Tooltip className="copy-chat-tooltip">Bad Response</Tooltip>}
                                        >
                                          <img
                                            src={messageItem?.is_liked === false ? dislikeIconDark : dislikeIcon}
                                            alt="imagePreview"
                                            className="dislike-icon"
                                            onClick={() => handleDislike(messageItem)}
                                          />
                                        </OverlayTrigger>

                                      </div>
                                    }

                                  </div>
                                </>

                        }
                      </div>
                    )
                  })
                  :
                  null
              }
              {isChatResponseLoading &&
                <ChatResponseSpinner withText={true} />
              }

              {/*{showRelatedSources && !isChatResponseLoading ?
                sourceWebUrls.length > 0 || sourceImageUrls.length > 0 || sourceVideoUrls.length > 0 ?
                  <Accordion className='source-accordion'>
                    <Accordion.Item eventKey="0" onClick={() => setTimeout(() => {
                      bottomRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
                    }, 300)}>
                      <Accordion.Header>Related Sources</Accordion.Header>
                      <Accordion.Body>
                        <div className='source-buttons'>
                          <div className={`source-button ${activeSourceButton === 0 && 'active'}`} onClick={() => changeSourceType(0)}>Links</div>
                          <div className={`source-button ${activeSourceButton === 1 && 'active'}`} onClick={() => changeSourceType(1)}>Images</div>
                          <div className={`source-button ${activeSourceButton === 2 && 'active'}`} onClick={() => changeSourceType(2)}>Videos</div>
                        </div>
                        {activeSourceButton === 0 ?
                          <div className='source-urls'>
                            {sourceWebUrls.map((item, index) => {
                              return (
                                <div key={index} onClick={() => goToSourceUrl(item.source_url)}>
                                  {item.source_url}
                                </div>
                              )
                            })}
                          </div>
                          :
                          activeSourceButton === 1 ?
                            <div className='source-images'>
                              {sourceImageUrls.map((item, index) => {
                                return (
                                  <div key={index} onClick={() => goToSourceUrl(item.source_url)}>
                                    <img src={item.source_thumbnail} alt={index} />
                                  </div>
                                )
                              })}
                            </div>
                            :
                            <div className='source-videos'>
                              {sourceVideoUrls.map((item, index) => {
                                return (
                                  <div key={index} onClick={() => goToSourceUrl(item.source_url)}>
                                    <img src={item.source_thumbnail} alt={index} />
                                    <PlayCircleFill className='play-icon' />
                                  </div>
                                )
                              })}
                            </div>
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  : null
                : null
              }*/}



              <div ref={bottomRef} className="mt-2" key={bottomRef}></div>
            </div>

            {/* Pre-Prompt Examples */}

            {
              selectedPrompt !== null && chat.session === null ?
                selectedPrompt === 7 ?
                  <UrlSuggestions />
                  :
                  <div className="suggestions-content mt-auto second-step" key={keyID}>
                    <div className="try-this-out">Try these out!</div>
                    <Carousel categories={categories} selectedPromptExample={selectedPromptExample} selectedPrompt={selectedPrompt} handleSelectedPrompt={handleSelectedPrompt} addSingleFilePortrait={addSingleFilePortrait} />
                    <div className="ai-category-message" dangerouslySetInnerHTML={{ __html: categories[selectedPrompt - 1].description }}></div>
                  </div>
                :
                null
            }

            {/*Input Area*/}
            <div className={`${selectedPrompt === null ? 'mt-auto' : ''} message-input-area first-step`}>
              <div role="button" className={'d-flex align-items-center pe-2'} onClick={() => !isChatResponseLoading && !isGettingReady && addSingleFile()} style={{ cursor: isChatResponseLoading || isGettingReady ? 'not-allowed' : 'pointer' }}>
                <img src={`${isChatResponseLoading || isGettingReady ? CirclePlusDisabled : darkTheme ? CirclePlusWhite : CirclePlus}`} alt="circle-plus" />
              </div>
              {isChatResponseLoading ?
                <div className={'input-area'}>
                  <TextArea
                    value={''}
                    onChange={(e) => handleChatTextChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Message"
                    autoSize={{
                      minRows: 1,
                      maxRows: 5,
                    }}
                    className={'autogrow-textarea getting-ready-placeholder'}
                    disabled={true}
                  />
                  <button className={`send-message-btn disabled`} disabled={true}></button>
                </div>
                :
                <div
                  className={
                    selectedFile && isMobile ?
                      !selectedFile.type.includes('image') ?
                        'input-area active-border flex-wrap'
                        :
                        'input-area active-border'
                      :
                      canSendChatMessage && prompt.trim() !== "" ?
                        'input-area active-border'
                        :
                        'input-area'
                  }
                >

                  {selectedFile ?
                    selectedFile.type.includes('image') ?
                      <div className='img-preview'>
                        <XCircleFill className='remove-chat-img' onClick={removeSelectedFile} />
                        <img src={URL.createObjectURL(selectedFile)} alt="preview" />
                      </div>
                      :
                      <div className='doc-preview'>
                        <X className='remove-chat-doc' onClick={removeSelectedFile} />
                        <img src={getFileTypeLogo(selectedFile.type)} alt="pic-logo" />
                        <div className='doc-item-name'>
                          {selectedFile.name}
                        </div>
                      </div>
                    :
                    selectedPromptExample ?
                      selectedPromptExample.type === 'image' && selectedPromptExample.file ?
                        <div className='img-preview'>
                          <X className='remove-chat-img' onClick={removeSelectedPrompt} />
                          <img src={selectedPromptExample.file} alt="preview" />
                        </div>
                        :
                        selectedPromptExample.type === 'document' ?
                          <div className="doc-preview">
                            <X className="remove-chat-doc" onClick={removeSelectedPrompt} />
                            <img src={getFileTypeLogo(selectedPromptExample.fileType)} alt="pic-logo" />
                            <div className="doc-item-name">
                              {selectedPromptExample.name}
                            </div>
                          </div>
                          :
                          null
                      :
                      null
                  }

                  {url &&
                    <div className="img-preview">
                      <X className="remove-chat-img" onClick={removeSelectedUrl} />
                      <img src={UrlThumb} alt="preview" />
                    </div>
                  }

                  <TextArea
                    ref={textAreaRef}
                    autoFocus
                    value={prompt}
                    onChange={(e) => handleChatTextChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder={isGettingReady ? "The system is getting ready. Please wait for a while." : "Message"}
                    autoSize={{
                      minRows: 1,
                      maxRows: 5,
                    }}

                    className={
                      !isGettingReady && isMobile && selectedFile && !selectedFile.type.includes('image') ?
                        'autogrow-textarea getting-ready-placeholder flexible-mobile-input'
                        :
                        isGettingReady ?
                          'autogrow-textarea getting-ready-placeholder'
                          :
                          'autogrow-textarea'
                    }
                    disabled={isGettingReady || isChatResponseLoading}
                  />
                  {isGettingReady ?
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={
                        <Tooltip className="copy-chat-tooltip">Stop Generating</Tooltip>}
                    >
                      <img src={stopIcon} alt="stop" className='stop-generating-btn' onClick={() => stopResponseSSE()} />

                    </OverlayTrigger>


                    :
                    <button className={`send-message-btn ${!canSendChatMessage && prompt.trim() === '' && 'disabled'}`} disabled={!canSendChatMessage && prompt.trim() === ''} onClick={handleQuestion}></button>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <AddSingleFileModal
        show={showAddSingleFileModal}
        handleAddFile={handleAddFile}
        handleAddUrl={handleAddUrl}
        handleClose={handleCloseAddFileModal}
        categoryName={categoryName}
      />

      <ApiLimitationWarningModal show={showApiLimitationModal} handleCloseModal={handleCloseApiLimitationModal} />
      <ImagePreviewModal show={showImagePreview} handleCloseModal={handleCloseImagePreview} image={imagePreviewUrl} download={downloadUrl} />
    </>
  )
}
