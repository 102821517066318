import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function Base64ImageConverter({ url, fileType="jpg", clsName }) {
  const [imageUrl, setImageUrl] = useState(url);
  const [showPreview, setShowPreview] = useState(false);


    const openPreview = () => {
        setShowPreview(true);
    };

    const closePreview = () => {
        setShowPreview(false);
    };

  useEffect(() => {
    if (url) {
      setImageUrl(url);
    }
  }, [url]);


    return (
    <>
      {imageUrl && (
        <div className="image-preview-wrapper">
            <img src={url} alt="Converted Item" className={clsName} onClick={openPreview}/>
            {
              showPreview && (
                <Modal show={showPreview} onHide={closePreview} centered size="lg">
                  <Modal.Header closeButton />
                  <Modal.Body>
                    <img src={url} alt="Converted Item" className='w-100'/>
                  </Modal.Body>
                </Modal>
              )
            }
        </div>
      )}
    </>
  );
}
