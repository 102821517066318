import choochLogoSmall from '../../assets/images/choochLogoSmall.svg'

import pdfBig from '../../assets/images/pdfLogoBig.png';
import docBig from '../../assets/images/docLogoBig.png';
import pptBig from '../../assets/images/pptLogoBig.png';
import xlsBig from '../../assets/images/xlsLogoBig.png';

import pdf from '../../assets/images/pdfLogo.png';
import doc from '../../assets/images/docLogoBig.png';
import ppt from '../../assets/images/pptLogoBig.png';
import xls from '../../assets/images/xlsLogoBig.png';
import txt from '../../assets/images/txtLogo.png';
import csv from '../../assets/images/csvLogo.png';
import picture from '../../assets/images/picture.svg';
import urlLink from '../../assets/images/linkLogo.png';
import { backendPort } from '../../config';

const fileTypesLogoBig = [
  {
    extension: 'pdf',
    logo: pdfBig
  },
  {
    extension: 'docx' || 'doc',
    logo: docBig
  },
  {
    extension: 'pptx' || 'ppt',
    logo: pptBig
  },
  {
    extension: 'xlsx' || 'xls',
    logo: xlsBig
  }
]

const fileTypesLogo = [
  {
    extension: 'pdf',
    logo: pdf
  },
  {
    extension: 'docx' || 'doc',
    logo: doc
  },
  {
    extension: 'pptx' || 'ppt',
    logo: ppt
  },
  {
    extension: 'xlsx' || 'xls',
    logo: xls
  },
  {
    extension: 'txt',
    logo: txt
  },
  {
    extension: 'csv',
    logo: csv
  },
  {
    extension: 'jpg' || 'jpeg' || 'png',
    logo: picture
  },
  {
    extension: null,
    logo: urlLink
  }
]

export const getFileTypeLogoBig = (fileType) => {
  const fileExtension = fileType?.get_file_extension || fileType;
  const getFileExtension = fileTypesLogoBig.filter(file => file.extension === fileExtension);
  return getFileExtension[0]?.logo || choochLogoSmall;
}

export const getFileTypeLogo = (fileType) => {

  /*if(fileType?.file_name === "" && fileType?.source_url !== '') {
    return youtube;
  }*/

  const fileExtension = fileType?.get_file_extension || fileType;

  const getFileExtension = fileTypesLogo.filter(file => file.extension === fileExtension);
  //const getYoutubeLogo = fileTypesLogo.filter(file => file.source_url === true);
  return getFileExtension[0]?.logo || choochLogoSmall;
}


export const languageOptions = [
  { value: 'en', label: 'English (default)' },
  { value: 'ar', label: 'Arabic' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'ca', label: 'Catalan' },
  { value: 'zh', label: 'Chinese' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'nl', label: 'Dutch' },
  { value: 'et', label: 'Estonian' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'iw', label: 'Hebrew' },
  { value: 'hi', label: 'Hindi' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'id', label: 'Indonesian' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'kn', label: 'Kannada' },
  { value: 'ko', label: 'Korean' },
  { value: 'lv', label: 'Latvian' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mr', label: 'Marathi' },
  { value: 'ne', label: 'Nepali' },
  { value: 'no', label: 'Norwegian' },
  { value: 'fa', label: 'Persian' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'pa', label: 'Punjabi' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'sr', label: 'Serbian' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'es', label: 'Spanish' },
  { value: 'sw', label: 'Swahili' },
  { value: 'sv', label: 'Swedish' },
  { value: 'ta', label: 'Tamil' },
  { value: 'te', label: 'Telugu' },
  { value: 'th', label: 'Thai' },
  { value: 'tr', label: 'Turkish' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'vi', label: 'Vietnamese' },
]


export async function base64ToImageUrl(base64String, fileType) {

  const imageType = fileType || 'image/png';
  const base64WithoutHeader = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
  const binary = atob(base64WithoutHeader);
  const arrayBuffer = new ArrayBuffer(binary.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binary.length; i++) {
    uint8Array[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: imageType });
  return URL.createObjectURL(blob);
}

export const dataToUrl = async (data, fileType='image') => {
  const blob = new Blob([data], { type: fileType });
  return URL.createObjectURL(blob);
};

export const getSupportedFileTypes = () => {
  return JSON.stringify(JSON.parse(localStorage.getItem('supported_extensions')))
    .replace(/"/g, '')
    .replace(/,/g, ', ')
    .replace(/\s+/g, ' .')
    .replace(/\[/g, '.')
    .replace(/]/g, '')
};

export const encodeHTML = (input) => {
  const lookup = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return input.replace(/[&<>"']/g, match => lookup[match]);
};




export const getRefreshURL = () => {
  let host = window.location.origin;
  const hostArray = host.split(':');

  if(process.env.REACT_APP_ON_PREM === 'false') {
    return process.env.REACT_APP_BASE_URL;
  } else {
    return hostArray[0] + ':' + hostArray[1] + `:${backendPort}`;
  }
}

export const getApiURL = () => {
  return getRefreshURL() + '/chat';
}
