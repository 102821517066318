import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  deleteSession,
  hideModal
} from '../state/slices/chatHistoryListSlice';
import {
  createNewChat
} from '../state/slices/chatSlice';

export default function DeleteModal(props) {

  const { selectedIds } = props;
  const chatHistoryList = useSelector(state => state.chatHistoryList);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  }

  const handleDelete = async () => {
    await dispatch(deleteSession(selectedIds))
    .then(() => {
      sessionStorage.removeItem('session_ID');
      props.clearDeleteStatus();
    });
    dispatch(hideModal());
    dispatch(createNewChat());
    sessionStorage.removeItem('session_ID');
  }

  return (
    <Modal className='integration-modal-delete' show={chatHistoryList.modal} onHide={closeModal} centered size='md'>
      <Modal.Header closeButton>
        <Modal.Title><ExclamationCircleFill color='#EB5857' size={20} className='me-3' /> Delete Chat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The selected chat history will be deleted. Are you sure you want to delete?
      </Modal.Body>
      <Modal.Footer>
        <Button className='transparent-btn' onClick={closeModal}>Cancel</Button>
        <Button className='red-btn' onClick={handleDelete}>
          {chatHistoryList.status === 'loading' ?
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />
            :
            'Delete'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
