import React from 'react'
import { Navigate } from 'react-router-dom'
import Header from './Header';
import { useAuth } from './utils/AuthContext';
import { Row, Col, Container } from 'react-bootstrap';

export default function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated !== "true") {
    return <Navigate to="/login" />
  }

  return (
    <Container fluid className='d-flex flex-column p-0'>
      <Row className='m-0 p-0'>
        <Col className='m-0 p-0'>
          <Header />
        </Col>
      </Row>
      <div >
      {children}
      </div>
    </Container>
  );
}
