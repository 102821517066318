import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { Button, Form } from 'react-bootstrap'
import { Spinner } from 'react-bootstrap'
import companyLogo from '../assets/images/choochLogo.png'
import {
  ArrowRight,
  Eye,
  EyeSlash
} from 'react-bootstrap-icons';
import { AXIOS_INSTANCE } from './utils/axiosInstance'
import { useAuth } from "./utils/AuthContext"
import LoginImage from '../assets/images/login-image.png';
import imageChatTitle from '../assets/images/imagechat-title.svg';
import LoginGif from '../assets/images/login-api-code.gif';
import ImageGeneration from '../assets/images/Hero-ImageGeneration.gif';
import AppStoreLogo from '../assets/images/app-store-logo.svg';
import GooglePlayLogo from '../assets/images/google-play-logo.svg';
import GoogleLogin from '../assets/images/google.png';
import FacebookLogin from '../assets/images/logos_facebook.png';
import ChevronLeft from '../assets/images/chevronLeft.png'
import { useToast } from './utils/ToastContext';
import {
  encodeHTML,
  getRefreshURL
} from './utils/generic';
import { resetApp } from '../state/slices/resettableSlice';
import { useDispatch } from 'react-redux';
import GoogleAndFacebookLoginModal
  from './utils/GoogleAndFacebookLoginModal';
import axios from 'axios';
import VerifyEmail from './VerifyEmail';
import DOMPurify from 'dompurify';
import { useTheme } from './utils/ThemeContext';
import { Helmet } from 'react-helmet-async';

export default function Login() {
  const { toggleTheme } = useTheme()
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { updateMessage } = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [canLogin, setCanLogin] = useState(false);
  const { setUser, userAccountType, isAuthenticated } = useAuth();
  const [rememberUser, setRememberUser] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [socialLoginUser, setSocialLoginUser] = useState(false)
  const [socialLoginCode, setSocialLoginCode] = useState( null)
  const [verifyEmail, setVerifyEmail] = useState(false)

  // no special characters like <, >, etc.
  const passwordRegex = /^[^<>]*$/;
  const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  useEffect(() => {
    if (isAuthenticated === 'true') {
      navigate('/home');
    }
  }, []);

  useEffect(() => {
    if(localStorage.getItem('isRefreshTokenExpired') === 'true') {
      updateMessage('Your session has expired. Please log in again.', 'error');
      localStorage.setItem('isRefreshTokenExpired', 'false')
    }
  }, []);

  const handleEmailChange = e => {
    e.preventDefault();
    setEmail(e.target.value)
    setIsInvalid(false)
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  const handlePasswordChange = e => {
    e.preventDefault();

    setPassword(e.target.value)
    setIsInvalid(false)
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      if(mailRegex.test(email) && passwordRegex.test(password)) {
        setCanLogin(true);
      } else {
        setCanLogin(false);
      }
    } else {
      setCanLogin(false);
    }
  }, [email, password])


  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    let code = searchParams.get('code');
    let token = searchParams.get('token');
    if (code) {
      getToken(code, 'code');
    } else if(token) {
      getToken(token, 'token');
    }
  }, []);

  const getToken = (code = null, type = 'code') => {

    AXIOS_INSTANCE(
        {
          method: 'post',
          url: getRefreshURL() + '/authentication/token/',
          data: {
            'username': email || null,
            'password': password || null,
             [type]: code
          }
        })
        .then(res => {
          //getUserAccountType();
          dispatch(resetApp());
          setUser(email, rememberUser)
          setIsInvalid(false)
          localStorage.setItem('rememberUser', rememberUser)
          if (rememberUser) {
            localStorage.setItem('access_token', res.data.access)
            localStorage.setItem('refresh_token', res.data.refresh)
          }
          else {
            sessionStorage.setItem('access_token', res.data.access)
            sessionStorage.setItem('refresh_token', res.data.refresh)
          }
          getThemeStatus();
        })
        .catch(error => {
          console.log('error: login', error)
          if(error.response.data.code === 'not_verified') {
            updateMessage('Please verify your email to log in.', 'error');
            setVerifyEmail(true);
          }
          setIsLoading(false)
          setIsInvalid(true)
        });
  }

  const handleSocialAppLogin = async (socialApp) => {

    axios.get(`${getRefreshURL()}/authentication/social/${socialApp}/`)
      .then((res) => {
        window.location.href = res.data.url
      })
      .catch(error => {
        console.log(error)
      });
  }


  const handleLogin = () => {
    setIsLoading(true)
    //localStorage.clear()
    sessionStorage.clear()
    getToken()
  }

  const getSupportedFiles = () => {
    AXIOS_INSTANCE.get('/supported-extension/')
        .then((res) => {
          localStorage.setItem('supported_extensions', JSON.stringify(res.data))
          navigate("/home");
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
        });
  }

  const getThemeStatus = () => {
    AXIOS_INSTANCE.get('/user-setting/')
        .then((res) => {
          toggleTheme(res.data.dark_mode ? 'dark' : 'light')
          getSupportedFiles()
        })
        .catch(error => {
          console.log(error)
        });
  }


  const goToGooglePlay = () => {
    const url = 'https://play.google.com/store/apps/details?id=com.chooch.imagechat'
    const cleanURL = DOMPurify.sanitize(url)
    window.open(cleanURL, '_blank')
  }

  const goToAppStore = () => {
    const url = 'https://apps.apple.com/us/app/imagechat-ai-computer-vision/id1304120928';
    const cleanURL = DOMPurify.sanitize(url)
    window.open(cleanURL, '_blank')
  }

  const goToForgotPassword = () => {
    navigate('/forgot-password')
  }

  const goToProductsPage = () => {
    window.open(process.env.REACT_APP_DASHBOARD_URL, "_self")
  }

  const goToSignUp = () => {
    navigate('/register')
  }

  const goToAIVision = () => {
    window.open('https://app.chooch.ai/feed/user-settings#security', "_blank");
  }


  return (
    <div className='d-flex'>
      <Helmet>
        <title>Log in to ImageChat | Create Free, Unlimited Content</title>
        <meta name="description" content="Log in to ImageChat and create free, unlimited images and content. All unique, original, and copyright-free. Start now and unleash your creativity with ImageChat!"></meta>

        <meta property="og:url" content="https://imagechatweb.chooch.ai/login"></meta>
        <meta property="og:title" content="Log in to ImageChat | Chooch"></meta>
        <meta property="og:description" content="Log in to ImageChat and create free, unlimited images and content. All unique, original, and copyright-free. Start now and unleash your creativity with ImageChat!"></meta>

        <meta name="twitter:title" content="Log in to ImageChat | Chooch"></meta>
        <meta name="twitter:description" content="Log in to ImageChat and create free, unlimited images and content. All unique, original, and copyright-free. Start now and unleash your creativity with ImageChat!"></meta>
        <link rel="canonical" href="https://imagechatweb.chooch.ai/login"></link>
      </Helmet>
      <div className='login-left-banner'>
        <div className='banner-content'>
          <div className='banner-header'>
            {/*<p className='banner-title'>Welcome to Chooch</p>*/}
            <div className='banner-text-body'>
             {/* <p className='banner-sur-text'>
                Have you tried ImageChat?
              </p>*/}
              <h2 className='banner-subtitle'>
                Start chatting with your data.
              </h2>
              <h3 className='banner-text'>
                Log In to experience ImageChat&ndash;Web!
              </h3>
            </div>
          </div>
          <div className='login-gif-wrapper'>
            <div className='responsive-gif-wrapper'><img src={ImageGeneration} alt="login-image" className='login-image' /></div>
          </div>
          <div className='mobile-app-wrapper'>
            <p className='mobile-apps-text'>Download the ImageChat app for free</p>
            <div className='d-flex mobile-apps'>
              <img src={AppStoreLogo} alt="login" className='me-3' onClick={goToAppStore} />
              <img src={GooglePlayLogo} alt="login" onClick={goToGooglePlay} />
            </div>
          </div>
        </div>
      </div>
      <div className='login-right'>
        <img src={ChevronLeft} alt="back" className='back-btn' onClick={goToProductsPage}/>
        <div className="login-content">
          <img className="login-brand-title" src={imageChatTitle} alt="image-chat-title"/>
          <img className="login-brand-logo" src={companyLogo} alt="logo"/>
          {
            verifyEmail ?
              <VerifyEmail verifyEmail={verifyEmail} setVerifyEmail={setVerifyEmail}/>
              :
              <>
                <h1 className="login-header">
                  Log In
                  to <span className="gradient-header-text">ImageChat Web</span>
                </h1>
                <Form.Group className="password-input">
                  <Form.Control isInvalid={isInvalid} className="login-input" type="email" placeholder="Email" value={email} onKeyDown={handleKeyPress} onChange={e => handleEmailChange(e)}/>
                  <Form.Control.Feedback type="invalid">
                    Invalid email or password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="password-input">
                  <Form.Control isInvalid={isInvalid} className="login-input" type={isPasswordVisible ? 'text' : 'password'} placeholder="Password" onKeyDown={handleKeyPress} value={password} onChange={e => handlePasswordChange(e)}/>
                  <Form.Control.Feedback type="invalid">
                    Invalid email or password.
                  </Form.Control.Feedback>
                  {
                    !isInvalid ?
                      isPasswordVisible ?
                        <EyeSlash size={20} className="pass-visibility-btn" onClick={() => setIsPasswordVisible(!isPasswordVisible)}/>
                        :
                        <Eye size={20} className="pass-visibility-btn" onClick={() => setIsPasswordVisible(!isPasswordVisible)}/>
                      :
                      null
                  }

                </Form.Group>

                <div className="d-flex align-items-center justify-content-end">
                  {/*<Form.Check type="checkbox" label="Remember Me" className="remember-me" checked={rememberUser} onChange={() => setRememberUser(!rememberUser)}/>*/}
                  <p className="forgot-password-btn">
                    <span onClick={goToForgotPassword}>Forgot your password?</span>
                  </p>
                </div>

                <div className="login-button-wrapper cloud-login-btn-wrapper mb-xl-4">
                  {
                    isLoading ?
                      <Button className="purple-btn w-100" disabled={true}><Spinner animation="border"/></Button>
                      :
                      canLogin ?
                        <Button className="purple-btn w-100" onClick={handleLogin}>
                          Log In
                        </Button>
                        :
                        <Button className="disabled-btn w-100" disabled={true}>
                          Log In
                        </Button>
                  }
                </div>

                <div className="or-text d-flex align-items-center justify-content-center">
                  - OR -
                </div>

                <div className="info-text ai-vision-redirection-info google-facebook-info">
                  <div className="google-users-login" onClick={() => handleSocialAppLogin('google')}>
                    <img src={GoogleLogin} alt={'google-login'} width={'26px'}/>
                    <span>Log In with Google</span>
                  </div>
                  <div className="facebook-users-login" onClick={() => handleSocialAppLogin('facebook')}>
                    <img src={FacebookLogin} alt={'facebook-login'} width={'26px'}/>
                    <span>Log In with Facebook</span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center mb-xxl-5">
                  <p className="sign-up-redirect-btn">
                    Don’t have an
                    account? <span onClick={goToSignUp}>Sign Up</span>
                  </p>
                </div>
              </>
          }

          <p className="copyright text-center mt-auto">
            All rights reserved © Chooch
            Intelligence Technologies Co.
          </p>
        </div>
      </div>
      <GoogleAndFacebookLoginModal show={socialLoginUser} handleClose={() => setSocialLoginUser(false)}/>
    </div>
  )
}
