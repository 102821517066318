import React from 'react';
import CustomCopy from '../CustomCopy';

export default function MarkdownPre(props) {
  const { children } = props;
  const codeName = children.props.className ? children.props.className.replace('language-', '') : 'code';

  return (
    <div className='code-block'>
      <div className='code-header'>
        <span className='code-name'>{codeName}</span>
        <CustomCopy text={children.props.children} className={'copy-code-icon'}/>
      </div>
      {children}
    </div>
  );
}
