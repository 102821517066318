import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import ChatPage from './ChatPage';
import SelfHostedSignUp from './SelfHostedSignUp';
import DocumentationPage from './DocumentationPage';
import SettingsPage from './SettingsPage';
import ForgotPassword from './ForgotPassword';
import InferenceEngine from './InferenceEngine';
import About from './About';
import LicenseAgreement from './LicenseAgreement';
import ChatHistoryPage from './ChatHistoryPage';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';

export default function MyRoutes() {

  return (
    <Routes>
      <Route path="/register" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <ChatPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/docs"
        element={
          <PrivateRoute>
            <DocumentationPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/about"
        element={
          <PrivateRoute>
            <About />
          </PrivateRoute>
        }
      />
      <Route
        path="/license-agreement"
        element={
          <PrivateRoute>
            <LicenseAgreement />
          </PrivateRoute>
        }
      />
      <Route
        path="/inference-engine"
        element={
          <PrivateRoute>
            <InferenceEngine />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <SettingsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/chat-history"
        element={
          <PrivateRoute>
            <ChatHistoryPage />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <Navigate to="/home" replace />
        }
      />
    </Routes>
  )
}
