import React from 'react';
import { Modal } from 'react-bootstrap';
import downloadIcon from '../assets/images/download-icon-transparent.svg';
import closeIcon from '../assets/images/close-white.svg';

export default function ImagePreviewModal({ show, handleCloseModal, image, download }) {
  return (
    <>
      {
        image && (
          <Modal className="image-preview-modal" show={show} onHide={handleCloseModal} fullscreen backdrop={false}>
            <Modal.Body>
            <a href={download}>
              <div className='image-download-btn bordered-btn'>
                <img src={downloadIcon} alt="downloadIcon" />
                Download
              </div>
              </a>
              <img src={image} alt="preview" className="image-preview" />
              <img src={closeIcon} alt="closeIcon" className='close-icon' onClick={handleCloseModal} />
            </Modal.Body>
          </Modal>
        )
      }
    </>
  );
}
