import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import step1Image from '../assets/images/step1-image.png'
import step2Image from '../assets/images/step2-image.png'
import step3Image1 from '../assets/images/step3-image1.png'
import step3Image2 from '../assets/images/step3-image2.png'
import step4Image1 from '../assets/images/step4-image1.png'
import step4Image2 from '../assets/images/step4-image2.png'
import step6Image from '../assets/images/step6-image.png'
import { AXIOS_INSTANCE } from './utils/axiosInstance'

export default function FirstTimeModal(props) {
  const [currentStep, setCurrentStep] = useState(1)

  const previousStep = (i) => {
    setCurrentStep(i)
  }

  const nextStep = (i) => {
    setCurrentStep(i)
  }

  const updateOnboardingStatus = () => {
    AXIOS_INSTANCE.patch('/user-setting/',
      {
        'completed_onboarding': true
      })
      .then(res => {
        sessionStorage.setItem('isWelcomeSeen', true)
        props.closeModal();
      })
      .catch(error => {
        console.log(error)
      });
  }

  return (
    <Modal className='first-time-modal' show={props.show} centered size='lg' onHide={updateOnboardingStatus}>
      <Modal.Header closeButton>
        <Modal.Title>
          {currentStep === 1 ?
            'Text-to-Image Generation'
            :
            currentStep === 2 ?
              'Image-to-Text Generation'
              :
              currentStep === 3 ?
                'Image-to-Image Generation'
                :
                currentStep === 4 ?
                  'Portrait Styles'
                  :
                  currentStep === 5 ?
                    'AI Chat'
                    :
                    'Chat with Documents'
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === 1 ?
          <div className='examples-content step1'>
            <p className='modal-explanation'>Begin prompt with <strong>Generate</strong> or <strong>Create</strong> for more <br></br>accurate results.</p>
            <div className='prompt-message'>Prompt: <span>'Generate a winter scene with an igloo <br></br> and camels around it.'</span></div>
            <img src={step1Image} alt="step1" key="step1" />
            <div className='generated-prompt'>Generated</div>
          </div>
          :
          currentStep === 2 ?
            <div className='examples-content step2'>
              <p className='modal-explanation'>Start prompt with <strong>Describe this image</strong> to convert it to text.</p>
              <div className='prompt-message'>Prompt: <span>'Describe the image'</span></div>
              <img src={step2Image} alt="step2" key="step2" />
              <div className='chat-message'>
                The image depicts a lion wearing a purple hoodie and sunglasses, standing in front of a city skyline with lightning in the background.
              </div>
              <div className='generated-prompt'>Generated</div>
            </div>
            :
            currentStep === 3 ?
              <div className='examples-content step3'>
                <p className='modal-explanation'>Start prompt with <strong>Generate a similar image</strong> or <strong>Restore this image</strong>.</p>
                <div className='prompt-message'>Prompt: <span>'Generate a similar image'</span></div>
                <img src={step3Image1} className='left-img' alt="step3" key="step3" />
                <img src={step3Image2} className='right-img' alt="step32" key="step32" />
                <div className='generated-prompt'>Generated</div>
              </div>
              :
              currentStep === 4 ?
                <div className='examples-content step4'>
                  <p className='modal-explanation'>Upload your portrait and <strong>enter a style keyword</strong> to generate similar image styles.</p>
                  <div className='prompt-message'>Prompt: <span>'Generate a similar image in <br></br> cartoon style'</span></div>
                  <img src={step4Image1} className='left-img' alt="step4" key="step4" />
                  <img src={step4Image2} className='right-img' alt="step42" key="step42" />
                  <div className='generated-prompt'>Generated</div>
                </div>
                :
                currentStep === 5 ?
                  <div className='examples-content step5'>
                    <p className='modal-explanation'>Create the prompt with specific context, topic, tone, and instructions succinctly.</p>
                    <div className='prompt-message'>Prompt: <span>'How can I master Python quickly and <br></br>efficiently'</span></div>
                    <div className='chat-message'>
                      Here are some tips for mastering Python quickly and efficiently:
                      <br></br><br></br>
                      <strong>1. Learn the basics:</strong> Begin by learning the fundamentals of Python, such as data types, variables, loops, and conditional statements.
                      <br></br><br></br>
                      <strong>2. Practice coding:</strong> Start with simple projects and gradually move...
                    </div>
                    <div className='generated-prompt'>Generated</div>
                  </div>
                  :
                  <div className='examples-content step6'>
                    <p className='modal-explanation'>Upload a document and start with <strong>List the key points</strong> or <strong>Summarize this document</strong>.</p>
                    <div className='prompt-message'>Prompt: <span>'What is the total invoice amount'</span></div>
                    <img src={step6Image} alt="step6" key="step6" />
                    <div className='chat-message'>Based on the provided context, the <br></br>total amount is <strong>$146.30.</strong></div>
                    <div className='generated-prompt'>Generated</div>
                  </div>
        }

        <div className='w-100 d-flex gap-3 justify-content-center mt-5'>
          {currentStep === 1 ?
            <button className='cancel-tutorial-btn' onClick={() => updateOnboardingStatus()}>Cancel</button>
            :
            <button className='cancel-tutorial-btn' onClick={() => previousStep(currentStep - 1)}>Back</button>
          }
          {currentStep === 6 ?
            <button className='next-step-btn' onClick={() => updateOnboardingStatus()}>Done</button>
            :
            <button className='next-step-btn' onClick={() => nextStep(currentStep + 1)}>Next</button>
          }

        </div>

        <div className='progress-content'>
          <div className={`progress-item ${currentStep === 1 && 'active'} `}></div>
          <div className={`progress-item ${currentStep === 2 && 'active'} `}></div>
          <div className={`progress-item ${currentStep === 3 && 'active'} `}></div>
          <div className={`progress-item ${currentStep === 4 && 'active'} `}></div>
          <div className={`progress-item ${currentStep === 5 && 'active'} `}></div>
          <div className={`progress-item ${currentStep === 6 && 'active'} `}></div>
        </div>
      </Modal.Body>
    </Modal >
  )
}
