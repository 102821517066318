import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AXIOS_INSTANCE } from '../../components/utils/axiosInstance';
import moment from 'moment';

const initialState = {
  data: {},
  modal: false,
  nextPageUrl: null,
  status: 'idle'
};

export const getChatHistoryList = createAsyncThunk('chatHistoryList',
  async (page ) => {
    const response = await AXIOS_INSTANCE.get(`/chat-session/?page=${page}&page_size=25`);
    return response.data;
  }
);

export const deleteSession = createAsyncThunk('deleteSession',
  async (session) => {
    return AXIOS_INSTANCE.delete(`/chat-session/`, {
      data:{
        session_ids: session
      }
    })
      .then(() => {
        return session;
      });
  }
);

export const { actions, reducer } = createSlice({
  name: 'chatHistoryList',
  initialState,
  reducers: {
    openModal: (state) => {
      state.modal = true;
    },
    hideModal: (state) => {
      state.modal = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChatHistoryList.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getChatHistoryList.fulfilled, (state, action) => {
      const today = moment();
      const thisMonth = moment().subtract(30, 'days');
      const thisWeek = moment().subtract(7, 'days');
      const whichMonth = moment().subtract(10, 'days');

      state.status = 'success';
      state.data = action.payload;

    });
    builder.addCase(getChatHistoryList.rejected, (state) => {
      state.status = 'failed';
    });

    /*Delete Session*/
    builder.addCase(deleteSession.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteSession.fulfilled, (state, action) => {
      state.status = 'success';
      state.data.results = state.data.results.filter(session => session.id !== action.payload);
    });
    builder.addCase(deleteSession.rejected, (state) => {
      state.status = 'failed';
    });
  }
});

export const { openModal, hideModal } = actions;


