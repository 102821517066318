import React, { useEffect, useState } from 'react';
import { AXIOS_INSTANCE } from './utils/axiosInstance';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

export default function UrlSuggestions() {
  const [urlList, setUrlList] = useState([]);

  useEffect(() => {
    getUrlSuggestions()
  }, [])

  const getUrlSuggestions = () => {
    AXIOS_INSTANCE.get('/suggestion/')
      .then(res => {
        setUrlList(res.data)
      })
      .catch(error => {
        console.log(error)
      });
  }

  const goToUrl = (url) => {
    window.open(url, "_blank");
  }

  return (
    <div className={`url-suggestion-content ${urlList.length > 0 && 'visible'}`}>
      <div className="try-this-out">Explore more</div>
      <div className='w-100 d-flex align-items-center justify-content-between my-3'>
        {urlList.map((item, i) => {
          return (
            <div className='url-suggestion-item' key={i} onClick={() => goToUrl(item.url)}>
              <div className='icon-field'>
                <BoxArrowUpRight color='#8020CF' size={20} />
              </div>
              <div className='text-field'>
                <div className='url-header' title={item.name}>{item.name}</div>
                <div className='url-link'>{item.url}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="ai-category-message">Based on what's popular, it is recommended to you.</div>
    </div>
  )
}
