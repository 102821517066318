import { Modal, Button } from "react-bootstrap";
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  upgradeSystem,
  hideModal
} from '../state/slices/settingSlice';
import React from 'react';
import { useToast } from './utils/ToastContext';

export default function SystemUpgradeModal() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const { updateMessage } = useToast();

  const closeModal = () => {
    dispatch(hideModal());
  }

  const handleSystemUpgrade = async () => {
    await dispatch(upgradeSystem()).then((response) => {
      window.location.reload();
    }).catch((error) => {
      closeModal();
      updateMessage('Something went wrong. Please try again later.', 'error');
    });
  }

  /*useEffect(() => {
    if(settings.status === 'success') {
      closeModal();
    }

    if(settings.status === 'failed') {
      closeModal();
      updateMessage('Something went wrong. Please try again later.', 'error');
    }


  }, [settings.status]);*/

  return (
    <Modal className='system-upgrade-modal p-5' show={settings?.modal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          When the system update starts, your app will be offline for a short time.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className='transparent-btn' onClick={closeModal}>Cancel</Button>
        <Button className="blue-btn" onClick={handleSystemUpgrade}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );

}
