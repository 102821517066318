import { useEffect } from "react"
import { useNavigate } from "react-router-dom";

export default function OneStepLeft() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 5000)
  }, [])

  return (
    <div className='h-100 d-flex align-items-center justify-content-center'>
      <div className='one-step-left'>
        <p className='one-step-left-title mb-3 fw-bold'>
          One step left!
        </p>
        <p className='verfication-email-text fw-bold mb-4'>
          Please go to your email and verify your email for Chooch
        </p>
      </div>
    </div>
  )
}
