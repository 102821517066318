import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss';
import { BrowserRouter } from "react-router-dom";
import MyRoutes from './components/MyRoutes';
import { AuthProvider } from './components/utils/AuthContext';
import { BurgerProvider } from './components/utils/BurgerContext';
import {
  ToastProvider
} from './components/utils/ToastContext';
import ToastMessage
  from './components/ToastMessage';
import { Provider } from 'react-redux';
import store from './state/store';
import RestartLoader from './components/RestartLoader';
import { useEffect } from 'react';
import { ThemeProvider } from "./components/utils/ThemeContext";
import { HelmetProvider } from 'react-helmet-async';

function App() {

  return (
    <HelmetProvider>
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider>
          <BurgerProvider>
            <ToastProvider>
              <BrowserRouter>
                <RestartLoader />
                <MyRoutes />
                <ToastMessage />
              </BrowserRouter>
            </ToastProvider>
          </BurgerProvider>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
    </HelmetProvider>
  );
}

export default App;
