import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { getFileTypeLogoBig } from './utils/generic';

export default function Carousel(props) {

  var settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
  };

  return (
    props.categories[props.selectedPrompt - 1].type === 'text' || props.categories[props.selectedPrompt - 1].type === 'textai' ?
      <div className='prompt-text-examples'>
        <Slider {...settings}>
          {props.categories[props.selectedPrompt - 1].examples.map((example, index) => {
            return (
              <div key={index} className={`${example.exampleID === props.selectedPromptExample?.exampleID ? 'prompt-text-item selected' : 'prompt-text-item'}`} onClick={() => props.handleSelectedPrompt(example)}>
                <p className={props.categories[props.selectedPrompt - 1].type === 'textai' ? 'ai-text' : 'prompt-text'}>{example.examplePrompt}</p>
              </div>
            )
          })}
        </Slider>
      </div>
      :
      props.categories[props.selectedPrompt - 1].type === 'image' ?
        <div className='portrait-image-examples'>
          <Slider {...settings}>
            {props.categories[props.selectedPrompt - 1].examples.map((example, index) => {
              return (
                <div key={index} className={`${example.exampleID === props.selectedPromptExample?.exampleID ? 'prompt-image-item selected' : 'prompt-image-item'}`} onClick={() => props.addSingleFilePortrait(example.examplePrompt)}>
                  <img src={example.thumbnail} alt="src" className="img-file preview" />
                  <div className="example-image-name">{example.name}</div>
                </div>
              )
            })}
            <div className='prompt-image-item' onClick={() => props.addSingleFilePortrait('')}>
              <div className='custom-image-box'>
                Unleash your creativity and type out your unique artistic portrait styles.
              </div>
            </div>
          </Slider>
        </div>
        :
        props.categories[props.selectedPrompt - 1].type === 'imageToImage' || props.categories[props.selectedPrompt - 1].type === 'imageToText' ?
          <div className='prompt-image-examples'>
            <Slider {...settings}>
              {props.categories[props.selectedPrompt - 1].examples.map((example, index) => {
                return (
                  <div key={index} className={`${example.exampleID === props.selectedPromptExample?.exampleID ? 'prompt-image-to-image-item selected' : 'prompt-image-to-image-item'}`} onClick={() => props.handleSelectedPrompt(example)}>
                    <img src={example.thumbnail} alt="src" className="img-file preview" />
                    <div className="example-image-name">{example.examplePrompt}</div>
                  </div>
                )
              })}
            </Slider>
          </div>
          :
          <div className='prompt-text-examples'>
            <Slider {...settings}>
              {props.categories[props.selectedPrompt - 1].examples.map((example, index) => {
                return (
                  <div key={index} className={`${example.exampleID === props.selectedPromptExample?.exampleID ? 'prompt-doc-item selected' : 'prompt-doc-item'}`} onClick={() => props.handleSelectedPrompt(example)}>
                    <img src={getFileTypeLogoBig(example.fileType)} alt="pic-logo" />
                    <div className='d-flex flex-column'>
                      <div className='prompt-text'>{example.examplePrompt}</div>
                      <div className='prompt-file'><span>File: </span>{example.examplePromptTitle}</div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
  )
}
