import React, {
  useEffect,
  useState
} from 'react';
import {
  InputGroup,
  FormControl,
  FormLabel
} from 'react-bootstrap';
import DOMPurify from 'dompurify';

export default function ResetCode({ length = 6 , setResetCode, resetCount}) {
  const [code, setCode] = useState(Array(length).fill(''));

  useEffect(() => {
    setCode(Array(length).fill(''));
    setResetCode('');
  }, [resetCount]);


  const handleChange = (value, index) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    const cleanResetCode = DOMPurify.sanitize(newCode.join(''));
    setResetCode(cleanResetCode);
    if (value.length === 1 && index < length - 1) {
      const nextSibling = document.querySelector(
        `input[name="code-${index + 1}"]`
      );
      if (nextSibling) {
        nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !code[index]) {
      const prevSibling = document.querySelector(`input[name="code-${index - 1}"]`);
      if (prevSibling) {
        prevSibling.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    if (pastedData && pastedData.length === length) {
      const newCode = pastedData.split('');
      setCode(newCode);
      const cleanResetCode = DOMPurify.sanitize(newCode.join(''));
      setResetCode(cleanResetCode);
    }
  };

  return (
    <InputGroup className=" reset-code-input-group" autoComplete={'off'}>
      <FormLabel className="reset-label fw-bold  d-block w-100">Reset Code</FormLabel>
      {code.map((_, index) => (
        <FormControl
          key={index}
          name={`code-${index}`}
          value={code[index]}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          maxLength="1"
          className="reset-code-input"
          style={{ maxWidth: '40px', height: '40px', textAlign: 'center' }}
          pattern={'[0-9]*'}
        />
      ))}
    </InputGroup>
  );
};
