import React, { useContext, useState } from 'react'

const ToastContext = React.createContext()

export function useToast() {
  return useContext(ToastContext)
}

export function ToastProvider({ children }) {
  const [type, setType] = useState('success');
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('Something went wrong!');

  const close = () => {
    setShow(false);
  }

  const updateMessage = (title, type, msg) => {
    setMessage(msg);
    setType(type)
    setTitle(title)
    setShow(true);
  }

  const value = {
    type,
    show,
    message,
    title,
    updateMessage,
    close
  }

  return (
    <ToastContext.Provider value={value}>
      {children}
    </ToastContext.Provider>
  )
}
