import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useToast } from './utils/ToastContext';
function useCountdown(minutes = 10) {
  const storedTargetDate = localStorage.getItem('resetCodeTargetDate');
  const initialTargetDate = storedTargetDate ? new Date(storedTargetDate) : new Date(Date.now() + minutes * 60000);
  const [targetDate, setTargetDate] = useState(initialTargetDate);
  const location = useLocation();
  const { updateMessage } = useToast();

  const calculateTimeLeft = () => {
    let timeLeft = {};
    const difference = +targetDate - +new Date();

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 1000 / 60)),
        seconds: Math.floor((difference / 1000)),
      };
    }

    if (difference <= 0) {
      localStorage.removeItem('resetCodeTargetDate');
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (timeLeft.minutes !== undefined && location.state && location.state.email) {
      localStorage.setItem('resetCodeTargetDate', targetDate.toString());
    }
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, timeLeft]);

  return timeLeft;
}

export default function CountdownComponent() {
  const timeLeft = useCountdown();
  const location = useLocation();
  const [isResendCode, setIsResendCode] = useState(false);


  const handleResendCode = () => {
    return fetch(process.env.REACT_APP_BASE_URL + '/authentication/reset_password/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: location.state.email,
        send_email: true,
      })
    }).then(response => {
      console.log(response)
      if (response.status === 200) {
        localStorage.setItem('resetCodeTargetDate', new Date(Date.now() + 10 * 60000).toString());
        setIsResendCode(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);

      } else {
        console.log('Error')
      }
    });
  }

  return (
    <div className='reset-code-countdown'>
      { timeLeft.seconds === undefined || timeLeft.seconds <= 0 ?
        <span>Your password reset request has expired</span>
        :
        <span>Your password reset request will expire in <span className={'text-danger fw-bolder'}>
          <span className='fs-4'>{timeLeft.minutes === 10 ? '10' : timeLeft.minutes + 1}</span> minutes</span>
        </span>
      }
      <span
        className={timeLeft.seconds <= 0 ||  timeLeft.seconds === undefined ? 'resend-code-btn fw-bolder' : 'resend-code-btn fw-bolder disabled'}
        onClick={timeLeft.seconds <= 0 || timeLeft.seconds === undefined ? handleResendCode : null}
      >
        {isResendCode ? 'Sent!' : 'Resend'}
      </span>
    </div>
  );
}
