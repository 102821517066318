import { combineReducers} from "@reduxjs/toolkit";

import { reducer as chatReducer } from './slices/chatSlice';
import { reducer as chatHistoryListReducer } from './slices/chatHistoryListSlice';
import { reducer as sessionContent } from './slices/chatSlice';
import { reducer as settingReducer } from './slices/settingSlice';
import { reducer as restartReducer } from './slices/restartSlice';
import { reducer as resettableReducer } from './slices/resettableSlice';

const reducers = {
    chat: chatReducer,
    chatHistoryList: chatHistoryListReducer,
    sessionContent: sessionContent,
    settings: settingReducer,
    restart: restartReducer,
    resettable: resettableReducer,
};

const rootReducer = combineReducers(reducers);

const resettableRootReducer = (state, action) => {
    if (action.type === 'resettable/resetApp') {
        state = undefined;
    }
    return rootReducer(state, action);
}

export default resettableRootReducer;
