import { configureStore} from '@reduxjs/toolkit';
import resettableRootReducer from "./rootReducer";

const store = configureStore({
    reducer: resettableRootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;
