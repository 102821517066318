import React from 'react';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import MarkdownCodeBlock from './markdown-components/MarkdownCodeBlock';
import MarkdownPre from './markdown-components/MarkdownPre';
import ExternalLink
  from './markdown-components/ExternalLink';

export default function CustomMarkdown({ markdownText }) {
  const markdownCorrectness = (text) => {
    return text
      .replace(/\*\*\s*(\S.*?)\s*\*\*/g, '**$1**')
      .replace(/(\d+)-(\S*?)/g, '$1.$2');
  };
  return (
    <Markdown
      className={'markdown'}
      remarkPlugins={[remarkGfm]}
      children={markdownCorrectness(markdownText)}
      components={{
        code: MarkdownCodeBlock,
        pre: MarkdownPre,
        a: ExternalLink
      }}
    />
  );
};

