import SubHeader from '../assets/images/sub-header-bg.jpeg';
import { Col, Container, ListGroup, Row, Tab, Tabs } from 'react-bootstrap';
import { CheckCircleFill, CodeSlash } from 'react-bootstrap-icons';
import placeholderLight from '../assets/images/light-api.jpg';
import placeholderDark from '../assets/images/dark-api.jpg';
import DotMore from '../assets/images/3-dot-more.svg';
import { apiDocs } from './utils/apiDocumentation';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'react-bootstrap-icons'
import { useTheme } from './utils/ThemeContext';

export default function DocumentationPage() {
  const navigate = useNavigate();
  const { darkTheme } = useTheme();

  const navigateToHomepage = () => {
    navigate("/home");
  }
  return (
    <div className='documentation-page'>
      <div className={'sub-header-wrapper'}>
        <img src={SubHeader} alt="" className={'subheader-bg'} />
        <div className='subheader-breadcrumb'>
          <div className='main-breadcrumb' onClick={navigateToHomepage}>
            Homepage
          </div>
          <ChevronRight size={14} color='#FFFFFF' />
          <div className='sub-breadcrumb'>
            Documentation
          </div>
        </div>

        <div className={'sub-header-title'}>
          <h4>Documentation</h4>
          <h1>ImageChat-3 API Guide</h1>
        </div>
      </div>
      <Container fluid className='d-flex flex-column pb-5'>
        <Row className='mx-0'>
          <Col sm={3} className='api-navigation pe-5'>
            <div className='sidebar'>
              <p>ImageChat-3 API</p>
              <ListGroup defaultActiveKey="#link1">
                <ListGroup.Item action href="#image-prediction">
                  Image Prediction (Prompting)
                </ListGroup.Item>
                <ListGroup.Item action href="#workflow">
                  Workflow Prediction
                </ListGroup.Item>
                <ListGroup.Item action href="#vision">
                  Vision Prediction
                </ListGroup.Item>
                <ListGroup.Item action href="#text-prediction">
                  Text Prediction (Prompting)
                </ListGroup.Item>
                <ListGroup.Item action href="#document-prediction">
                  Document Prediction (Prompting) - Beta
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
          <Col sm={9} className='px-1 pe-5'>
            <p className='section-title'>ImageChat overview</p>
            <p className={'documentation-page-text'}>
              ImageChat-3 is Chooch’s latest cutting-edge model that provides more detailed insights into visual images with staggering accuracy. Our new ensemble model combines computer vision and large language models to provide you with the best possible results. ImageChat-3 has over 11 billion parameters and was trained on 400 million images. For more information on the release and an example use case, read our blog.
            </p>
            <p className={'documentation-page-text'}><strong>ImageChat-3 includes functionality such as:</strong></p>
            <div>
              <div className={'listing-item'}>
                <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                <span className={'documentation-page-text'}>Easy to use image analysis and descriptive chat</span>
              </div>
              <div className={'listing-item'}>
                <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                <span className={'documentation-page-text'}>Customized model creation and prompting</span>
              </div>
              <div className={'listing-item'}>
                <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                <span className={'documentation-page-text'}>Model integration with ReadyNow models for additional accuracy and localization</span>
              </div>
              <div className={'listing-item'}>
                <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                <span className={'documentation-page-text'}>Model integration with custom models</span>
              </div>
              <div className={'listing-item'}>
                <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                <span className={'documentation-page-text'}>Hosted API usage and automated image analysis</span>
              </div>
            </div>
            <div>
              <p className={'section-sub-title-blue'}>ImageChat-3 Examples</p>
              <p className={'documentation-page-text'}>
                With ImageChat you can upload an image and have a continuous discussion, allowing you to provide multiple prompts for further analysis. The example conversations below showcase expected usage of ImageChat.
              </p>
              <img className='mb-5 w-100' src={darkTheme ? placeholderDark : placeholderLight} alt="plchl" />
            </div>
            <div>
              <p className={'section-title'}>ImageChat-3 API</p>
              <p className={'documentation-page-text'}>
                Our cutting-edge ensemble model combines computer vision and large language models to provide you with the best possible results. Both ImageChat and Custom ImageChat models are available via API access for automating prompting and analysis in your pipeline.
              </p>
              <p className={'section-sub-title-blue'}>ImageChat-3 API Examples</p>
              <p className={'documentation-page-text'}>
                Please see the Jupyter Notebooks Examples in api_examples/ for information on how to use the ImageChat-3 API. Below we will describe the functionality and expected results.
              </p>
              <p className={'section-sub-title-blue'}>Functionality</p>
              <p className={'documentation-page-text'}>
                There are 5 core component features of ImageChat-3 API that enable everything from Image analysis to multi-language conversation. See below:
              </p>
              <div>
                <div className={'listing-item'}>
                  <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                  <a href='#image-prediction' className={'documentation-page-text-blue'}>Image Prediction (Prompting)</a>
                </div>
                <div className={'listing-item'}>
                  <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                  <a href='#workflow' className={'documentation-page-text-blue'}>Workflow Prediction</a>
                </div>
                <div className={'listing-item'}>
                  <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                  <a href='#vision' className={'documentation-page-text-blue'}>Vision Prediction</a>
                </div>
                <div className={'listing-item'}>
                  <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                  <a href='#text-prediction' className={'documentation-page-text-blue'}>Text Prediction (Prompting)</a>
                </div>
                <div className={'listing-item'}>
                  <CheckCircleFill color={'#303030'} size={18} style={{ marginRight: '15px' }} />
                  <a href='#document-prediction' className={'documentation-page-text-blue'}>Document Prediction (Prompting) - Beta</a>
                </div>
              </div>
            </div>
            {/* Prediction Image */}
            <p id='image-prediction' className={'section-sub-title mt-5 mb-1'}>Image Prediction (Prompting):</p>
            <p className={'documentation-page-text'}>Image Prompting allows users to upload an image and receive an AI-generated prediction regarding its content. Users can engage in a conversation with the AI about the image’s content, making it an interactive experience. Supported image formats for this functionality are: .jpg, .jpeg, and .png.</p>
            <div className={'w-100'} >
              <Tabs
                defaultActiveKey="python"
                transition={false}
                id="noanim-tab-example"
                className="justify-content-end code-tab"
              >
                <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                <Tab eventKey="python" title="Python" className='code-tab-item'>
                  <code className='code-example' style={{ lineHeight: '18px' }}>

                    <pre>
                      {apiDocs.predictionImagePython}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="javascript" title="JavaScript" >
                  <code className='code-example'>
                    <pre>
                      {apiDocs.predictionImageJS}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="curl" title="cURL">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.predictionImageCurl}
                    </pre>
                  </code>
                </Tab>
                <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
              </Tabs>
              <div>
                <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                <code className='code-example '>
                  <pre className='code-response'>
                    {apiDocs.predictionImageResponse}
                  </pre>
                </code>
              </div>
              <h4 className={'blue-title'}><strong>Predict with source_id, continue chat/conversation on source:</strong></h4>
              <div className={'w-100'} >
                <Tabs
                  defaultActiveKey="python"
                  transition={false}
                  id="noanim-tab-example"
                  className="justify-content-end code-tab"
                >
                  <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                  <Tab eventKey="python" title="Python" className='code-tab-item'>
                    <code className='code-example' style={{ lineHeight: '18px' }}>
                      <pre>
                        {apiDocs.predictionImageWithSourceIdAndContinuePython}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="javascript" title="JavaScript">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictionImageWithSourceIdAndContinueJS}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictionImageWithSourceIdAndContinueCurl}
                      </pre>
                    </code>
                  </Tab>
                  <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
                </Tabs>
                <div>
                  <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                  <code className='code-example '>
                    <pre className='code-response'>
                      {apiDocs.predictionImageWithSourceIdAndContinueResponse}
                    </pre>
                  </code>
                </div>
              </div>
              <h4 className={'blue-title'}><strong>Predict with source_id: </strong></h4>
              <div className={'w-100'} >
                <Tabs
                  defaultActiveKey="python"
                  transition={false}
                  id="noanim-tab-example"
                  className="justify-content-end code-tab"
                >
                  <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                  <Tab eventKey="python" title="Python" className='code-tab-item'>
                    <code className='code-example' style={{ lineHeight: '18px' }}>
                      <pre>
                        {apiDocs.predictionImageWithSourceIdPython}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="javascript" title="JavaScript">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictionImageWithSourceIdJS}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictionImageWithSourceIdCurl}
                      </pre>
                    </code>
                  </Tab>
                  <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
                </Tabs>
                <div>
                  <h4 className={'blue-title'}><strong>Sample Response</strong></h4>
                  <code className='code-example '>
                    <pre className='code-response'>
                      {apiDocs.predictionImageWithSourceIdResponse}
                    </pre>
                  </code>
                </div>
              </div>
            </div>

            {/* Prediction WorkFlow */}
            <p id='workflow' className={'section-sub-title mt-5 mb-1'}>Workflow Prediction</p>
            <p className={'documentation-page-text'}>The Workflow Prediction offers an advanced fusion of object detection models with ImageChat capabilities. Users have the flexibility to combine the ImageChat LLM Vision Model with Chooch’s ReadyNow Models or even integrate their own Custom Object Detection models. This ensures detailed and specific results. For instance, you can create a Workflow by writing the prompt “is there any person with a backpack?”, followed by integrating Chooch’s General Object Detection 4.0 (80) model with the “person” class. The AI will first identify the “person” and then direct the prompt question exclusively to that identified person cut out.</p>
            <div className={'w-100'} >
              <Tabs
                defaultActiveKey="python"
                transition={false}
                id="noanim-tab-example"
                className="justify-content-end code-tab"
              >
                <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                <Tab eventKey="python" title="Python" className='code-tab-item'>
                  <code className='code-example' style={{ lineHeight: '18px' }}>
                    <pre>
                      {apiDocs.imageWorkFlowPython}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="javascript" title="JavaScript">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.imageWorkFlowJS}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="curl" title="cURL">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.imageWorkFlowCurl}
                    </pre>
                  </code>
                </Tab>
                <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
              </Tabs>
              <div>
                <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                <code className='code-example '>
                  <pre className='code-response'>
                    {apiDocs.imageWorkFlowResponse}
                  </pre>
                </code>
              </div>
              <h4 className={'blue-title'}><strong>Predict with source_id, continue chat/conversation on source:</strong></h4>
              <div className={'w-100'} >
                <Tabs
                  defaultActiveKey="python"
                  transition={false}
                  id="noanim-tab-example"
                  className="justify-content-end code-tab"
                >
                  <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                  <Tab eventKey="python" title="Python" className='code-tab-item'>
                    <code className='code-example' style={{ lineHeight: '18px' }}>
                      <pre>
                        {apiDocs.imageWorkFlowWithSourceIdAndContinuePython}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="javascript" title="JavaScript">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.imageWorkFlowWithSourceIdAndContinueJS}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.imageWorkFlowWithSourceIdAndContinueCurl}
                      </pre>
                    </code>
                  </Tab>
                  <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
                </Tabs>
                <div>
                  <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                  <code className='code-example '>
                    <pre className='code-response'>
                      {apiDocs.imageWorkFlowWithSourceIdAndContinueResponse}
                    </pre>
                  </code>
                </div>
              </div>
              <h4 className={'blue-title'}><strong>Prediction with file url</strong></h4>
              <div className={'w-100'} >
                <Tabs
                  defaultActiveKey="python"
                  transition={false}
                  id="noanim-tab-example"
                  className="justify-content-end code-tab"
                >
                  <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                  <Tab eventKey="python" title="Python" className='code-tab-item'>
                    <code className='code-example' style={{ lineHeight: '18px' }}>
                      <pre>
                        {apiDocs.withFileUrlPython}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="javascript" title="JavaScript">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.withFileUrlJS}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.withFileUrlCurl}
                      </pre>
                    </code>
                  </Tab>
                  <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
                </Tabs>
                <div>
                  <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                  <code className='code-example '>
                    <pre className='code-response'>
                      {apiDocs.withFileUrlResponse}
                    </pre>
                  </code>
                </div>
              </div>
            </div>

            {/* Predict Image Chooch Vision */}
            <p id='vision' className={'section-sub-title mt-5 mb-1'}>Vision Prediction</p>
            <p className={'documentation-page-text'}>This section provides a standalone API designed for those who have existing custom object detection models or are utilizing Chooch’s ReadyNow models. It serves as a dedicated platform to execute and analyze predictions derived from these models.</p>
            <div className={'w-100'} >
              <Tabs
                defaultActiveKey="python"
                transition={false}
                id="noanim-tab-example"
                className="justify-content-end code-tab"
              >
                <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                <Tab eventKey="python" title="Python" className='code-tab-item'>
                  <code className='code-example' style={{ lineHeight: '18px' }}>
                    <pre>
                      {apiDocs.choochVisionPython}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="javascript" title="JavaScript">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.choochVisionJS}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="curl" title="cURL">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.choochVisionCurl}
                    </pre>
                  </code>
                </Tab>
                <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
              </Tabs>
              <div>
                <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                <code className='code-example '>
                  <pre className='code-response'>
                    {apiDocs.choochVisionResponse}
                  </pre>
                </code>
              </div>
              <h4 className={'blue-title'}><strong>Predict with file URL:</strong></h4>
              <div className={'w-100'} >
                <Tabs
                  defaultActiveKey="python"
                  transition={false}
                  id="noanim-tab-example"
                  className="justify-content-end code-tab"
                >
                  <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                  <Tab eventKey="python" title="Python" className='code-tab-item'>
                    <code className='code-example' style={{ lineHeight: '18px' }}>
                      <pre>
                        {apiDocs.choochVisionWithFileUrlPython}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="javascript" title="JavaScript">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.choochVisionWithFileUrlJS}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.choochVisionWithFileUrlCurl}
                      </pre>
                    </code>
                  </Tab>
                  <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
                </Tabs>
                <div>
                  <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                  <code className='code-example '>
                    <pre className='code-response'>
                      {apiDocs.choochVisionWithFileUrlResponse}
                    </pre>
                  </code>
                </div>
              </div>
            </div>

            {/* ImageChat-3 PredictText */}
            <p id='text-prediction' className={'section-sub-title mt-5 mb-1'}>Text Prediction (Prompting)</p>
            <p className={'documentation-page-text'}>Text Prediction is designed for those who wish to initiate a dialogue directly with the AI without the need for an image. Simply input your text, and the AI will generate a response, facilitating a seamless conversational experience.</p>
            <div className={'w-100'} >
              <Tabs
                defaultActiveKey="python"
                transition={false}
                id="noanim-tab-example"
                className="justify-content-end code-tab"
              >
                <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                <Tab eventKey="python" title="Python" className='code-tab-item'>
                  <code className='code-example' style={{ lineHeight: '18px' }}>
                    <pre>
                      {apiDocs.predictTextPython}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="javascript" title="JavaScript">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.predictTextJS}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="curl" title="cURL">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.predictTextCurl}
                    </pre>
                  </code>
                </Tab>
                <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
              </Tabs>
              <div>
                <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                <code className='code-example '>
                  <pre className='code-response'>
                    {apiDocs.predictTextResponse}
                  </pre>
                </code>
              </div>
              <h4 className={'blue-title'}><strong>Predict with source_id, continue chat/conversation on source:</strong></h4>
              <div className={'w-100'} >
                <Tabs
                  defaultActiveKey="python"
                  transition={false}
                  id="noanim-tab-example"
                  className="justify-content-end code-tab"
                >
                  <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                  <Tab eventKey="python" title="Python" className='code-tab-item'>
                    <code className='code-example' style={{ lineHeight: '18px' }}>
                      <pre>
                        {apiDocs.predictTextWithSourceIdAndContinuePython}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="javascript" title="JavaScript">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictTextWithSourceIdAndContinueJS}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictTextWithSourceIdAndContinueCurl}
                      </pre>
                    </code>
                  </Tab>
                  <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
                </Tabs>
                <div>
                  <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                  <code className='code-example '>
                    <pre className='code-response'>
                      {apiDocs.predictTextWithSourceIdAndContinueResponse}
                    </pre>
                  </code>
                </div>
              </div>
            </div>

            {/* Prediction Document */}
            <p id='document-prediction' className={'section-sub-title mt-5 mb-1'}>Document Prediction (Prompting) – Beta:</p>
            <p className={'documentation-page-text'}>Document Prediction enables users to upload text-based files, which the AI will generate a prediction about the document’s content. Not only does this offer insights into the document, but users can also chat with the AI about the content, enhancing understanding and clarity. The supported formats for this feature are .txt, .pdf, .doc, .docx, .ppt, .pptx, .csv, .xls, and .xlsx.</p>
            <div className={'w-100'} >
              <Tabs
                defaultActiveKey="python"
                transition={false}
                id="noanim-tab-example"
                className="justify-content-end code-tab"
              >
                <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                <Tab eventKey="python" title="Python" className='code-tab-item'>
                  <code className='code-example' style={{ lineHeight: '18px' }}>
                    <pre>
                      {apiDocs.predictDocumentPython}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="javascript" title="JavaScript">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.predictDocumentJS}
                    </pre>
                  </code>
                </Tab>
                <Tab eventKey="curl" title="cURL">
                  <code className='code-example'>
                    <pre>
                      {apiDocs.predictDocumentCurl}
                    </pre>
                  </code>
                </Tab>
                <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
              </Tabs>
              <div>
                <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                <code className='code-example '>
                  <pre className='code-response'>
                    {apiDocs.predictDocumentResponse}
                  </pre>
                </code>
              </div>
              <h4 className={'blue-title'}><strong>Predict with source_id, continue chat/conversation on source:</strong></h4>
              <div className={'w-100'} >
                <Tabs
                  defaultActiveKey="python"
                  transition={false}
                  id="noanim-tab-example"
                  className="justify-content-end code-tab"
                >
                  <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                  <Tab eventKey="python" title="Python" className='code-tab-item'>
                    <code className='code-example' style={{ lineHeight: '18px' }}>
                      <pre>
                        {apiDocs.predictDocumentWithSourceIdAndContinuePython}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="javascript" title="JavaScript">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictDocumentWithSourceIdAndContinueJS}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictDocumentWithSourceIdAndContinueCurl}
                      </pre>
                    </code>
                  </Tab>
                  <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
                </Tabs>
                <div>
                  <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                  <code className='code-example '>
                    <pre className='code-response'>
                      {apiDocs.predictDocumentWithSourceIdAndContinueResponse}
                    </pre>
                  </code>
                </div>
              </div>
              <h4 className={'blue-title'}><strong>Predict with source_id: </strong></h4>
              <div className={'w-100'} >
                <Tabs
                  defaultActiveKey="python"
                  transition={false}
                  id="noanim-tab-example"
                  className="justify-content-end code-tab"
                >
                  <Tab className='code-tab-item' title={<span>&nbsp;<img src={DotMore} alt='copy-code' /> </span>}></Tab>
                  <Tab eventKey="python" title="Python" className='code-tab-item'>
                    <code className='code-example' style={{ lineHeight: '18px' }}>
                      <pre>
                        {apiDocs.predictDocumentWithSourceIdPython}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="javascript" title="JavaScript">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictDocumentWithSourceIdJS}
                      </pre>
                    </code>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <code className='code-example'>
                      <pre>
                        {apiDocs.predictDocumentWithSourceIdCurl}
                      </pre>
                    </code>
                  </Tab>
                  <Tab className='code-tab-item' title={<CodeSlash>&nbsp;<img src={DotMore} alt='copy-code' /> </CodeSlash>}></Tab>
                </Tabs>
                <div>
                  <h4 className={'blue-title'}><strong>Example response:</strong></h4>
                  <code className='code-example '>
                    <pre className='code-response'>
                      {apiDocs.predictDocumentWithSourceIdResponse}
                    </pre>
                  </code>
                </div>
                <div>
                  <h4 className={'blue-title'}><strong>Languages:</strong></h4>
                  <div>
                    <code className='code-example'>
                      <pre className='code-response lang-text'>
                        {apiDocs.langText}
                      </pre>
                    </code>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
