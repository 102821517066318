import React from 'react'
import { Toast } from 'react-bootstrap'
import { useToast } from './utils/ToastContext';
import success from "../assets/images/success.svg";
import error from "../assets/images/error-warning.svg";
import closeicon from "../assets/images/close.svg";
import redClose from "../assets/images/close-red.svg";


export default function ToastMessage() {
  const { type, message, show, close, title } = useToast()

  return (
    <Toast className={type === 'success' ? "toast-message-success" : "toast-message-error"} onClose={() => close()} show={show} delay={5000} autohide >
      <Toast.Header className={type === 'success' ? "toast-header-success" : "toast-header-error"}>
        <div className='me-3'>
          {
            type === 'success' ?
              <img className="success" src={success} alt="success" />
              :
              <img className="error" src={error} alt="error" />
          }
          {title}
        </div>
        {
          type === 'success' ?
            <img onClick={close} className="closeicon" src={closeicon} alt="close" />
            :
            <img onClick={close} className="closeicon" src={redClose} alt="close" />
        }
      </Toast.Header>
      {
        message ?
          <Toast.Body className='d-flex justify-content-between'>
            <div className='me-3 message-body'>
              {message}
            </div>
          </Toast.Body>
          :
          null
      }
    </Toast>
  )
}
