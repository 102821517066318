import React from 'react'

export default function Loader() {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
    <div className="lds-spinner w-100 d-flex justify-content-center my-5">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p className='loading-text'>Loading</p>
    </div>
  )
}
