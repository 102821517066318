import {
  Button,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown, Offcanvas, Stack
} from 'react-bootstrap';
import newChatLogo from '../assets/images/new-chat-logo.svg';
import choochLogoSmall from '../assets/images/choochLogoSmall.svg';
import React, { useEffect, useRef } from 'react';
import { createNewChat } from '../state/slices/chatSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'antd';
import Error from '../assets/images/warning-red-circle.svg';
import { useAuth } from './utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import { startRestart } from '../state/slices/restartSlice';
import SelectLanguage from './SelectLanguage';
import ChatHistoryList from './ChatHistoryList';
import choochLogo from '../assets/images/choochLogo.png';
import choochLogoWhite from '../assets/images/choochLogoWhite.png';
import Base64ImageConverter from './utils/Base64ImageConverter';
import { useTour } from '@reactour/tour';
import { useTheme } from "./utils/ThemeContext";

export default function ResponsiveNavbar() {
  const { darkTheme } = useTheme();
  const { setIsOpen, currentStep } = useTour()
  const charListRef = useRef(null)
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const navigateToHome = () => {
    sessionStorage.removeItem('session_ID');
    navigate("/home");
  }
  const navigateToAbout = () => {
    navigate("/about");
  }

  const navigateToSettings = () => {
    navigate("/settings");
  }
  const navigateToAPIDocumentation = () => {
    navigate('/docs');
  }

  const navigateToInferenceEngine = () => {
    navigate("/inference-engine");
  }

  const restartSystem = () => {
    dispatch(startRestart());
  }

  const navigateToChatHistory = () => {
    navigate("/chat-history");
  }

  const handleNewChat = () => {
    dispatch(createNewChat());
    sessionStorage.removeItem('session_ID');
    navigate("/home")
  }

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('theme', darkTheme ? 'dark' : 'light');
    logout();
    navigate("/login");
  };

  const handleOpenChoochPlatformAccount = () => {
    window.open("https://app.chooch.ai/feed/user-settings", "_blank");
  }

  // handle navbar collapse on mobile for tour
  useEffect(() => {
    const toggler = document.querySelector('.navbar-toggler');
    const collapseElement = document.querySelector('.navbar-toggler.collapsed');
    const closeElement = document.querySelector('.offcanvas-header .btn-close');

    if (currentStep === 3) {
      if (toggler && collapseElement) {
        toggler.click()
      }
    }
    if (currentStep === 2) {
      if (!collapseElement) {
        closeElement.click()
      }
    }
  }, [currentStep]);

  useEffect(() => {
    const dropdownMenu = document.querySelector('.border-bottom.nav-item.dropdown');
    const dropdownToggle = document.querySelector('.dropdown-toggle.nav-link');

    if (currentStep === 4) {
      if (dropdownToggle && dropdownMenu) {
        dropdownToggle.click()
      }
    }

  }, [currentStep]);



  return (
    <Navbar expand="md" className='navbar-wrapper responsive-navbar'>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Brand>
        <Nav.Link href="#" onClick={navigateToHome}>
          <img
            src={choochLogoSmall}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Chooch Logo"
          />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Brand>
        <Nav.Link href='#' onClick={handleNewChat}>
          <img
            src={newChatLogo}
            width="30"
            height="30"
            className="d-inline-block align-top third-step"
            alt="Chooch Logo"
          />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Offcanvas
        placement={'start'}
        className='offcanvas-navbar'
        aria-labelledby="responsive-navbar-nav"
      >
        <Offcanvas.Header closeButton>
          <div className='app-logo-box'>
            {
              settings?.data?.company_logo === null || settings?.data?.company_logo === 'null' || settings?.data?.company_logo === 'undefined' || settings?.data?.company_logo === '' ?
                <img
                  className="app-logo"
                  src={darkTheme ? choochLogoWhite : choochLogo}
                  alt="app-logo"
                />
                :
                <Base64ImageConverter base64Image={settings?.data?.company_logo} fileType={'png'} clsName={'app-logo'} />
            }
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav>
            <Nav.Link href="#">
              <span className='api-doc-btn' onClick={navigateToAPIDocumentation}>API Documentation</span>
            </Nav.Link>
            {
              process.env.REACT_APP_ON_PREM !== 'false' &&
              <Nav.Link href="#" className='inference-btn-wrap'>
                <span className='enhance-exp-btn inference-btn' onClick={navigateToInferenceEngine}>Enhance Your Experience</span>
              </Nav.Link>
            }
            {
              process.env.REACT_APP_ON_PREM !== 'false' ?
                <NavDropdown
                  className='border-bottom'
                  title={
                    <span className='d-inline-flex gap-1 align-items-center'>
                      <div className='user-circle'> {currentUser.email.slice(0, 2)}</div>
                      <span className=''>{currentUser.email}</span>
                      {
                        settings.data.has_new_version &&
                        <Popover content={'New version available'} placement='bottom' trigger="hover">
                          <img src={Error} alt="info" style={{ marginLeft: '5px', width: '18px', verticalAlign: 'bottom' }} />
                        </Popover>
                      }
                    </span>
                  }
                  id="collapsible-nav-dropdown" >
                  <NavDropdown.Item href='#' onClick={navigateToAbout}>
                    About
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={navigateToSettings}>
                    Settings
                    {
                      settings.data.has_new_version &&
                      <Popover content={'New version available'} placement='bottom' trigger="hover">
                        <img src={Error} alt="info" style={{ marginLeft: '20px', width: '18px', verticalAlign: 'text-top' }} />
                      </Popover>
                    }
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={restartSystem}>
                    Restart
                  </NavDropdown.Item>
                  <Nav.Item href="#" className='sign-out-item'>
                    <Button variant="text" className='sign-out-btn ' onClick={handleLogout}>Sign out</Button>
                  </Nav.Item>
                </NavDropdown>
                :
                <NavDropdown
                  className='border-bottom'
                  title={
                    <span className='d-inline-flex gap-1 align-items-center'>
                      <div className='user-circle'> {currentUser.email.slice(0, 2)}</div>
                      <span className=''>{currentUser.email}</span>
                    </span>
                  }
                  id="collapsible-nav-dropdown" >
                  {/*<NavDropdown.Item href='#' onClick={handleOpenChoochPlatformAccount}>
                      Account
                    </NavDropdown.Item>*/}
                  <NavDropdown.Item className='fifth-step' href="#" onClick={navigateToSettings}>
                    Settings
                  </NavDropdown.Item>
                  {/*<NavDropdown.Item href="#" onClick={restartSystem}>
                      Restart
                    </NavDropdown.Item>*/}
                  <NavDropdown.Item href='#' onClick={navigateToAbout}>
                    Help
                  </NavDropdown.Item>
                  <Nav.Item href="#" className='sign-out-item'>
                    <Button variant="text" className='sign-out-btn ' onClick={handleLogout}>Sign out</Button>
                  </Nav.Item>
                </NavDropdown>
            }
            <Nav.Item className='menu'>
              <ChatHistoryList />
            </Nav.Item>
          </Nav>
        </Offcanvas.Body>
        <div className={'border-bottom'}></div>
        <Nav.Item href='#' className='select-language mt-4 px-1'>
          <SelectLanguage height={'400px'} />
        </Nav.Item>
      </Navbar.Offcanvas>
    </Navbar>
  )
}
