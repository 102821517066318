import TestImage1 from "../../assets/images/testImage1.jpg";
import Thumbnail1 from "../../assets/images/thumbnail1.jpg";
import TestImage2 from "../../assets/images/testImage2.jpg";
import Thumbnail2 from "../../assets/images/thumbnail2.jpg";
import TestImage3 from "../../assets/images/testImage3.jpg";
import Thumbnail3 from "../../assets/images/thumbnail3.jpg";
import TestImage4 from "../../assets/images/testImage4.jpg";
import Thumbnail4 from "../../assets/images/thumbnail4.jpg";
import TestImage5 from "../../assets/images/testImage5.jpg";
import Thumbnail5 from "../../assets/images/thumbnail5.jpg";
import TestDocument1 from "../../assets/files/Invoice.pdf";

import textToImageThumb from "../../assets/images/textToImageThumb.png";
import portraitStylesThumb from "../../assets/images/portraitStylesThumb.png";
import imageToImageThumb from "../../assets/images/imageToImageThumb.png";
import imageToTextThumb from "../../assets/images/imageToTextThumb.png";
import aiChatThumb from "../../assets/images/aiChatThumb.svg";
import aiChatThumbDark from "../../assets/images/aiChatThumbDark.svg";
import chatWithDocsThumb from "../../assets/images/chatWithDocsThumb.svg";

import cartoon from "../../assets/images/cartoon.png";
import neonPunk from "../../assets/images/neonPunk.png";
import gameArt from "../../assets/images/gameArt.png";
import fantasyArt from "../../assets/images/fantasyArt.png";
import lineArt from "../../assets/images/lineArt.png";
import retroArcade from "../../assets/images/retroArcade.png";
import watercolor from "../../assets/images/watercolor.png";
import comicBook from "../../assets/images/comicBook.png";
import popArt from "../../assets/images/popArt.png";
import renaissance from "../../assets/images/renaissance.png";
import anime from "../../assets/images/anime.png";
import filmNoir from "../../assets/images/filmNoir.png";

import imgToImg1 from '../../assets/images/imgToImg1.png';
import imgToImg2 from '../../assets/images/imgToImg2.png';
import imgToImg3 from '../../assets/images/imgToImg3.png';
import imgToImg4 from '../../assets/images/imgToImg4.png';
import imgToImg5 from '../../assets/images/imgToImg5.png';
import imgToImg6 from '../../assets/images/imgToImg6.png';

import imgToText1 from '../../assets/images/imgToText1.png';
import imgToText2 from '../../assets/images/imgToText2.png';
import imgToText3 from '../../assets/images/imgToText3.png';
import imgToText4 from '../../assets/images/imgToText4.png';
import imgToText5 from '../../assets/images/imgToText5.png';
import imgToText6 from '../../assets/images/imgToText6.png';

import Invoice from "../../assets/files/ABC_Company's_Invoice.pdf";
import Max_Riley_CV from "../../assets/files/Max_Riley_CV.docx";
import Classical_Literature from "../../assets/files/Classical_Literature.pptx";
import Annual_Review from "../../assets/files/Annual_Review.pptx";
import Team_Meeting_Agenda from "../../assets/files/Team_Meeting_Agenda.docx";
import Monthly_Salary from "../../assets/files/Monthly_Salary.xlsx";




const prompts = [
  {
    id: 1,
    title: "Text to Image Generation",
    description: '<p class="prompt-item-description">Start with <strong>Generate</strong> or <strong>Create</strong> to get more accurate results</p>',
    examples: [
      {
        exampleID: 1,
        type: "text",
        name: "example1",
        examplePrompt: "Create a bearded man, cinematic lighting, shot with dslr camera, 80mm, enchanting, photorealism, masterpiece, atmosphere, highly detailed",
      },
      {
        exampleID: 2,
        type: "text",
        name: "example2",
        examplePrompt: "Generate a winter scene with an igloo and camels around it."
      }
    ]
  },
  {
    id: 2,
    title: "Image to Text Generation",
    description: '<p class="prompt-item-description">Convert the image to text by starting with <strong>Describe the image.</strong></p>',
    examples: [
      {
        exampleID: 1,
        type: "image",
        name: "imageName.jpg",
        file: TestImage1,
        thumbnail: Thumbnail1,
        examplePrompt: "Describe the image"
      },
      {
        exampleID: 2,
        type: "image",
        name: "imageName.jpg",
        file: TestImage2,
        thumbnail: Thumbnail2,
        examplePrompt: "What type of house is this?"
      }
    ]
  },
  {
    id: 3,
    title: "Image to Image Generation",
    description: "<p class=\"prompt-item-description\">Start with <strong>Generate a similar image</strong> or <strong>Restore this image.</strong></p>",
    examples: [
      {
        exampleID: 1,
        type: "image",
        name: "imageName.jpg",
        file: TestImage3,
        thumbnail: Thumbnail3,
        examplePrompt: "Restore this image"
      },
      {
        exampleID: 2,
        type: "image",
        name: "imageName.jpg",
        file: TestImage4,
        thumbnail: Thumbnail4,
        examplePrompt: "Create a similar image"
      }
    ]
  },
  {
    id: 4,
    title: "AI Chat",
    description: "<p class='prompt-item-description'>Enter a prompt and start chatting  or upload an image to chat with it.</p>",
    examples: [
      {
        exampleID: 1,
        type: "image",
        name: "imageName.jpg",
        file: TestImage5,
        thumbnail: Thumbnail5,
        examplePrompt: "Write an image title and text best suited for SEO"
      },
      {
        exampleID: 2,
        type: "image",
        name: "imageName.jpg",
        file: "",
        examplePrompt: "What are the most efficient methods to master Python quickly?"
      }
    ]
  },
  {
    id: 5,
    title: "Chat with Document",
    description: "<p class='prompt-item-description'>Upload a document and start with <strong>List the key points</strong> or <strong>Summarize this document.</strong>",
    examples: [
      {
        exampleID: 1,
        type: "document",
        fileType: "pdf",
        name: "Invoice.pdf",
        file: TestDocument1,
        examplePrompt: "How much is the total amount?",
        examplePromptTitle: "How much is the total amount?"
      }
    ]
  }
];

export default prompts;


export const categories = [
  {
    id: 1,
    title: 'Text-to-Image Generation',
    description: '<p class="prompt-item-description">For <strong>Text-to-Image</strong> generation, begin prompt with <span>Generate</span> or <span>Create</span> for more accurate results.</p>',
    img: textToImageThumb,
    type: "text",
    examples: [
      {
        exampleID: 1,
        name: "example1",
        type: "text",
        examplePrompt: "Generate an image of an embroidered tapestry in the style of Tudor England, showing a scene from a hunt, with rich colors and skilled embroidery"
      },
      {
        exampleID: 2,
        name: "example2",
        type: "text",
        examplePrompt: "Generate a vintage oil painting of a royal leopard portrait, with flowers on it's head wrapped in silk, vivid colors, moody, dark background",
      },
      {
        exampleID: 3,
        name: "example3",
        type: "text",
        examplePrompt: "Create an image of a cute papercraft city using purple, pink, and orange colors"
      },
      {
        exampleID: 4,
        name: "example4",
        type: "text",
        examplePrompt: "Generate an image of a Greek Salad, flying ingredients, creative, dramatic light, kodak 70mm, octane render, 8K, HD, pink background"
      },
      {
        exampleID: 5,
        name: "example5",
        type: "text",
        examplePrompt: "Generate an image of a fractal miniature fantasy landscape in a glass sphere floating in the space, RAW photo, hd, dramatic lighting, low light"
      },
      {
        exampleID: 6,
        name: "example6",
        type: "text",
        examplePrompt: "Create an image of a modern space station depicted with cinematic lighting, jewel tones, and extreme photorealism."
      },
    ]
  },
  {
    id: 2,
    title: 'Portrait Styles',
    description: '<p class="prompt-item-description">For <strong>Portrait Styles</strong>, upload your portrait and <span>enter a style keyword</span> to generate similar image styles.</p>',
    img: portraitStylesThumb,
    type: "image",
    examples: [
      {
        exampleID: 1,
        name: "Cartoon",
        type: "image",
        thumbnail: cartoon,
        examplePrompt: 'Generate this image in cartoon style, city background, vibrant'
      },
      {
        exampleID: 2,
        name: "Neon Punk",
        type: "image",
        thumbnail: neonPunk,
        examplePrompt: 'Generate this image in neon punk style'
      },
      {
        exampleID: 3,
        name: "Game Art",
        type: "image",
        thumbnail: gameArt,
        examplePrompt: 'Generate this image in game art style, city background'
      },
      {
        exampleID: 4,
        name: "Fantasy Art",
        type: "image",
        thumbnail: fantasyArt,
        examplePrompt: 'Generate this image in fantasy art style'
      },
      {
        exampleID: 5,
        name: "Line Art",
        type: "image",
        thumbnail: lineArt,
        examplePrompt: 'Generate this image in line art style'
      },
      {
        exampleID: 6,
        name: "Retro Arcade",
        type: "image",
        thumbnail: retroArcade,
        examplePrompt: 'Generate this image in Retro-Arcade style, arcade background'
      },
      {
        exampleID: 7,
        name: "Watercolor",
        type: "image",
        thumbnail: watercolor,
        examplePrompt: 'Generate this image in watercolor style, forest background'
      },
      {
        exampleID: 8,
        name: "Comic Book",
        type: "image",
        thumbnail: comicBook,
        examplePrompt: 'Generate this image in comic book style, city background'
      },
      {
        exampleID: 9,
        name: "Pop Art",
        type: "image",
        thumbnail: popArt,
        examplePrompt: 'Generate this image in pop-art style, city background'
      },
      {
        exampleID: 10,
        name: "Renaissance",
        type: "image",
        thumbnail: renaissance,
        examplePrompt: 'Generate this image in Renaissance style'
      },
      {
        exampleID: 11,
        name: "Anime",
        type: "image",
        thumbnail: anime,
        examplePrompt: 'Generate this image in anime style, beach, palms, summer vibes'
      },
      {
        exampleID: 12,
        name: "Black & White Film Noir",
        type: "image",
        thumbnail: filmNoir,
        examplePrompt: 'Generate this image in black and white film noir style, beach, palms, summer vibes'
      }
    ]
  },
  {
    id: 3,
    title: 'Image-to-Image Generation',
    description: '<p class="prompt-item-description">For <strong>Image-to-Image</strong> generation, start prompt with <span>Generate a similar image</span> or <span>Restore this image</span>.</p>',
    img: imageToImageThumb,
    type: "imageToImage",
    examples: [
      {
        exampleID: 1,
        examplePrompt: "Restore this image",
        type: "image",
        thumbnail: imgToImg1,
        file: imgToImg1
      },
      {
        exampleID: 2,
        examplePrompt: "Generate a similar image",
        type: "image",
        thumbnail: imgToImg2,
        file: imgToImg2,
      },
      {
        exampleID: 3,
        examplePrompt: "Generate a similar image",
        type: "image",
        thumbnail: imgToImg3,
        file: imgToImg3,
      },
      {
        exampleID: 4,
        examplePrompt: "Generate a similar image",
        type: "image",
        thumbnail: imgToImg4,
        file: imgToImg4,
      },
      {
        exampleID: 5,
        examplePrompt: "Generate a similar image",
        type: "image",
        thumbnail: imgToImg5,
        file: imgToImg5,
      },
      {
        exampleID: 6,
        examplePrompt: "Generate a similar image",
        type: "image",
        thumbnail: imgToImg6,
        file: imgToImg6,
      }
    ]
  },
  {
    id: 4,
    title: 'Image-to-Text Generation',
    description: '<p class="prompt-item-description">For <strong>Image-to-Text</strong> generation, start prompt with <span>Describe this image</span> to convert it to text.</p>',
    img: imageToTextThumb,
    type: "imageToText",
    examples: [
      {
        exampleID: 1,
        examplePrompt: "Review this interior from an architectural perspective",
        type: "image",
        thumbnail: imgToText1,
        file: imgToText1,
      },
      {
        exampleID: 2,
        examplePrompt: "Create a name and promotion for this hand soap",
        type: "image",
        thumbnail: imgToText2,
        file: imgToText2,
      },
      {
        exampleID: 3,
        examplePrompt: "Describe this image",
        type: "image",
        thumbnail: imgToText3,
        file: imgToText3,
      },
      {
        exampleID: 4,
        examplePrompt: "How should I care for this plant?",
        type: "image",
        thumbnail: imgToText4,
        file: imgToText4,
      },
      {
        exampleID: 5,
        examplePrompt: "How can I make this dessert?",
        type: "image",
        thumbnail: imgToText5,
        file: imgToText5,
      },
      {
        exampleID: 6,
        examplePrompt: "What is this art style called?",
        type: "image",
        thumbnail: imgToText6,
        file: imgToText6,
      }
    ]
  },
  {
    id: 5,
    title: 'AI Chat',
    description: '<p class="prompt-item-description">For <strong>AI Chat</strong>, create the prompt with specific context, topic, tone, and instructions succinctly.</p>',
    img: aiChatThumb,
    imgDark: aiChatThumbDark,
    type: "textai",
    examples: [
      {
        exampleID: 1,
        name: "example1",
        examplePrompt: "Create 10 blog post ideas about LLMs"
      },
      {
        exampleID: 2,
        name: "example2",
        examplePrompt: "Create a job description for an HR Generalist",
      },
      {
        exampleID: 3,
        name: "example3",
        examplePrompt: "Recommend some hobbies that I can pursue from the comfort of my home"
      },
      {
        exampleID: 4,
        name: "example4",
        examplePrompt: "What are the top movies to see in theatres this weekend?"
      },
      {
        exampleID: 5,
        name: "example5",
        examplePrompt: "What are the best breakfast restaurants in Houston, Texas?"
      },
      {
        exampleID: 6,
        name: "example6",
        examplePrompt: "What is the difference between compliment and complementary?"
      },
    ]
  },
  {
    id: 6,
    title: 'Chat with Documents',
    description: '<p class="prompt-item-description">For <strong>Chat with Documents</strong>, upload a document and start with <span>Write the key points</span> or <span>Summarize this document</span>.</p>',
    img: chatWithDocsThumb,
    type: "document",
    examples: [
      {
        exampleID: 1,
        type: "document",
        fileType: "pdf",
        name: "Invoice.pdf",
        file: Invoice,
        examplePrompt: "What is the total invoice amount?",
        examplePromptTitle: "ABC Company's Invoice"
      },
      {
        exampleID: 2,
        type: "document",
        fileType: "docx",
        name: "Max_Riley_CV.docx",
        file: Max_Riley_CV,
        examplePrompt: "Summarize Riley's work experience",
        examplePromptTitle: "Max Riley CV"
      },
      {
        exampleID: 3,
        type: "document",
        fileType: "pptx",
        name: "Classical_Literature.pptx",
        file: Classical_Literature,
        examplePrompt: "What is the difference between Classical and Classic Literature?",
        examplePromptTitle: "Classical Literature"
      },
      {
        exampleID: 4,
        type: "document",
        fileType: "pptx",
        name: "Annual_Review.pptx",
        file: Annual_Review,
        examplePrompt: "By what percentage did profit increase in the last quarter?",
        examplePromptTitle: "Annual Review"
      },
      {
        exampleID: 5,
        type: "document",
        fileType: "docx",
        name: "Team_Meeting_Agenda.docx",
        file: Team_Meeting_Agenda,
        examplePrompt: "Summarize the team meeting agenda.",
        examplePromptTitle: "Team Meeting Agenda"
      },
      {
        exampleID: 6,
        type: "document",
        fileType: "xlsx",
        name: "Monthly_Salary.xlsx",
        file: Monthly_Salary,
        examplePrompt: "What is the salary of Olivia Hall?",
        examplePromptTitle: "Monthly Salary"
      }
    ]
  }
]
