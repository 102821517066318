import axios from "axios";
import { getApiURL, getRefreshURL } from './generic';

function getAccessToken() {
  if (localStorage.getItem('rememberUser') === 'true') {
    return localStorage.getItem('access_token');
  }
  else {
    return sessionStorage.getItem('access_token');
  }
}

function getRefreshToken() {
  if (localStorage.getItem('rememberUser') === 'true') {
    return localStorage.getItem('refresh_token');
  }
  else {
    return sessionStorage.getItem('refresh_token');
  }
}

function setTokens(tokenObj) {
  if (localStorage.getItem('rememberUser') === 'true') {
    localStorage.setItem('access_token', tokenObj.access);
    localStorage.setItem('refresh_token', tokenObj.refresh);
  }
  else {
    sessionStorage.setItem('access_token', tokenObj.access);
    sessionStorage.setItem('refresh_token', tokenObj.refresh);
  }
}

const AXIOS_INSTANCE = axios.create({
  baseURL: getApiURL(),
});

AXIOS_INSTANCE.interceptors.request.use(
  config => {
    const token = getAccessToken();
    config.headers = {
      'Authorization': 'Bearer ' + token,
    }
    return config;
  },
  error => {
    return Promise.reject(error)
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

AXIOS_INSTANCE.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry && err.response.data.code === 'invalid_token') {
      if (isRefreshing) {
        if (err.request.status === 401 ) {
          if (localStorage.getItem('rememberUser') === 'true') {
            localStorage.clear();
          }
          else {
            sessionStorage.clear();
          }

          localStorage.setItem('isRefreshTokenExpired', 'true');
          window.location.href = '/login';
        }
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        AXIOS_INSTANCE
          .post(getRefreshURL() + '/authentication/token/refresh/', {
            'refresh': getRefreshToken()
          })
          .then(({ data }) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
            setTokens(data);
            processQueue(null, data.access_token);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            // if refresh token is expired, redirect to login page
            if (localStorage.getItem('rememberUser') === 'true') {
              localStorage.clear();
            }
            else {
              sessionStorage.clear();
            }
            localStorage.setItem('isRefreshTokenExpired', 'true');
            window.location.href = '/login';
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

export {
  AXIOS_INSTANCE
};
