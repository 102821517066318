import { useDispatch, useSelector } from 'react-redux';
import { getSettings, openModal } from '../state/slices/settingSlice';
import { Container, Tabs, Tab, } from 'react-bootstrap';
import { Form, Input, Popover } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Loader from './Loader';
import Info from '../assets/images/info-icon.svg';
import SystemUpgradeModal from './SystemUpgradeModal';
import { AXIOS_INSTANCE } from './utils/axiosInstance';
import { CheckCircleFill, Eye, EyeSlash, InfoCircleFill } from 'react-bootstrap-icons';
import { useAuth } from "./utils/AuthContext";
import Copy from '../assets/images/copy.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ChevronRight } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import { useTheme } from "./utils/ThemeContext";

export default function SettingsPage() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const navigate = useNavigate();
  const { darkTheme, toggleTheme } = useTheme();

  const { isMobile } = useAuth();

  const [file, setFile] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [companyLogo, setCompanyLogo] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('general');
  const [canSave, setCanSave] = useState(false);
  const [isGettingReady, setIsGettingReady] = useState(false)
  const [isCopied, setIsCopied] = useState(false);

  const [showApiKey, setShowApiKey] = useState(true)

  const themeOptions = [
    {
      label: 'Dark Theme',
      value: 'dark'
    },
    {
      label: 'Light Theme',
      value: 'light'
    }
  ]

  const updateThemeStatus = (e) => {
    AXIOS_INSTANCE.patch('/user-setting/',
      {
        'dark_mode': e.value === 'dark' ? true : false
      })
      .then(res => {
        toggleTheme(e.value)
      })
      .catch(error => {
        console.log(error)
      });
  }


  const getSetting = useCallback(() => {
    dispatch(getSettings()).then((response) => {
      const { payload } = response;
      setCompanyName(payload.company_name);
      setContactEmail(payload.contact_email);
      setFile([payload.company_logo]);
      setCompanyLogo((payload.company_logo !== null) && (payload.company_logo !== 'null') && (payload.company_logo !== 'undefined') && (payload.company_logo !== ''));
      setIsUploading(false);
    }).catch((error) => {
      console.log('error', error);

    })
  }, [dispatch]);

  useEffect(() => {
    if (process.env.REACT_APP_ON_PREM !== 'false') {
      const getRestartStat = () => {
        AXIOS_INSTANCE.get('/system-restart/')
          .then(res => {
            if (res.data.healthy) {
              setIsGettingReady(false);
            }
            else {
              setIsGettingReady(true);
            }
          })
      }
      getSetting();
      getRestartStat();

      const intervalId = setInterval(() => {
        getRestartStat();
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const handleSystemUpgrade = () => {
    dispatch(openModal());
  }

  const handleApiKeyCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }

  const navigateToHomepage = () => {
    navigate("/home");
  }

  if (settings?.status === 'loading') {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div className='settings-page'>
        <div className='breadcrumb'>
          <div className='main-breadcrumb' onClick={navigateToHomepage}>
            Homepage
          </div>
          <ChevronRight size={14} />
          <div className='sub-breadcrumb'>
            Settings
          </div>
        </div>

        <div className='settings-page-content'>
          <div className='box-label'>Theme</div>
          <Select
            className="basic-single theme-selector"
            classNamePrefix="select"
            isSearchable={false}
            defaultValue={darkTheme ? themeOptions[0] : themeOptions[1]}
            options={themeOptions}
            onChange={(e) => updateThemeStatus(e)}
          />

          <div className='box-label'>API</div>
          <div className='setting-box'>
            API Key
            <div className='key-box'>
              {showApiKey ? settings?.data?.get_api_key || '' : '*************************'}
              {showApiKey ?
                <EyeSlash onClick={() => setShowApiKey(!showApiKey)} />
                :
                <Eye onClick={() => setShowApiKey(!showApiKey)} />
              }
            </div>
            <CopyToClipboard text={settings?.data?.get_api_key || ''} onCopy={handleApiKeyCopy} >
              <span style={{ cursor: 'pointer' }}>
                <img src={Copy} alt="copy" style={{ marginRight: '8px' }} /> {isCopied ? 'Copied' : 'Copy'}
              </span>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <SystemUpgradeModal />
    </>
  )
}
