import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { TourProvider } from '@reactour/tour'
import { X } from 'react-bootstrap-icons';
import { AXIOS_INSTANCE } from './components/utils/axiosInstance'
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

/*TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

TagManager.dataLayer({
  dataLayer: {
    event: 'Sent Message',
    category: 'User',
    action: 'Sent Message'
  }
});*/

ReactGA.initialize(process.env.REACT_APP_GTAG_ID);

ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Chat'}); // Record a pageview for the given page

ReactGA.event({
  category: 'User',
  action: 'Sent Message'
});

const root = ReactDOM.createRoot(document.getElementById('root'));

function ContentComponent(props) {
  const isLastStep = props.currentStep === props.steps.length - 1
  const title = props.steps[props.currentStep].title
  const content = props.steps[props.currentStep].content

  const closeTour = () => {
    props.setIsOpen(false)
    props.setCurrentStep(0)
  }

  const finishTour = () => {
    props.setIsOpen(false)
    props.setCurrentStep(0)
    updateOnboardingStatus()
  }

  const updateOnboardingStatus = () => {
    AXIOS_INSTANCE.patch('/user-setting/',
      {
        'completed_onboarding': true
      })
      .then(res => {
        sessionStorage.setItem('isWelcomeSeen', true)
      })
      .catch(error => {
        console.log(error)
      });
  }

  return (
    <div className='tour-box'>
      <div className='w-100 d-flex justify-content-between align-itens-center'>
        <h5>{title}</h5>
        <X
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={closeTour}
        />
      </div>

      <p>{content}</p>
      <div className='w-100 d-flex justify-content-between align-itens-center'>
        <div>{props.currentStep + 1}/{props.steps.length}</div>
        <div className='w-100 d-flex justify-content-end'>
          {
            props.currentStep !== 0 &&
            <button
              className='back-btn'
              disabled={props.currentStep === 0}
              onClick={() => {
                props.setCurrentStep((s) => s - 1)
              }}
            >
              Back
            </button>
          }
          <button
            className='next-btn'
            onClick={() => {
              if (isLastStep) {
                finishTour()
              } else {
                props.setCurrentStep((s) => s + 1)
              }
            }}
          >
            {isLastStep ? 'Finish Tutorial' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  )
}

const steps = [
  {
    selector: '.first-step',
    title: 'Getting started',
    content: 'To start a conversation, ask a question in the input box or upload an image or file.',
  },
  {
    selector: '.second-step',
    title: 'Test examples',
    content: 'Try ImageChat on documents and images or even ask it questions to see how it works.',
  },
  {
    selector: '.third-step',
    title: 'Start a new chat',
    content: 'Begin a new conversation by selecting ‘New Chat’ button. ',
  },
  {
    selector: '.fourth-step',
    title: 'Chat History',
    content: 'You can view your most recent chat history and have the option to delete any entries if desired.',
  },
  {
    selector: '.fifth-step',
    title: 'Settings',
    content: 'You can access the Settings, Help and Account pages from your username located at the top right.',
  }
]

root.render(
  <TourProvider steps={steps} ContentComponent={ContentComponent}>
    <App />
  </TourProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
