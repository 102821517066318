
export default function VerifyEmail({verifyEmail, setVerifyEmail}) {
  return (
    <div className='h-100 d-flex align-items-center justify-content-center'>
      <div className='verify-email'>
        <p className='verfication-email-text mb-0 fw-bold'>We sent you a a verification e-mail.</p>
        <p className='verfication-email-text fw-bold mb-4'>Please check your email.</p>
        <button
          className='back-to-login-btn'
          onClick={() => setVerifyEmail(!verifyEmail)}
        >
          Back to Log In
        </button>
      </div>
    </div>
  )
}
