import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import companyLogo from '../assets/images/choochLogo.png';
import { useAuth } from './utils/AuthContext';
import ResetPassword from './ResetPassword';
import { CheckCircleFill } from 'react-bootstrap-icons';
import DOMPurify from 'dompurify';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated } = useAuth();
  const [isInvalid, setIsInvalid] = useState(false)

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleResetPassword = () => {
    if (!isValidEmail) {
      return;
    }
    setIsLoading(true);
    return fetch(process.env.REACT_APP_BASE_URL + '/authentication/reset_password/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: email,
        send_email: true
      })
    }).then(response => {
      if (response.status === 200) {
        setIsLoading(false);
        navigate('/reset-password', { state: { email } });
      } else {
        setIsLoading(false);
        setIsInvalid(true)
      }
    })
  }

  const handleChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(emailRegex.test(e.target.value));
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && isValidEmail) {
      e.preventDefault();
      await handleResetPassword();
    }
  }

  return (
    <>
      <div className='forgot-password-container'>
        <Image src={companyLogo} alt="company logo" className="company-logo" />
        <h2 className="forgot-password-title">Forgot Password?</h2>
        <p className="forgot-password-text">No worries, we will send you a link here where you can reset your password.</p>

        <Form className='d-inline-flex justify-content-center gap-2 flex-wrap' >
          <Form.Group className="mb-4 position-relative">
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              isInvalid={isInvalid}
              className={`email-input ${isValidEmail ? 'valid-email' : ''}`}
            />
            {isValidEmail && (
              <CheckCircleFill className="valid-email-icon" color='#006644' />
            )}
          </Form.Group>
          <Button
            variant="primary"
            onClick={handleResetPassword}
            className="reset-password-btn mb-4"
            disabled={isLoading || !isValidEmail}
          >
            {isLoading ? <Spinner as="span" animation="border" size="sm" /> : 'Reset Password'}
          </Button>
        </Form>

        <p className="remember-question">
          Do you remember your password?
          <span className='back-to-login' onClick={() => navigate('/login')}> Login</span>
        </p>
        <p className="copyright">
          All rights reserved © Chooch
          Intelligence Technologies Co.
        </p>
      </div>
    </>
  );
}
