import React, { useState } from 'react';
import Select, {components} from 'react-select';
import Search from '../assets/images/search-white.svg';
import { languageOptions } from './utils/generic';
import { isMultilingual } from '../config';
import { useTheme } from './utils/ThemeContext';



/**** Select components ****/
const Control = ({ children, ...props }) => (
  <components.Control {...props} href='#'>
    <span>
      {isMultilingual  && <img src={Search} alt="search"  />}
    </span>
    {children}
  </components.Control>
);
export default function SelectLanguage({ show, ...props }) {
  const [language, setLanguage] = useState(JSON.parse(localStorage.getItem('language')) || { value: 'en', label: 'English (default)' });

  const handleLanguageChange = (language) => {
    localStorage.setItem('language', JSON.stringify(language));
    setLanguage(JSON.parse(localStorage.getItem('language')));
    setLanguage(language);
  }

  const { darkTheme } = useTheme()

/**** Select styles ****/
const selectStyles = {
  menu:(styles) => ({
    ...styles,
    backgroundColor: darkTheme ? '#38363B' : '#F7F7F7',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: styles.height || '68vh',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    display: !isMultilingual && 'none'
  }),
  input: styles => ({
    ...styles,
    height: '40px',
    width: '100px',
    color: darkTheme ? '#F7F7F7' : '#4E4576',
  }),
  control: (styles, { isFocused, isActive }) => {

    return {
      ...styles,
      backgroundColor: 'linear-gradient(90deg, #8020CF 0%, #472CE6 100%)',
      border: isFocused ? '1px solid #AAAAAA' : isActive ? '1px solid #AAAAAA' : 'none',
      boxShadow: '0 0 1 0 #2C2C2C4F',
      borderRadius: '5px',
      padding: '0 10px',
      height: '50px',
      cursor: 'pointer',
      "&:hover": isFocused ? {
        border: '1px solid #AAAAAA',
      } : 'none'
    }
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: darkTheme ? '#F7F7F7' : '#535353',
      fontSize: '16px',
      fontWeight: '500',
      lineWeight: '16px',
      backgroundColor: darkTheme ? isSelected || isFocused ? '#6D6C6F' : '#38363B' : isSelected || isFocused ? '#F9F2FF' : '#F7F7F7', // menu list bg color
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  placeholder: styles => ({
    ...styles,
    color: 'red !important',
  }),
  singleValue: styles => {
    return {
      ...styles,
      color: darkTheme ? '#F7F7F7' : '#4E4576',
      fontSize: '16px',
      fontWeight: '500 !important',
      textAlign: 'center'
    }
  }
}

  return (
    <Select
      isDisabled={!isMultilingual}
      menuPlacement={props.placement || 'top'}
      components={{ Control, IndicatorSeparator: () => null }}
      className="basic-single language-select"
      classNamePrefix="select"
      defaultValue={ language || languageOptions[0]}
      isSearchable={true}
      name="language"
      options={languageOptions}
      styles={{ ...selectStyles, menuList: styles => ({props, ...styles, maxHeight: props.height || '68vh' }) }}
      onChange={language => handleLanguageChange(language)}
    />
  )
}
