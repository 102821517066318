import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Spinner, Form, OverlayTrigger, Tooltip, Image } from 'react-bootstrap'
import { FileUploader } from "react-drag-drop-files";
import { useToast } from './utils/ToastContext';
import infoIcon from '../assets/images/info-icon.svg'
import { uploadFiles, uploadLink } from '../state/slices/chatSlice';
import { getSupportedFileTypes } from './utils/generic';

export default function AddSingleFileModal(props) {
  const chat = useSelector(state => state.chat);
  const dispatch = useDispatch();

  const { updateMessage } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [urlLink, setUrlLink] = useState('');

  const handleChange = (file) => {
    setSelectedFile(file);
  };

  const removeFile = () => {
    setSelectedFile(null)
  }

  const closeAndClearModal = () => {
    setSelectedFile(null);
    setUrlLink('');
    setIsLoading(false);
    props.handleClose();
  }

  const addFile = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append('language', JSON.parse(localStorage.getItem('language'))?.value || 'en');
    formData.append('session_id', '');

    setSelectedFile(selectedFile);
    props.handleAddFile(selectedFile)
    //await dispatch(uploadFiles(formData));
    closeAndClearModal();
  }

  useEffect(() => {
    if(selectedFile !== null) {
      addFile();
    }
  }, [selectedFile]);

  const addLink = async () => {
    setIsLoading(true);
    /*await dispatch(uploadLink({
      'source_url': urlLink,
      'language': JSON.parse(localStorage.getItem('language'))?.value,
      'session_id': chat.session
    }));*/
    props.handleAddUrl(urlLink)
    closeAndClearModal();
  }

  useEffect(() => {
    if (chat.status === 'failed' && chat.message === null) {
      updateMessage('Something went wrong! Please try again.', 'error')
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat.status])

  return (
    <Modal className='add-file-modal' show={props.show} centered size='lg' onHide={closeAndClearModal}>
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You can upload a file and then chat with the file you uploaded.</p>

        <FileUploader
          disabled={urlLink !== ''}
          classes={`${urlLink !== '' ? 'upload-disabled' : ''}`}
          multiple={false}
          handleChange={(file) => handleChange(file)}
          onTypeError={(file) => updateMessage('File type not supported. Please upload a valid file.', 'error')}
          name="file"
          types={JSON.parse(localStorage.getItem('supported_extensions'))}
          children={
              selectedFile !== null ?
                  <div className='drop-area-content file-uploaded-content'>
                    <div className='d-flex justify-content-center'>
                      <div className='file-uploaded'>File is added</div>
                    </div>
                    <div className='file-list'>
                      <div className='file-list-item'>
                        <div className='d-flex justify-content-between'>
                          <p className='file-name'>{selectedFile.name}</p>
                          <p className='remove-file' onClick={removeFile}>Remove</p>
                        </div>
                        <div className='progress-bar'></div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className='drop-area-content add-file-modal__container'>
                    <div className='add-file-modal__inner-container'>
                      <p className='add-file-modal__heading-text'><span>Browse</span> or drag and drop</p>
                      <ul className='add-file-modal__help-text'>
                        <li className='mb-2'>You can upload <span><strong>only one file</strong></span> at a time.</li>
                        <li>Supported formats are:
                          <span>{getSupportedFileTypes()}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
          }
        />

        {/*<div className='or-divider'>
          <div className='divider-border'></div>
          <div className='divider-text'>OR</div>
          <div className='divider-border'></div>
        </div>

        <Form.Group>
          <span className={`form-label ${selectedFile !== null ? 'disabled' : ''}`}>URL Link
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="bottom-start"
              overlay={
                <Tooltip className='upload-file-tooltip'>
                  <div className={'supported-file-info'}>
                    <span>Supported image <span style={{ color: '#8020CF' }}>file types</span>  .jpg, .jpeg, .png</span>
                  </div>
                  <div className={'supported-file-info'}>
                    <span>YouTube URL must contain the transcript.</span>
                  </div>
                </Tooltip>
              }
            >
              <Image
                className='add-file-modal__info-icon'
                alt='info'
                src={infoIcon}
              />
            </OverlayTrigger>
          </span>
          <Form.Control
            type="text"
            onChange={(e) => setUrlLink(e.target.value)}
            disabled={selectedFile !== null}
            value={urlLink}
            placeholder={`Paste an Image link`}
          />
        </Form.Group>*/}

      </Modal.Body>
      <Modal.Footer>
        {/*{selectedFile !== null ?
          isLoading ?
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                  style={{
                    color: '#8020cf'
                  }}
                />
                &nbsp;
                Processing
              </>
              :
              <span>Upload</span>
          :
          urlLink.length > 0 ?
            <div className='gradient-bordered-area' onClick={addLink}>
              <button className='gradient-bordered-btn'>
                {isLoading ?
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                      style={{
                        color: '#8020cf'
                      }}
                    />
                    &nbsp;
                    Processing
                  </>
                  :
                  'Upload'
                }
              </button>
            </div>
            :
            <button className='disabled-btn'>
              Upload
            </button>
        }*/}
      </Modal.Footer>
    </Modal>
  )
}
