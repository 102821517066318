import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
  AXIOS_INSTANCE
} from '../../components/utils/axiosInstance';

const initialState = {
  data: {},
  modal: false,
}

export const getSettings = createAsyncThunk('getSettings',
  async () => {
    const response = await AXIOS_INSTANCE.get('/user-setting/');
    return response.data;
  }
);

export const updateSettings = createAsyncThunk('updateSettings',
  async (formData) => {
    const response = await AXIOS_INSTANCE.put('/user-setting/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
);

export const upgradeSystem = createAsyncThunk('upgradeSystem',
  async () => {
    const response = await AXIOS_INSTANCE.patch('/user-setting/', {'upgrade': true});
    return response.data;
  }
);

export const {actions, reducer} = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    openModal: (state) => {
      state.modal = true
    },
    hideModal: (state) => {
      state.modal = false
    }
  },
  extraReducers: (builder) => {
    // Get Settings
    builder.addCase(getSettings.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload;

    });
    builder.addCase(getSettings.rejected, (state) => {
      state.status = 'failed';
    });

    // Update Settings
    builder.addCase(updateSettings.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(updateSettings.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload;
    });
    builder.addCase(updateSettings.rejected, (state) => {
      state.status = 'failed';
    });

    // Upgrade System
    builder.addCase(upgradeSystem.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(upgradeSystem.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload;
    });
    builder.addCase(upgradeSystem.rejected, (state) => {
      state.status = 'failed';
    })
  }
});

export const { openModal, hideModal } = actions;
