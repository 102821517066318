import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
  AXIOS_INSTANCE
} from '../../components/utils/axiosInstance';

const initialState = {
  status: localStorage.getItem('restartStatus'),
}

export const getRestartStatus = createAsyncThunk('getRestartStatus',
  async () => {
    const response = await AXIOS_INSTANCE.get('/system-restart/');
    return response.data;
  }
);

export const startRestart = createAsyncThunk('startRestart',
  async () => {
    const response = await AXIOS_INSTANCE.post('/system-restart/');
    return response.data;
  }
);

export const {actions, reducer} = createSlice({
  name: 'restart',
  initialState,
  extraReducers: (builder) => {
    // Restart Status
    builder.addCase(getRestartStatus.pending, (state, action) => {
      state.status = 'restartLoading';
      localStorage.setItem('restartStatus', 'restartLoading')
    });
    builder.addCase(getRestartStatus.fulfilled, (state, action) => {
      if(action.payload.healthy){
        state.status = 'success';
        localStorage.setItem('restartStatus', 'success')
      }
      else{
        state.status = 'restartLoading';
        localStorage.setItem('restartStatus', 'restartLoading')
      }
    });
    builder.addCase(getRestartStatus.rejected, (state) => {
      state.status = 'failed';
      localStorage.setItem('restartStatus', 'failed')
    })

    // Restart Start
    builder.addCase(startRestart.pending, (state, action) => {
      state.status = 'restartLoading';
      localStorage.setItem('restartStatus', 'restartLoading')
    });
    builder.addCase(startRestart.fulfilled, (state, action) => {
      state.status = 'success';
      localStorage.setItem('restartStatus', 'success')
    });
    builder.addCase(startRestart.rejected, (state) => {
      state.status = 'failed';
      localStorage.setItem('restartStatus', 'failed')
    })
  }
});

export const { openModal, hideModal } = actions;
