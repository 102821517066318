import React, {
  useEffect,
  useState
} from 'react';
import companyLogo from '../assets/images/choochLogo.png';
import {
  Button,
  Form,
  InputGroup,
  Spinner
} from 'react-bootstrap';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { useToast } from './utils/ToastContext';
import { useAuth } from './utils/AuthContext';
import axios from 'axios';
import Loader from './Loader';
import {
  getApiURL, getRefreshURL,
} from './utils/generic';
import DOMPurify from 'dompurify';
import imageChatTitle
  from '../assets/images/imagechat-title.svg';
import homepage from '../assets/images/signup-homepage.png';

import LicenseAgreementModal
  from './LicenceAgreementModal';
import LoginGif
  from '../assets/images/login-api-code.gif';
import AppStoreLogo
  from '../assets/images/app-store-logo.svg';
import GooglePlayLogo
  from '../assets/images/google-play-logo.svg';
import GoogleLogin
  from '../assets/images/google.png';
import FacebookLogin
  from '../assets/images/logos_facebook.png';
import { Helmet } from 'react-helmet-async';

import { X, Check } from 'react-bootstrap-icons';
import VerifyEmail from './VerifyEmail';
import OneStepLeft from './OneStepLeft';
import ChevronLeft
  from '../assets/images/chevronLeft.png';
import ImageGeneration
  from '../assets/images/Hero-ImageGeneration.gif';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

/*const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayer: {
    event: 'pageview',
    page: window.location.pathname,
    title: 'Sign Up',
  }
}

TagManager.initialize(tagManagerArgs);*/

ReactGA.initialize(process.env.REACT_APP_GTAG_ID);

ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Sign Up' });


export default function CloudSignUp() {
  const oneLetterRegex = /[a-z]/;
  const oneCapitalLetterRegex = /[A-Z]/;
  const oneNumberRegex = /[0-9]/;
  const tenCharactersRegex = /.{10,}/;
  const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const maxSixteenCharsRegex = /^.{0,15}$/;
  // not special characters
  const firstLastNameRegex = /^[a-zA-ZğüşöçİĞÜŞÖÇâêîôûÂÊÎÔÛäöüÄÖÜéÉàÀèÈìÌòÒùÙáÁíÍóÓúÚñÑçÇłŁźŹżŻąĄęĘóÓúÚ\-'. ]+$/
  const lastNameRegex = /^[a-zA-ZğüşöçİĞÜŞÖÇâêîôûÂÊÎÔÛäöüÄÖÜéÉàÀèÈìÌòÒùÙáÁíÍóÓúÚñÑçÇłŁźŹżŻąĄęĘóÓúÚ\-'. ]+$/
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^<>]{10,}$/;

  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { updateMessage } = useToast();
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [isAtLeastOneLetter, setIsAtLeastOneLetter] = useState(false)
  const [isAtLeastOneCapitalLetter, setIsAtLeastOneCapitalLetter] = useState(false)
  const [isAtLeastOneNumber, setIsAtLeastOneNumber] = useState(false)
  const [isAtLeastTenCharacters, setIsAtLeastTenCharacters] = useState(false)
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [apiKey, setApiKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisibleConfirm, setIsPasswordVisibleConfirm] = useState(false);
  const [canRegister, setCanRegister] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false)
  const [isCheckingExistedUser, setIsCheckingExistedUser] = useState(false)
  const [showLicenseAgreement, setShowLicenseAgreement] = useState(false)
  const [isLicenseAgreementChecked, setIsLicenseAgreementChecked] = useState(false)
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false)
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);

  useEffect(() => {
    if (isAuthenticated === 'true') {
      navigate('/home');
    }

  }, [isAuthenticated, navigate]);

  const goToGooglePlay = () => {
    const url = 'https://play.google.com/store/apps/details?id=com.chooch.imagechat';
    const cleanURL = DOMPurify.sanitize(url);
    window.open(cleanURL, '_blank');
  }

  const goToAppStore = () => {
    const url = 'https://apps.apple.com/us/app/imagechat-ai-computer-vision/id1304120928';
    const cleanURL = DOMPurify.sanitize(url);
    window.open(cleanURL, '_blank');
  }

  const handleUserFirstNameChange = e => {
    e.preventDefault();
    let { target: { value } } = e;
    value = value.trim();
    //const inputVal = DOMPurify.sanitize(e.target.value);
    setIsFirstNameValid(maxSixteenCharsRegex.test(value) && firstLastNameRegex.test(value));
    setFirstName(e.target.value);
  };

  const handleUserLastNameChange = e => {
    e.preventDefault();
    let { target: { value } } = e;
    //const inputVal = DOMPurify.sanitize(e.target.value);
    value = value.trim();
    setIsLastNameValid(maxSixteenCharsRegex.test(lastName) && lastNameRegex.test(value));
    setLastName(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
    }
  };

  const handleEmailChange = e => {
    e.preventDefault();
    setEmailError(false)
    //const inputVal = DOMPurify.sanitize(e.target.value);
    setEmail(e.target.value);
  }

  const handleEmailBlur = (e) => {
    e.preventDefault();

    //const inputVal = DOMPurify.sanitize(e.target.value);

    if (!mailRegex.test(email) && email.length > 0) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }

  const handlePasswordChange = e => {
    e.preventDefault();
    setPassword(e.target.value)
    setPasswordError(!passwordRegex.test(e.target.value));
  }

  const handlePassConfirmation = e => {
    e.preventDefault();
    setPasswordConfirm(e.target.value)
    if (passwordConfirm !== password || !passwordRegex.test(e.target.value)) {
      setIsPasswordMatch(false)
    }
  }

  const goToLogin = () => {
    navigate('/login');
  }

  const handleAPIKeyChange = e => {
    e.preventDefault();
    const inputVal = DOMPurify.sanitize(e.target.value);
    setApiKey(inputVal)
  }

  const handleLicenseAgreement = () => {
    setIsLicenseAgreementChecked(true)
    setShowLicenseAgreement(false)
  }

  const handleCancelLicenseAgreement = () => {
    setIsLicenseAgreementChecked(false)
    setShowLicenseAgreement(false)
  }

  const handleRegister = async () => {
    setIsLoading(true)

    return await fetch(process.env.REACT_APP_BASE_URL + '/authentication/signup/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'first_name': firstName,
        'last_name': lastName,
        'username': email,
        'password': password,
        'confirm_password': passwordConfirm,
      })
    }).then(response => {
      setIsLoading(false)
      if (response.ok !== false) {

        ReactGA.event({
          category: 'User',
          action: 'Sign Up',
          label: 'User Sign Up',
          formId: process.env.REACT_APP_GTAG_ID_FORM_ID,
          userEmailAddress: email,
        });

        /*TagManager.dataLayer({
          dataLayer: {
            event: 'Sign Up',
            formId: process.env.REACT_APP_GTAG_ID_FORM_ID,
            userEmailAddress: email,
          }
        });*/
        updateMessage('User created successfully. Verify your email.', 'success');
        // one step left to verify email
        setIsVerificationEmailSent(true)
      } else {
        if (response.status === 400) {
          updateMessage('User already exists.', 'error');
        }
      }
    }).catch(err => {
      console.log('err', err)
      setIsLoading(false)
      updateMessage('Something went wrong. Please try again.', 'error');
    })
  }

  useEffect(() => {
    if (firstName.length > 0 && lastName.length > 0
      && isFirstNameValid && isLastNameValid
      && mailRegex.test(email) && isLicenseAgreementChecked
      && isAtLeastOneLetter && isAtLeastOneCapitalLetter
      && isAtLeastOneNumber && isAtLeastTenCharacters
      && isPasswordMatch && !passwordError
      && passwordRegex.test(passwordConfirm) && passwordRegex.test(password)) {
      setCanRegister(true);
    } else {
      setCanRegister(false);
    }
  }, [firstName, lastName, isFirstNameValid, isLastNameValid, email, password, passwordError, passwordConfirm, isLicenseAgreementChecked, isAtLeastOneLetter, isAtLeastOneCapitalLetter, isAtLeastOneNumber, isAtLeastTenCharacters, isPasswordMatch]);

  const handleSocialAppLogin = async (socialApp) => {
    const cleanBaseURL = DOMPurify.sanitize(getRefreshURL());

    axios.get(`${cleanBaseURL}/authentication/social/${socialApp}/`)
      .then((res) => {
        window.location.href = res.data.url
      })
      .catch(error => {
        console.log(error)
      });
  }

  const goToProductsPage = () => {
    window.open(process.env.REACT_APP_DASHBOARD_URL, "_self");
  }

  useEffect(() => {
    setIsAtLeastOneLetter(oneLetterRegex.test(password));
    setIsAtLeastOneCapitalLetter(oneCapitalLetterRegex.test(password));
    setIsAtLeastOneNumber(oneNumberRegex.test(password));
    setIsAtLeastTenCharacters(tenCharactersRegex.test(password));
    setIsPasswordMatch(password === passwordConfirm && password.length > 0 && passwordConfirm.length > 0);
    setPasswordError(!passwordRegex.test(password));

    if (password === passwordConfirm && password.length < 10) {
      setIsPasswordMatch(false)
    }
  }, [password, passwordConfirm])


  return (
    <div className='d-flex'>
      <Helmet>
        <title>Sign Up For ImageChat | Chooch</title>
        <meta name="description" content="Create a free ImageChat Web account and create unlimited AI content. Try Text-to-Image, Image-To-Text, Chat with ImageChat. Sign up today!"></meta>

        <meta property="og:url" content="https://imagechatweb.chooch.ai/register"></meta>
        <meta property="og:title" content="Sign Up For ImageChat | Chooch"></meta>
        <meta property="og:description" content="Create a free ImageChat Web account and create unlimited AI content. Try Text-to-Image, Image-To-Text, Chat with ImageChat. Sign up today!"></meta>

        <meta name="twitter:title" content="Sign Up For ImageChat | Chooch"></meta>
        <meta name="twitter:description" content="Create a free ImageChat Web account and create unlimited AI content. Try Text-to-Image, Image-To-Text, Chat with ImageChat. Sign up today!"></meta>
        <link rel="canonical" href="https://imagechatweb.chooch.ai/register"></link>
      </Helmet>

      <div className='login-left-banner'>
        <div className='banner-content'>
          <div className='banner-header'>
            {/*<p className='banner-title'>Welcome to Chooch</p>*/}
            <div className='banner-text-body'>
              {/* <p className='banner-sur-text'>
                Have you tried ImageChat?
              </p>*/}
              <p className='banner-subtitle'>
                Start chatting with your data.
              </p>
              <p className='banner-text'>
                Sign up to experience
                ImageChat&ndash;Web!
              </p>
              {/*<p className='banner-subtext'>
                Log into ImageChat to get started
              </p>*/}
            </div>
          </div>
          <div className="login-gif-wrapper">
            <div className="responsive-gif-wrapper">
              <img src={ImageGeneration} alt="login-image" className="login-image" />
            </div>
          </div>
          <div className="mobile-app-wrapper">
            <p className="mobile-apps-text">Download the ImageChat app for free</p>
            <div className='d-flex mobile-apps'>
              <img src={AppStoreLogo} alt="login" className='me-3' onClick={goToAppStore} />
              <img src={GooglePlayLogo} alt="login" onClick={goToGooglePlay} />
            </div>
          </div>
        </div>
      </div>
      <div className="login-right">
        <img src={ChevronLeft} alt="back" className="back-btn" onClick={goToLogin} />
        <div className="login-content">
          <img className="login-brand-title" src={imageChatTitle} alt="imagechat-title" />
          <img className="login-brand-logo" src={companyLogo} alt="logo" />
          {
            isVerificationEmailSent ?
              <OneStepLeft />
              :
              <>
                <h1 className="login-header">
                  Sign up for <span className="gradient-header-text">ImageChat Web</span>
                </h1>
                <InputGroup className="input-group d-flex justify-content-between gap-3">
                  <Form.Control
                    className="form-control login-input first-name-input"
                    style={{ width: '45%' }}
                    type="text"
                    placeholder="First Name*"
                    value={firstName}
                    onChange={handleUserFirstNameChange}
                    isInvalid={firstName.length > 0 && !isFirstNameValid}
                  />
                  <Form.Control
                    className="form-control login-input last-name-input"
                    style={{ width: '45%' }}
                    type="text"
                    placeholder="Last Name*"
                    value={lastName}
                    onChange={handleUserLastNameChange}
                    isInvalid={lastName.length > 0 && !isLastNameValid}
                  />
                  <Form.Control.Feedback type="invalid" >
                    {(!isFirstNameValid && !isLastNameValid) ? 'Both first and last names must be no longer than 16 characters.' :
                      !isFirstNameValid ? 'First name must be no longer than 16 characters and contain no special characters.' :
                        !isLastNameValid ? 'Last name must be no longer than 16 characters and contain no special characters.' :
                          null
                    }
                  </Form.Control.Feedback>
                </InputGroup>

                <Form.Control.Feedback type="invalid">
                  {(!isFirstNameValid || !isLastNameValid) ? 'First and Last names must be no longer than 16 characters.' : null}
                </Form.Control.Feedback>
                <Form.Group className="password-input">
                  <Form.Control
                    isInvalid={isInvalid || emailError}
                    className="login-input"
                    type="email"
                    placeholder="Email*"
                    value={email}
                    onKeyDown={handleKeyPress}
                    onChange={e => handleEmailChange(e)}
                    onBlur={e => handleEmailBlur(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError ? 'Please enter a valid email address' : isInvalid ? 'User already exists' : null}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="password-input">
                  <Form.Control
                    className="login-input"
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Password*"
                    value={password}
                    isInvalid={password.length > 0 && !passwordRegex.test(password)}
                    onChange={e => handlePasswordChange(e)}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[^<>]{10,}$"
                  />
                  <Form.Control.Feedback type="invalid">
                    Password doesn’t meet the
                    requirements.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid" tooltip className={'password-confirmation-tooltip bg-white text-wrap'}>
                    <div>
                      <p>Password must meet the
                        following
                        requirements:</p>
                    </div>
                    <div>
                      {!isFirstNameValid ?
                        <X size={24} color={'#DE350B'} /> :
                        <Check size={24} color={'#00A78E'} />
                      }
                      First Name must be less
                      than <strong>16
                        characters</strong>
                    </div>
                    <div>
                      {!isLastNameValid ?
                        <X size={24} color={'#DE350B'} /> :
                        <Check size={24} color={'#00A78E'} />
                      }
                      Last Name must be less
                      than <strong>16
                        characters</strong>
                    </div>
                    <div>
                      {!isAtLeastOneLetter ?
                        <X size={24} color={'#DE350B'} /> :
                        <Check size={24} color={'#00A78E'} />}
                      At least <strong>one
                        letter</strong>
                    </div>
                    <div>
                      {!isAtLeastOneCapitalLetter ?
                        <X size={24} color={'#DE350B'} /> :
                        <Check size={24} color={'#00A78E'} />}
                      At least <strong>one capital
                        letter</strong>
                    </div>
                    <div>
                      {!isAtLeastOneNumber ?
                        <X size={24} color={'#DE350B'} /> :
                        <Check size={24} color={'#00A78E'} />}
                      At least <strong>one
                        number</strong>
                    </div>
                    <div>
                      {!isAtLeastTenCharacters ?
                        <X size={24} color={'#DE350B'} /> :
                        <Check size={24} color={'#00A78E'} />}
                      Be at least <strong>10
                        characters</strong>
                    </div>
                    <div>
                      {!passwordRegex.test(password) || passwordRegex.test(passwordConfirm) ?
                        <X size={24} color={'#DE350B'} /> :
                        <Check size={24} color={'#00A78E'} />}
                      No <strong> special characters</strong>
                    </div>
                    <div>
                      {isPasswordMatch ?
                        <Check size={24} color={'#00A78E'} />
                        :
                        <X size={24} color={'#DE350B'} />}
                      <strong>Passwords must
                        match</strong>
                    </div>

                  </Form.Control.Feedback>
                  {
                    !isInvalid ?
                      isPasswordVisible ?
                        <EyeSlash size={20} color="#AAAAAA" className="pass-visibility-btn" onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
                        :
                        <Eye size={20} color="#AAAAAA" className="pass-visibility-btn" onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
                      :
                      null
                  }

                </Form.Group>
                <Form.Group className="password-input">
                  <Form.Control
                    className="login-input"
                    type={isPasswordVisibleConfirm ? 'text' : 'password'}
                    placeholder="Confirm Password* "
                    value={passwordConfirm}
                    isInvalid={!isPasswordMatch}
                    onChange={e => handlePassConfirmation(e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {passwordConfirm.length > 0 && !isPasswordMatch ? 'Passwords must match' : null}
                  </Form.Control.Feedback>
                  {
                    !isInvalid ?
                      isPasswordVisibleConfirm ?
                        <EyeSlash size={20} color="#AAAAAA" className="pass-visibility-btn" onClick={() => setIsPasswordVisibleConfirm(!isPasswordVisibleConfirm)} />
                        :
                        <Eye size={20} color="#AAAAAA" className="pass-visibility-btn" onClick={() => setIsPasswordVisibleConfirm(!isPasswordVisibleConfirm)} />
                      :
                      null
                  }

                </Form.Group>

                {process.env.REACT_APP_CLOUD_IMAGE === 'True' ?
                  <Form.Group className="mail-input">
                    <Form.Control className="login-input" type="text" placeholder="API Key*" value={apiKey} onChange={e => handleAPIKeyChange(e)} />
                  </Form.Group>
                  :
                  null
                }

                <Form className="agreement-form mb-4 mt-xxl-1">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      label={
                        <span className="license-agreement">
                          By submitting this form I agree to <span className=" text-primary" onClick={() => setShowLicenseAgreement(true)}>Chooch License Agreement</span>
                        </span>
                      }
                      checked={isLicenseAgreementChecked}
                      onChange={() => setIsLicenseAgreementChecked(!isLicenseAgreementChecked)}
                    />
                  </Form.Group>
                </Form>

                <div className="login-button-wrapper sign-up-btn-wrapper">
                  {
                    isLoading ?
                      <Button className="purple-btn w-100" disabled={true}><Spinner animation="border" /></Button>
                      :
                      canRegister ?
                        <Button className="purple-btn w-100" onClick={handleRegister}>
                          Create an Account
                        </Button>
                        :
                        <Button className="disabled-btn w-100" disabled={true}>
                          Create an Account
                        </Button>
                  }
                </div>

                <div className="or-text d-flex align-items-center justify-content-center ">
                  - OR -
                </div>

                <div className="info-text ai-vision-redirection-info google-facebook-info">
                  <div className="google-users-login" onClick={() => handleSocialAppLogin('google')}>
                    <img src={GoogleLogin} alt={'google-login'} width={'26px'} />
                    <span>Sign Up with Google</span>
                  </div>
                  <div className="facebook-users-login" onClick={() => handleSocialAppLogin('facebook')}>
                    <img src={FacebookLogin} alt={'facebook-login'} width={'26px'} />
                    <span>Sign Up with Facebook</span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <p className="sign-up-redirect-btn">
                    Already have an
                    account?<span onClick={goToLogin}> Log In</span>
                  </p>
                </div>
              </>
          }

          <p className="copyright text-center mt-auto">
            All rights reserved © Chooch
            Intelligence Technologies Co.
          </p>
        </div>
      </div>

      <LicenseAgreementModal
        show={showLicenseAgreement}
        handleClose={handleCancelLicenseAgreement}
        handleAgree={handleLicenseAgreement}
      />
    </div>
  )
}
