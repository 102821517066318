import React, { useEffect } from 'react';
import {
  Button, Col,
  Container,
  Form, Image, Row
} from 'react-bootstrap';
import {
  Check, Eye,
  EyeSlash,
  X
} from 'react-bootstrap-icons';

import { useNavigate , useLocation} from 'react-router-dom';
import ResetCode from './ResetCode';
import companyLogo
  from '../assets/images/choochLogo.png';
import DOMPurify from 'dompurify';
import CountdownComponent from './Countdown';
import { useToast } from './utils/ToastContext';
export default function ResetPassword() {

  const oneLetterRegex = /[a-z]/;
  const oneCapitalLetterRegex = /[A-Z]/;
  const oneNumberRegex = /[0-9]/;
  const tenCharactersRegex = /.{10,}/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^<>]{10,}$/;
  const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const navigate = useNavigate();
  const location = useLocation();
  const { updateMessage } = useToast();


  const [password, setPassword] = React.useState('')
  const [passwordConfirm, setPasswordConfirm] = React.useState('')
  const [resetCode, setResetCode] = React.useState('')
  const [resetCount, setResetCount] = React.useState(0)
  const [passwordError, setPasswordError] = React.useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
  const [isPasswordMatch, setIsPasswordMatch] = React.useState(false)
  const [isAtLeastOneLetter, setIsAtLeastOneLetter] = React.useState(false)
  const [isAtLeastOneCapitalLetter, setIsAtLeastOneCapitalLetter] = React.useState(false)
  const [isAtLeastOneNumber, setIsAtLeastOneNumber] = React.useState(false)
  const [isAtLeastTenCharacters, setIsAtLeastTenCharacters] = React.useState(false)
  const [isInvalid, setIsInvalid] = React.useState(false)
  const [isPasswordVisibleConfirm, setIsPasswordVisibleConfirm] = React.useState(false)


  if (location.state === null) {
    navigate('/forgot-password')
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlePasswordChange(event.target.value)
    }
  }

  const handlePasswordChange = (e) => {
    //const inputVal = DOMPurify.sanitize(e.target.value)
    setPassword(e.target.value)
  }

  const handlePassConfirmation = (e) => {
    //const inputVal = DOMPurify.sanitize(e.target.value)
    setPasswordConfirm(e.target.value)
  }

  useEffect(() => {
    setIsAtLeastOneLetter(oneLetterRegex.test(password));
    setIsAtLeastOneCapitalLetter(oneCapitalLetterRegex.test(password));
    setIsAtLeastOneNumber(oneNumberRegex.test(password));
    setIsAtLeastTenCharacters(tenCharactersRegex.test(password));
    setIsPasswordMatch(password.length > 0 && passwordConfirm.length > 0 && password === passwordConfirm);
  }, [password, passwordConfirm])

  useEffect(() => {
    if(resetCode.length > 0
      && isAtLeastOneLetter
      && isAtLeastOneCapitalLetter
      && isAtLeastOneNumber
      && isAtLeastTenCharacters
      && isPasswordMatch
      && passwordRegex.test(password)
    ) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  }, [resetCode, password, passwordConfirm, isAtLeastOneLetter, isAtLeastOneCapitalLetter, isAtLeastOneNumber, isAtLeastTenCharacters, isPasswordMatch])


  const handleResetPassword = () => {
    return fetch(process.env.REACT_APP_BASE_URL + '/authentication/reset_password/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: location?.state &&  location?.state?.email,
        new_password: password,
        confirm_password: passwordConfirm,
        otp: resetCode
      })
    }).then(response => {
      if (response.ok) {
        navigate('/home', { replace: false })
      }
      if (response.status === 400) {
        updateMessage('Invalid reset code', 'error')
        setPassword('')
        setPasswordConfirm('')
        setResetCount(prevState => prevState + 1)
      }
    })
  }
  return (
    <Container fluid className="reset-password d-flex flex-column align-content-between vh-100">
      <Row>
        <div className="text-center">
          <Image src={companyLogo} alt="company logo" className="company-logo"/>
        </div>
      </Row>
      <Row className="flex-row justify-content-center">
        <Col sm={12} md={6} className="d-flex flex-column">
          <ResetCode setResetCode={setResetCode} resetCount={resetCount}/>
          {/** resend timing **/}
          <CountdownComponent/>
          <Form.Group className="password-input" aria-autocomplete={'none'}>
            <Form.Label className="login-label fw-bold">New Password</Form.Label>
            <Form.Control
              className="reset-password-input"
              type={isPasswordVisible ? 'text' : 'password'}
              autoComplete={'new-password'}
              aria-autocomplete={'none'}
              placeholder="Password*"
              value={password}
              isInvalid={password !== passwordConfirm || passwordError}
              onChange={e => handlePasswordChange(e)}
              onKeyDown={handleKeyPress}
              pattern={'/^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{10,}$/'}
            />
            <Form.Control.Feedback className="mb-2" type="invalid">
              {password.length > 0 && passwordError ? 'Password doesn’t meet the requirements.' : null}
            </Form.Control.Feedback>
            {
              !isInvalid ?
                isPasswordVisible ?
                  <EyeSlash size={20} color="#AAAAAA" className="pass-visibility-btn" onClick={() => setIsPasswordVisible(!isPasswordVisible)}/>
                  :
                  <Eye size={20} color="#AAAAAA" className="pass-visibility-btn" onClick={() => setIsPasswordVisible(!isPasswordVisible)}/>
                :
                null
            }
          </Form.Group>
          <Form.Group className="password-input">
            <Form.Label className="login-input fw-bold">Confirm Password</Form.Label>
            <Form.Control
              className="reset-password-input"
              autoComplete={'new-password'}
              aria-autocomplete={'none'}
              type={isPasswordVisibleConfirm ? 'text' : 'password'}
              placeholder="Confirm Password* "
              value={passwordConfirm}
              isInvalid={!isPasswordMatch && passwordConfirm.length > 0}
              onChange={e => handlePassConfirmation(e)}
              onKeyDown={handleKeyPress}
            />
            <Form.Control.Feedback type="invalid">
              {password !== passwordConfirm ? 'Passwords do not match' : 'Password doesn’t meet the requirements'}
            </Form.Control.Feedback>
            {
              !isInvalid ?
                isPasswordVisibleConfirm ?
                  <EyeSlash size={20} color="#AAAAAA" className="pass-visibility-btn" onClick={() => setIsPasswordVisibleConfirm(!isPasswordVisibleConfirm)}/>
                  :
                  <Eye size={20} color="#AAAAAA" className="pass-visibility-btn" onClick={() => setIsPasswordVisibleConfirm(!isPasswordVisibleConfirm)}/>
                :
                null
            }
          </Form.Group>
          <Button
            onClick={!passwordRegex.test(password) ? null : handleResetPassword}
            className="reset-btn"
            disabled={resetCode.length !== 6 || password !== passwordConfirm  || !isAtLeastOneLetter || !isAtLeastOneCapitalLetter || !isAtLeastOneNumber || !isAtLeastTenCharacters || !passwordRegex.test(password)}
          >
            Reset Password
          </Button>
        </Col>
        {/** Controls */}
        <Col sm={12} md={6}>
          <div>
            <p>Password must meet the following
               requirements:</p>
          </div>
          <div>
            {!isAtLeastOneLetter ?
              <X size={24} color={'#DE350B'}/> :
              <Check size={24} color={'#00A78E'}/>}
            At least <strong>one letter</strong>
          </div>
          <div>
            {!isAtLeastOneCapitalLetter ?
              <X size={24} color={'#DE350B'}/> :
              <Check size={24} color={'#00A78E'}/>}
            At least <strong>one capital
                             letter</strong>
          </div>
          <div>
            {!isAtLeastOneNumber ?
              <X size={24} color={'#DE350B'}/> :
              <Check size={24} color={'#00A78E'}/>}
            At least <strong>one number</strong>
          </div>
          <div>
            {!isAtLeastTenCharacters ?
              <X size={24} color={'#DE350B'}/> :
              <Check size={24} color={'#00A78E'}/>}
            Be at least <strong>10
                                characters</strong>
          </div>
          <div>
            {!passwordRegex.test(password) ?
              <X size={24} color={'#DE350B'}/> :
              <Check size={24} color={'#00A78E'}/>
            }
            No <strong>special characters</strong>
          </div>
          <div>
            {isPasswordMatch ?
              <Check size={24} color={'#00A78E'}/>
              :
              <X size={24} color={'#DE350B'}/>}
            <strong>Passwords must match</strong>
          </div>
        </Col>
      </Row>
      <Row className="mt-auto">
        <Col className="text-center">
          <p className="copyright ">
            All rights reserved © Chooch
            Intelligence Technologies Co.
          </p>
        </Col>
      </Row>
    </Container>
  )
}
