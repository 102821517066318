import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "./utils/AuthContext";
import choochLogo from '../assets/images/choochLogo.png';
import choochLogoWhite from '../assets/images/choochLogoWhite.png';
import { useDispatch, useSelector } from 'react-redux';
import Base64ImageConverter from './utils/Base64ImageConverter';
import { getSettings } from '../state/slices/settingSlice';
import { startRestart } from '../state/slices/restartSlice';
import Error from '../assets/images/warning-red-circle.svg';
import { Popover } from 'antd';
import ResponsiveNavbar from './ResponsiveNavbar';
import { AXIOS_INSTANCE } from './utils/axiosInstance';
import { getRefreshURL } from './utils/generic';
import { useTheme } from "./utils/ThemeContext";

export default function Header() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : sessionStorage.getItem('access_token');
  const [username, setUsername] = useState(localStorage.getItem("username") || '');
  const navigate = useNavigate();
  const { logout } = useAuth();

  /*const [clickTriggerStr, setClickTriggerStr] = useState('The URL is checking...');
  const [connectionStatus, setConnectionStatus] = useState(null);*/



  const { darkTheme } = useTheme();
  useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      darkTheme ? "dark" : "light"
    );
  }, [darkTheme]);





  const handleOpenChoochPlatform = () => {
    window.open(process.env.REACT_APP_DASHBOARD_URL + "?token=" + token, "_blank");
  };

  const handleOpenAnalyticsPlatform = () => {
    window.open("https://analytics.chooch.ai/?token=" + token, "_blank");
  };

  const handleOpenChoochPlatformAccount = () => {
    window.open(process.env.REACT_APP_DASHBOARD_URL + "?token=" + token, "_blank");
  };
  const handleLogout = () => {
    AXIOS_INSTANCE({
      method: 'post',
      url: getRefreshURL() + '/authentication/logout/',
      data: {
        'token': localStorage.getItem('refresh_token') || sessionStorage.getItem('refresh_token')
      }
    }).then(() => {
      //dispatch(resetApp());
      sessionStorage.clear();
      localStorage.clear();
      localStorage.setItem('theme', darkTheme ? 'dark' : 'light');
      logout();
      navigate("/login");
    }).catch(() => {
      console.log('error');
      //dispatch(resetApp());
      sessionStorage.clear();
      localStorage.clear();
      localStorage.setItem('theme', darkTheme ? 'dark' : 'light');
      logout();
      navigate("/login");
    });

  };

  const navigateToAbout = () => {
    navigate("/about");
  }

  const navigateToSettings = () => {
    navigate("/settings");
  }

  const navigateToAPIDocumentation = () => {
    navigate('/docs');
  }

  const navigateToInferenceEngine = () => {
    navigate("/inference-engine");
  }

  const restartSystem = () => {
    dispatch(startRestart());
  }

  /*const onChange = (editVal) => {
    if(editVal === '') {
      setClickTriggerStr(clickTriggerStr);
    } else {
      setClickTriggerStr(editVal);
      AXIOS_INSTANCE.post('/connection/', {
        "api_url": editVal
      }).then((response) => {
        setConnectionStatus(response.data.status)
      })
    }
  };*/



  /*const getConnectionURL = () => {
    AXIOS_INSTANCE.get('/connection/')
      .then((response) => {
        setConnectionStatus(response.data?.status);
        setClickTriggerStr(response.data?.api_url)

        if(response.status > 400) {
          setConnectionStatus(false)
          setClickTriggerStr(response.data?.api_url)
        }
      }).catch((error) => {
      console.log('error', error);
      setConnectionStatus(false)
    });
  }*/


  /*useEffect(() => {
    getConnectionURL()
    const interval = setInterval(() => {
      getConnectionURL()
    }, 60000);
    return () => clearInterval(interval);
  }, [connectionStatus]);*/

  useEffect(() => {
    dispatch(getSettings())
      .then((res) => {
        localStorage.setItem("username", res.payload.user_full_name)
        setUsername(res.payload.user_full_name)
      });
  }, [dispatch]);

  useEffect(() => {
    const resizeListener = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

  const handleNewChat = () => {
    sessionStorage.removeItem('session_ID');
    navigate("/home")
  }



  return (
    isMobile ?
      <ResponsiveNavbar />
      :
      <div className='main-header'>
        <a href="/home" className="app-logo-box" onClick={handleNewChat}>
          {
            settings?.data?.company_logo === null || settings?.data?.company_logo === 'null' || settings?.data?.company_logo === 'undefined' || settings?.data?.company_logo === undefined || settings?.data?.company_logo === '' ?
              <img
                className="app-logo"
                src={darkTheme ? choochLogoWhite : choochLogo}
                alt="app-logo"
              />
              :
              <Base64ImageConverter base64Image={settings?.data?.company_logo} fileType={'png'} clsName={'app-logo'} />
          }
        </a>
        <div className='d-flex align-items-center'>
          {
            process.env.REACT_APP_ON_PREM !== 'false' ?
              <>
                <p className='documentation-btn' onClick={navigateToAPIDocumentation}>API Documentation</p>
                <div className='inference-btn-wrap' onClick={navigateToInferenceEngine}>
                  <div className='inference-btn'>
                    Enhance Your Experience
                  </div>
                </div>
              </>
              :
              <Dropdown className="chooch-apps-dropdown">
                <Dropdown.Toggle>
                  ImageChat Web
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleOpenAnalyticsPlatform}>
                    Chooch Smart Analytics
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleOpenChoochPlatform}>
                    Chooch AI Vision Studio
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
          }
          {/*<p className='documentation-btn' onClick={navigateToAPIDocumentation}>API Documentation</p>*/}

          <Dropdown className={`${!isMobile ? 'user-menu-dropdown fifth-step' : 'user-menu-dropdown'}`}>
            {
              process.env.REACT_APP_ON_PREM !== 'false' ?
                <Dropdown.Menu>

                  <Dropdown.Item onClick={navigateToSettings}>
                    {/*<img src={Settings} alt="settings" />*/}
                    Settings
                    {
                      settings.data.has_new_version &&
                      <Popover content={'New version available'} placement='bottom' trigger="hover">
                        <img src={Error} alt="info" style={{ marginLeft: '20px', width: '18px', verticalAlign: 'bottom' }} />
                      </Popover>
                    }
                  </Dropdown.Item>
                  <Dropdown.Item onClick={restartSystem}>
                    {/* <img src={Restart} alt="restart" />*/}
                    Restart
                  </Dropdown.Item>
                  <Dropdown.Item onClick={navigateToAbout}>
                    {/*<img src={APIDocumentation} alt="about" />*/}
                    Help
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    {/*<img src={Out} alt="logout" />*/}
                    Sign Out
                  </Dropdown.Item>

                </Dropdown.Menu>
                :
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleOpenChoochPlatformAccount}>
                    Account
                  </Dropdown.Item>
                  <Dropdown.Item onClick={navigateToSettings}>
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Item onClick={navigateToAPIDocumentation}>
                    API Documentation
                  </Dropdown.Item>
                  <Dropdown.Item onClick={navigateToAbout}>
                    {/*<img src={APIDocumentation} alt="about" />*/}
                    Help
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
            }
            <Dropdown.Toggle>
              <div className='user-circle'> {username.split(' ')[0].slice(0, 1) + username.split(' ').pop().slice(0, 1)}</div>
              {/** {username} first letter big */}
              {username && username.split(' ')[0].charAt(0).toUpperCase() + username.split(' ')[0].slice(1) + ' ' + username.split(' ')[1].charAt(0).toUpperCase() + username.split(' ')[1].slice(1)}
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>
  )
}
