import { createSlice } from '@reduxjs/toolkit';
import { reducer as chatReducer } from './chatSlice';
import { reducer as chatHistoryListReducer } from './chatHistoryListSlice';
import { reducer as sessionContent } from './chatSlice';
import { reducer as settingReducer } from './settingSlice';
import { reducer as restartReducer } from './restartSlice';

const initialState = {
    chat: chatReducer(undefined, {}),
    chatHistoryList: chatHistoryListReducer(undefined, {}),
    sessionContent: sessionContent(undefined, {}),
    setting: settingReducer(undefined, {}),
    restart: restartReducer(undefined, {}),
};

export const { actions, reducer } = createSlice({
    name: 'resettable',
    initialState,
    reducers: {
        resetApp: () => initialState,
    },
});

export const { resetApp } = actions;

