import { Modal } from 'react-bootstrap';

export default function GoogleAndFacebookLoginModal(props) {

  const goToAIVision = () => {
    window.open('https://app.chooch.ai/feed/user-settings#security', "_blank");
  }

  const handleContactUs = () => {
    window.open('https://chooch.ai/contact/', "_blank");
  }

  return(
    <Modal className={'social-app-login-modal'} show={props.show} onHide={props.handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Important Notice for Google or Facebook Sign-In Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          <p className="social-app-login-text">If you initially signed up for an AI Vision Studio account using your Google or Facebook account, you'll need to set up a unique password for direct ImageChat-Web access. This step is essential to enhance your account security and streamline your login process. Here's how you can create your AI Vision Studio password:
          </p>
        </div>
        <div className='mb-3'>
          <span className="social-app-login-bold-text">1. Log in to your <span className="social-app-login-active-text" onClick={goToAIVision}>AI Vision Studio</span> Account Using Google or Facebook:</span>
          <span className="social-app-login-text">First, access the AI Vision Studio website and log in using your Google or Facebook account, as you usually do.</span>
        </div>
        <div className='mb-3'>
          <span className='social-app-login-bold-text'>2. Navigate to Profile:</span>
          <span className='social-app-login-text'>Once you're logged in, click on your profile icon in the top right corner of the screen and select 'Account Settings' from the drop-down menu.</span>
        </div>
        <div className='mb-3'>
          <span className='social-app-login-bold-text'>3. Select 'Security & Privacy’:</span>
          <span className='social-app-login-text'>In your account settings, look for an option labeled 'Create Password'.</span>
        </div>
        <div className='mb-3'>
          <span className='social-app-login-bold-text'>4. Follow the Instructions:</span>
          <span className='social-app-login-text'>Enter a new password as per the provided guidelines (ensure it's strong and unique for security purposes).</span>
        </div>
        <div className='mb-3'>
          <span className='social-app-login-bold-text'>5. Save Changes:</span>
          <span className='social-app-login-text'>After setting your new password, save the changes.</span>
        </div>
        <div className='mb-1'>
          <span className='social-app-login-text'>
            You can now log in to your ImageChat-Web account using the newly created password. If you encounter any issues or need further assistance, please <span className='social-app-login-active-text' onClick={handleContactUs}>contact</span> our support team.
          </span>
        </div>
      </Modal.Body>
    </Modal>
  )
}
