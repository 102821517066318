import React from 'react'

export default function ChatResponseSpinner({ withText }) {
  return (
    <div className='d-flex'>
      <div className={`chat-spinner d-flex ${withText ? 'ms-5' : ''}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {withText && <p className='chat-spinner-text'>Generating</p>}
    </div>
  )
}
